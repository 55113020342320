import { ChevronDown } from "@ignite-analytics/icons";
import { Button, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const TableViewHeader: React.FC = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" paddingBottom={1.5}>
        <Typography variant="textLg" component="h2" fontWeight="medium">
            <FormattedMessage defaultMessage="High risk suppliers" description="high risk suppliers list header" />
        </Typography>
        <Stack direction="row" spacing={2}>
            <FormControl>
                <Select
                    sx={{
                        margin: 0,
                        fontWeight: "medium",
                        ["& .MuiSelect-icon"]: { color: "inherit", alignItems: "center" },
                    }}
                    size="small"
                    value={2}
                    onChange={() => {}}
                    IconComponent={(props) => <ChevronDown fontSize="small" {...props} />}
                >
                    <MenuItem key={2} value={2}>
                        <FormattedMessage
                            defaultMessage="Industry distribution"
                            description="industry distribution list header"
                        />
                    </MenuItem>
                </Select>
            </FormControl>
            <Stack justifyContent="center">
                <Button size="small" color="secondary" onClick={() => {}}>
                    <FormattedMessage defaultMessage="View all" description="view all button" />
                </Button>
            </Stack>
        </Stack>
    </Stack>
);
