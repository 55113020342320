import { Chip } from "@mui/material";
import { styled, Theme, useTheme } from "@mui/material/styles";
import React from "react";
import { useIntl } from "react-intl";

import { Chip as OldChip } from "../../Chip";

interface AssessmentFieldProps {
    status: "notSent" | "sent" | "submitted";
}

type StatusValue = "submitted" | "sent" | "notSent";

export const AssessmentField: React.FC<AssessmentFieldProps> = ({ status }) => {
    const { formatMessage } = useIntl();
    const { name } = useTheme() as Theme & { name?: string };

    const getChipColor = (status: StatusValue) => {
        switch (status) {
            case "submitted":
                return "success";
            case "sent":
                return "primary";
            case "notSent":
                return "warning";
            default:
                return "neutral";
        }
    };

    const getOldChipColor = (status: StatusValue) => {
        switch (status) {
            case "submitted":
                return (theme: Theme) => theme.palette.success.container;
            case "sent":
                return (theme: Theme) => theme.palette.warning.container;
            case "notSent":
                return (theme: Theme) => theme.palette.secondary.container;
        }
    };

    const statusText = {
        submitted: formatMessage({ defaultMessage: "Submitted" }),
        sent: formatMessage({ defaultMessage: "Pending" }),
        notSent: formatMessage({ defaultMessage: "Not sent" }),
    };

    if (!statusText[status]) {
        return null;
    }

    return (
        <AssessmentCell>
            {name === "ignite-riddle" ? (
                <Chip
                    variant="status"
                    key={statusText[status]}
                    label={statusText[status]}
                    size="small"
                    color={getChipColor(status)}
                />
            ) : (
                <OldChip // riddle transition bandaid
                    key={statusText[status]}
                    sx={{
                        backgroundColor: getOldChipColor(status),
                    }}
                >
                    {statusText[status]}
                </OldChip>
            )}
        </AssessmentCell>
    );
};

const AssessmentCell = styled("div")(() => ({
    gap: 10,
    padding: "0 10px",
    maxWidth: "100%",
    overflow: "hidden",
    flexDirection: "row",
    display: "flex",
}));
