import { Pen, ChevronDown } from "@ignite-analytics/icons";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { ContactCell_ContactFragment } from "@/gql/graphql";

graphql(`
    fragment ContactCell_Contact on Contact {
        id
        email
        firstName
        lastName
        companyId
    }
`);

export type ContactCellProps = {
    readonly contact: ContactCell_ContactFragment | null;
    readonly numberOfAdditionalContacts: number;
    readonly canAddContacts: boolean;
    readonly onAddContact: () => void;
    readonly onEditContact: (contact: ContactCell_ContactFragment) => void;
    readonly onToggleDropdown: () => void;
};
const ContactCell = ({
    contact,
    numberOfAdditionalContacts,
    canAddContacts,
    onAddContact,
    onEditContact,
    onToggleDropdown,
}: ContactCellProps) => {
    if (contact?.email) {
        return (
            <Stack
                direction="row"
                alignItems="center"
                width="100%"
                height="100%"
                justifyContent="space-between"
                sx={[
                    {
                        "& > .buttons": {
                            display: "none",
                        },
                        "&:hover > .buttons": {
                            display: "flex",
                        },
                    },
                ]}
            >
                <Stack direction="row">
                    <Typography variant="textSm">{contact.email}</Typography>
                    {numberOfAdditionalContacts > 0 && (
                        <Chip
                            onClick={onToggleDropdown}
                            size="small"
                            sx={{
                                cursor: "pointer",
                                fontSize: "0.75rem",
                                height: "20px",
                                marginLeft: 1,
                                color: (theme) => theme.palette.grey[600],
                            }}
                            label={`+${numberOfAdditionalContacts}`}
                        />
                    )}
                </Stack>
                <Stack direction="row" className="buttons">
                    {canAddContacts && (
                        <IconButton sx={{ margin: 0, padding: 0 }} size="small" onClick={() => onEditContact(contact)}>
                            <Pen fontSize="inherit" />
                        </IconButton>
                    )}
                    {canAddContacts && (
                        <IconButton sx={{ margin: 0, padding: 0 }} size="small" onClick={onToggleDropdown}>
                            <ChevronDown fontSize="small" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        );
    }
    if (!canAddContacts) {
        return null;
    }
    return (
        <Stack
            direction="row"
            alignItems="center"
            width="100%"
            height="100%"
            onClick={onAddContact}
            sx={[
                {
                    cursor: "pointer",
                    "& > p": {
                        display: "none",
                    },
                    "&:hover > p": {
                        display: "block",
                    },
                },
            ]}
        >
            <Typography variant="textSm" color="primary">
                <FormattedMessage defaultMessage="Add contact" description="Add contact button" />
            </Typography>
        </Stack>
    );
};

export default ContactCell;
