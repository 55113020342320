import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DeleteViewModal_SupplierTableConfigFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

graphql(`
    fragment DeleteViewModal_SupplierTableConfig on SupplierTableConfig {
        id
        displayName
    }
`);

const deleteSupplierTableConfig = graphql(`
    mutation DeleteViewModal_DeleteSupplierTableConfig($input: DeleteSupplierTableConfigInput!) {
        deleteSupplierTableConfig(input: $input) {
            deletedId
        }
    }
`);

interface DeleteViewModalProps {
    onClose: (deleted: boolean, id: string) => void;
    view: DeleteViewModal_SupplierTableConfigFragment;
}

export const DeleteViewModal: React.FC<DeleteViewModalProps> = ({ onClose, view }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const [deleteConfig] = useMutation(deleteSupplierTableConfig);
    const deleteView = () => {
        deleteConfig({
            variables: {
                input: {
                    id: view.id,
                },
            },
            onCompleted: () => {
                onClose(true, view.id);
                track("Supplier Table: Deleted view", { view });
            },
            onError: () => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "An error occurred when deleting view",
                        description: "Delete view error message",
                    }),
                    severity: "error",
                });
            },
            update(cache, { data }) {
                cache.evict({
                    id: cache.identify({
                        __typename: "SupplierTableConfig",
                        id: data?.deleteSupplierTableConfig.deletedId,
                    }),
                });
            },
        });
    };

    return (
        <Dialog open={true} onClose={() => onClose(false, "")} maxWidth="xs" fullWidth>
            <DialogTitle>
                <FormattedMessage
                    defaultMessage="Delete view: {title}"
                    description="Delete view modal title"
                    values={{ title: view.displayName }}
                />
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Are you sure you want to delete this view? This will remove the view from all users permanently."
                            description="Delete view modal warning"
                        />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false, "")} variant="text" color="secondary">
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button onClick={deleteView} variant="contained" color="primary">
                    <FormattedMessage defaultMessage="Delete" description="Delete button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
