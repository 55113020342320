import { QueryHookOptions, useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import {
    GetContactsBySupplierIdsInput,
    SupplierTable_ContactFragment,
    SupplierTable_GetContactsBySupplierIdsQuery,
    SupplierTable_GetContactsBySupplierIdsQueryVariables,
} from "@/gql/graphql";

graphql(`
    fragment SupplierTable_Contact on Contact {
        id
        email
        firstName
        lastName
        position
        companyId
        phone {
            number
        }
    }
`);

export const getContactsDocument = graphql(`
    query SupplierTable_GetContactsBySupplierIds($input: GetContactsBySupplierIdsInput!) {
        getContactsBySupplierIds(input: $input) {
            result {
                supplierId
                contacts {
                    ...SupplierTable_Contact
                }
            }
        }
    }
`);

export function useSupplierContacts(
    queryVars: { input: GetContactsBySupplierIdsInput },
    options?: QueryHookOptions<
        SupplierTable_GetContactsBySupplierIdsQuery,
        SupplierTable_GetContactsBySupplierIdsQueryVariables
    >
) {
    const { data, refetch } = useQuery<
        SupplierTable_GetContactsBySupplierIdsQuery,
        SupplierTable_GetContactsBySupplierIdsQueryVariables
    >(getContactsDocument, {
        variables: queryVars,
        ...options,
    });

    const supplierContacts =
        data?.getContactsBySupplierIds.result.reduce(
            (acc: { [key: string]: SupplierTable_ContactFragment[] }, curr) => {
                if (curr.supplierId) {
                    acc[curr.supplierId] = curr.contacts;
                }
                return acc;
            },
            {}
        ) ?? {};

    return { supplierContacts, refetch };
}
