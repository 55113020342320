import { Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { SupplierTable_ContactFragment, UpsertContactModal_ContactFragment } from "@/gql/graphql";

import { CONTACT_COLUMN_ID } from "../../../../../components";
import ContactCell from "../../../Components/ContactCell";
import ContactCellDropdown from "../../../Components/ContactCellDropdown";
import { HandleOpenUpsertContactModal } from "../../../SupplierTable";

import { ColumnDefinition } from "./columnDefinition";
import { DragIndicator } from "./DragIndicator";

export function getContactsColumn(handleOpenUpsertContactModal: HandleOpenUpsertContactModal): ColumnDefinition {
    return {
        field: CONTACT_COLUMN_ID,
        type: "text",
        sortable: false,
        filterable: false,
        editable: true,
        width: 300,
        valueGetter(params) {
            return params.row[CONTACT_COLUMN_ID] ?? [];
        },
        renderHeader() {
            return (
                <Stack direction="row" alignItems="center">
                    <DragIndicator />
                    <Typography fontWeight={500} variant="inherit">
                        <FormattedMessage defaultMessage="Contact" description="Contact column title" />
                    </Typography>
                </Stack>
            );
        },
        renderCell(params) {
            const contacts = params.value;
            const contact = contacts[0];
            return (
                <ContactCell
                    contact={contact}
                    numberOfAdditionalContacts={Math.max(
                        0,
                        (contacts.filter((contact: SupplierTable_ContactFragment) => !!contact.email) ?? []).length - 1
                    )}
                    canAddContacts={!!params.isEditable}
                    onAddContact={() => {
                        handleOpenUpsertContactModal({ supplierId: params.row.id });
                    }}
                    onEditContact={(contact) => {
                        handleOpenUpsertContactModal({
                            contact: contact as UpsertContactModal_ContactFragment,
                            supplierId: params.row.id,
                        });
                    }}
                    onToggleDropdown={() => params.api.startCellEditMode({ id: params.id, field: params.field })}
                />
            );
        },
        renderEditCell(params) {
            if (params.cellMode === "view") {
                return null;
            }
            const contacts = params.value;
            const contact = contacts[0];
            const stopEditing = () =>
                params.api.stopCellEditMode({
                    id: params.id,
                    field: params.field,
                    ignoreModifications: true,
                });
            return (
                <Stack padding="0 9px" width="100%" zIndex={1}>
                    <ContactCell
                        contact={contact}
                        numberOfAdditionalContacts={Math.max(
                            0,
                            (contacts.filter((contact: SupplierTable_ContactFragment) => !!contact.email) ?? [])
                                .length - 1
                        )}
                        canAddContacts={!!params.isEditable}
                        onAddContact={() => {
                            handleOpenUpsertContactModal({ supplierId: params.row.id });
                        }}
                        onEditContact={(contact) => {
                            handleOpenUpsertContactModal({
                                contact: contact as UpsertContactModal_ContactFragment,
                                supplierId: params.row.id,
                            });
                        }}
                        onToggleDropdown={stopEditing}
                    />
                    <ContactCellDropdown
                        anchor={params.api.getCellElement(params.id, params.field)}
                        contacts={contacts.filter(
                            (contact: SupplierTable_ContactFragment) =>
                                !!contact.email && contact.id !== contacts[0]?.id
                        )}
                        onAddContact={() => {
                            handleOpenUpsertContactModal({ supplierId: params.row.id });
                            stopEditing();
                        }}
                        onEditContact={(contact) => {
                            handleOpenUpsertContactModal({
                                contact: contact as UpsertContactModal_ContactFragment,
                                supplierId: params.row.id,
                            });
                            stopEditing();
                        }}
                    />
                </Stack>
            );
        },
        hideable: true,
        pinnable: true,
    };
}
