import {
    ApolloClient,
    ApolloLink,
    FieldFunctionOptions,
    FieldMergeFunction,
    FieldReadFunction,
    from,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    TypePolicies,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

import { GetSuppliersResponse, QueryGetSuppliersArgs } from "@/gql/graphql";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getGraphqlClient = (errorHandler?: ApolloLink) => {
    const uri = import.meta.env.REACT_APP_GRAPHQL_ROUTER_URL;
    if (apolloClient) {
        return apolloClient;
    }
    const authenticationLink = setContext(async () => {
        const tenant = localStorage.getItem("tenant");

        const headers = {
            ...(tenant ? { "x-tenant-id": tenant } : {}),
        };
        return {
            headers,
            fetchOptions: {
                credentials: "include",
            },
        };
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 3,
            retryIf: (error) => !!error && typeof error === "object" && error.message === "Failed to fetch",
        },
    });
    const errorHandlerList = errorHandler ? [errorHandler] : [];
    const httpLink = new HttpLink({ uri, credentials: "include" });
    const time = new Date().getTime();
    apolloClient = new ApolloClient({
        cache: new InMemoryCache({ typePolicies }),
        link: from([...errorHandlerList, authenticationLink, retryLink, httpLink]),
        name: `suppliers-apollo-client-${time}`,
    });

    return apolloClient;
};

const pageBasedPagination: FieldMergeFunction<
    GetSuppliersResponse,
    GetSuppliersResponse,
    FieldFunctionOptions<Partial<QueryGetSuppliersArgs>, Partial<QueryGetSuppliersArgs>>
> = function (existing, incoming, { variables }) {
    // Default pageIndex to 0 if variables are empty (only happens on addSupplier on pageIndex=0)
    const pageIndex = variables?.input?.pageIndex ?? 0;
    // Slices since existing is immutable
    const merged = existing ? existing.suppliers.slice(0) : [];
    for (let i = 0; i < incoming.suppliers.length; ++i) {
        merged[pageIndex + i] = incoming.suppliers[i];
    }

    merged.length = pageIndex + incoming.suppliers.length;

    return {
        ...incoming,
        suppliers: merged,
    };
};

/**
 * Return a paginated subset of suppliers based on the pageIndex and pageRange arguments when reading
 * the getSuppliers query.
 */
const readPageBasedPagination: FieldReadFunction<
    GetSuppliersResponse,
    GetSuppliersResponse,
    FieldFunctionOptions<Partial<QueryGetSuppliersArgs>, Partial<QueryGetSuppliersArgs>>
> = function (existing, { args }) {
    if (args) {
        const pageIndex = args.input?.pageIndex ?? 0;
        const pageRange = args.input?.pageRange ?? 100;
        if (existing) {
            const { suppliers, ...rest } = existing;
            return {
                ...rest,
                suppliers: suppliers.slice(pageIndex, pageIndex + pageRange),
            };
        }
    }
};

const typePolicies: TypePolicies = {
    Query: {
        fields: {
            getSuppliers: {
                read: readPageBasedPagination,
                keyArgs: ["input", ["fallbackToOldDMS", "filterJson", "nameSearch", "sort", ["columnId", "direction"]]],
                merge: pageBasedPagination,
            },
            getSupplierTableMeta: {
                merge(existing, incoming, { mergeObjects }) {
                    return mergeObjects(existing, incoming);
                },
            },
        },
    },
};
