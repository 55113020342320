import { Divider, List } from "@mui/material";

import { RiskScore, TableViews_SupplierFragment } from "@/gql/graphql";

import { FilterInput } from "../../../InlineFilter/types";

import { ListContentContainer } from "./ListContentContainer";
import { ListRow } from "./ListRow";

interface CountryDistributionListProps {
    geoRiskColumnId?: string;
    topSuppliers?: TableViews_SupplierFragment[];
    topSuppliersLoading: boolean;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}

interface ListCountry {
    numberOfSuppliers: number;
    risk: RiskScore;
}

export const CountryDistributionList: React.FC<CountryDistributionListProps> = ({
    geoRiskColumnId,
    onNavigate,
    topSuppliers,
    topSuppliersLoading,
}) => {
    const countryDistribution =
        topSuppliers
            ?.filter((sup) => sup.risk?.social === "HIGH")
            .reduce(
                (prev, supplier) => {
                    const riskValue = supplier.risk?.geography;
                    if (!riskValue) return prev;
                    if (!supplier.country) return prev;
                    const prevValue = prev[supplier.country];
                    if (prevValue === undefined) {
                        prev[supplier.country] = { numberOfSuppliers: 1, risk: riskValue };
                        return prev;
                    }
                    prevValue.numberOfSuppliers += 1;
                    return prev;
                },
                {} as { [country: string]: ListCountry }
            ) ?? [];

    const sortedCountryDistribution = Object.entries(countryDistribution).sort(
        (a, b) => b[1].numberOfSuppliers - a[1].numberOfSuppliers
    );

    const handleOnClick = (country: string) => {
        if (!geoRiskColumnId) return;
        const filter: FilterInput[] = [
            {
                type: "include",
                column_id: geoRiskColumnId,
                include_exclude_values: ["high"],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: false,
            },
            {
                type: "include",
                column_id: "country",
                include_exclude_values: [country],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: false,
            },
        ];
        onNavigate(filter, ["name", "country", geoRiskColumnId], "DistributionHighRiskCountries");
    };

    return (
        <List style={{ maxHeight: "100%", overflow: "auto" }}>
            <Divider />
            <ListContentContainer loading={topSuppliersLoading}>
                {sortedCountryDistribution.map(([country, { numberOfSuppliers, risk }]) => {
                    return (
                        <ListRow
                            key={country}
                            risk={risk}
                            name={country}
                            value={numberOfSuppliers.toString()}
                            onClick={() => handleOnClick(country)}
                        />
                    );
                })}
            </ListContentContainer>
        </List>
    );
};
