import { Divider, List } from "@mui/material";

import { naceCodeToText } from "../../TableViews/helpers";

import { ListContentContainer } from "./ListContentContainer";
import { ListRow } from "./ListRow";

const naceDistributionDemo: { nace: string; numberOfSuppliers: number; risk: "HIGH" | "MEDIUM" | "LOW" }[] = [
    { nace: "24.10", numberOfSuppliers: 23, risk: "HIGH" },
    { nace: "24.20", numberOfSuppliers: 19, risk: "HIGH" },
    { nace: "28.11", numberOfSuppliers: 18, risk: "HIGH" },
    { nace: "35.23", numberOfSuppliers: 11, risk: "HIGH" },
    { nace: "08.12", numberOfSuppliers: 10, risk: "HIGH" },
    { nace: "25.50", numberOfSuppliers: 9, risk: "HIGH" },
    { nace: "08.91", numberOfSuppliers: 5, risk: "HIGH" },
    { nace: "25.30", numberOfSuppliers: 4, risk: "HIGH" },
    { nace: "01.26", numberOfSuppliers: 2, risk: "HIGH" },
    { nace: "51.21", numberOfSuppliers: 1, risk: "HIGH" },
];

export const IndustryDistributionList: React.FC = () => {
    return (
        <List>
            <Divider />
            <ListContentContainer>
                {naceDistributionDemo.slice(0, 10).map(({ nace, numberOfSuppliers, risk }) => {
                    return (
                        <ListRow
                            key={nace}
                            risk={risk}
                            name={naceCodeToText(nace)}
                            value={numberOfSuppliers.toString()}
                            onClick={() => {}}
                        />
                    );
                })}
            </ListContentContainer>
        </List>
    );
};
