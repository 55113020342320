import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

const subscriptionsUrl =
    process.env.REACT_APP_SUBSCRIPTIONS_URL ?? "https://subscriptions-service.igniteprocurement.com";

const customersUrl = process.env.REACT_APP_CUSTOMERS_URL;

export type DeepStreamCredentials = {
    tenant: string;
    deepstreamId?: string;
};

export const useSubscriptionsApiFetch = <T>(url: string) => {
    const [data, setData] = useState<T>();
    const [error, setError] = useState<Error>();
    useEffect(() => {
        fetch(`${subscriptionsUrl}/${url}`, {
            credentials: "include",
        })
            .then(async (response) => {
                setData(await response.json());
            })
            .catch((error) => {
                Sentry.captureException(error, { tags: { app: "suppliers-app" } });
                if (error instanceof Error) {
                    setError(error);
                }
                setError(new Error("Something went wrong"));
            });
    }, [url]);

    return {
        data,
        setData,
        error,
        setError,
    };
};

interface moduleResponse {
    [key: string]: { included: boolean };
}

type Module = "social-risk";

// returns if the module is included in the customer's subscription
export const useHasModuleIncluded = (module: Module, defaultValue: boolean) => {
    const [response, setResponse] = useState<boolean>(defaultValue);
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${customersUrl}/modules`, {
            credentials: "include",
        })
            .then(async (response) => {
                const data: moduleResponse = await response.json();
                setResponse(data[module]?.included);
            })
            .catch((error) => {
                Sentry.captureException(error, { tags: { app: "suppliers" } });
                if (error instanceof Error) {
                    setError(error);
                }
                setError(new Error("Something went wrong"));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [module]);

    return { response, loading, error };
};
