import { X } from "@ignite-analytics/icons";
import { Grid, IconButton, Stack } from "@mui/material";

import { graphql } from "@/gql";
import { FilterLine_SupplierTableColumnFragment } from "@/gql/graphql";

import { FilterComponent, SelectColumn } from "./Components";
import { Filter } from "./types";

graphql(`
    fragment FilterLine_SupplierTableColumn on SupplierTableColumn {
        id
        ...SelectColumn_SupplierTableColumn
    }
`);

interface FilterLineProps {
    columns: FilterLine_SupplierTableColumnFragment[];
    filter: Filter;
    showDelete: boolean;
    handleUpdateFilter: (filter: Filter) => void;
    handleDeleteFilter: (id: string) => void;
}

export const FilterLine: React.FC<FilterLineProps> = ({
    columns,
    filter,
    showDelete,
    handleUpdateFilter,
    handleDeleteFilter,
}) => {
    return (
        <Stack direction="row" alignItems="end">
            <Grid container direction="row" spacing={1} sx={{ alignItems: "end" }}>
                <SelectColumn columns={columns} filter={filter} handleUpdateFilter={handleUpdateFilter} />
                <FilterComponent filter={filter} handleUpdateFilter={handleUpdateFilter} />
            </Grid>
            {showDelete && (
                <IconButton onClick={() => handleDeleteFilter(filter.id)}>
                    <X sx={{ height: 14, width: 14, color: (theme) => theme.palette.text.primary }} />
                </IconButton>
            )}
        </Stack>
    );
};
