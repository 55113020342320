import { QuestionCircle } from "@ignite-analytics/icons";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";

interface Props {
    columnName: string;
}

export const NaceHeader: React.FC<Props> = ({ columnName }) => {
    const { formatMessage } = useIntl();
    return (
        <Stack direction="row" spacing={1} alignItems="center" fontSize="inherit">
            <Typography variant="inherit" fontWeight={500}>
                {columnName}
            </Typography>{" "}
            <Tooltip
                title={formatMessage({
                    defaultMessage:
                        "Nomenclature of Economic Activities (NACE) is a system that categorizes economic activities into standard codes.",
                })}
            >
                <QuestionCircle fontSize="inherit" />
            </Tooltip>
        </Stack>
    );
};
