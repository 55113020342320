import { openSocketConnection } from "@ignite-analytics/notifications";
import { useEffect, useRef } from "react";

interface IncomingEvent {
    process: string;
    type: "PROCESS_FINISHED" | "FAILED";
    id?: string;
    data?: unknown;
}

interface ValidateFileData {
    fields: { fieldKey: string; samples: string[] }[];
    warnings: string[];
    errors: string[];
}

export interface ValidateFileDataEvent {
    type: "PROCESS_FINISHED" | "FAILED";
    process: "ValidateFile";
    fileId: string;
    data: ValidateFileData;
}

export type FindDuplicatesInFileEvent =
    | {
          type: "PROCESS_FINISHED";
          process: "FindDuplicatesInFile";
          fileId: string;
          data: {
              duplicateValues: string[];
              uniqueColumnIndicies: number[];
          };
      }
    | {
          type: "FAILED";
          process: "FindDuplicatesInFile";
          fileId: string;
          data: { error: string; uniqueColumnIndicies: number[] };
      };

export type FindBlankCellsInColumnEvent =
    | {
          type: "PROCESS_FINISHED";
          process: "FindBlankCellsInColumn";
          fileId: string;
          data: {
              columnIndex: number;
              blankRowIndices: number[];
          };
      }
    | {
          type: "FAILED";
          process: "FindBlankCellsInColumn";
          fileId: string;
          data: {
              columnIndex: number;
              error: string;
          };
      };

export type DataManagementEvent = ValidateFileDataEvent | FindDuplicatesInFileEvent | FindBlankCellsInColumnEvent;

/**
 * Listen for ValidateFile and FindDuplicatesInFile events from the data management event topic.
 * @param userId User id
 * @param channel Channel
 * @param callback Called when a ValidateFile or FindDuplicatesInFile event is received. NOTE: The callback must be memoized to prevent reconnecting to the websocket.
 */
export const useDataManagementEventListener = (
    userId: string,
    channel: number,
    callback: (e: DataManagementEvent) => void
) => {
    const callbackRef = useRef(callback);
    useEffect(() => {
        if (callbackRef.current === callback) return;
        callbackRef.current = callback;
        console.warn("Callback changed in useDataManagementEventListener! This causes the websocket to reconnect.");
    }, [callback]);

    useEffect(() => {
        if (!userId) return;

        return openSocketConnection(
            `${process.env.REACT_APP_WS_PROTOCOL || ""}://${
                process.env.REACT_APP_NOTIFICATIONS_URL || ""
            }/ws/v2/datamanagement-events/${localStorage.tenant}/${channel}/${userId}`,
            (message: string) => {
                const event: IncomingEvent = JSON.parse(message);
                if (event.process === "ValidateFile") {
                    const data: ValidateFileData | null = (event.data as ValidateFileData) || null;
                    callback({
                        type: event.type,
                        process: event.process,
                        fileId: event.id ?? "",
                        data: {
                            fields: data ? data.fields : [],
                            warnings: data ? data.warnings : [],
                            errors: data ? data.errors : [],
                        },
                    });
                } else if (event.process === "FindDuplicatesInFile") {
                    const duplicatesEvent = event as Exclude<FindDuplicatesInFileEvent, "fileId"> & { id: string };
                    callback({ ...duplicatesEvent, fileId: duplicatesEvent.id });
                } else if (event.process === "FindBlankCellsInColumn") {
                    const blankCellsEvent = event as Exclude<FindBlankCellsInColumnEvent, "fileId"> & { id: string };
                    callback({ ...blankCellsEvent, fileId: blankCellsEvent.id });
                }
            }
        );
    }, [userId, channel, callback]);
};
