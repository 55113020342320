import { Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { DataGridColumnFragment } from "@/gql/graphql";

import { NaceHeader } from "../../../Components/NaceHeader";
import { TableRiskField } from "../../../Components/TableRiskField";
import { NaceEditField, NaceField } from "../../../InputComponents/NaceField";
import { isRiskColumnType } from "../../../tableUtils";

import { ColumnDefinition } from "./columnDefinition";
import { DragIndicator } from "./DragIndicator";

export function getRiskColumn(column: DataGridColumnFragment): Partial<ColumnDefinition> | null {
    if (!isRiskColumnType(column.type)) {
        return null;
    }
    const isNACEColumn = column.type === "NACE";
    if (isNACEColumn) {
        return {
            editable: true,
            width: 350,
            renderCell: NaceField,
            renderEditCell: NaceEditField,
            valueGetter: ({ value }) => {
                // This gets the first five digits of the NACE code to be backwards compatible with the old NACE codes that used 6 digits
                if (value?.length > 5) {
                    return value.slice(0, 5);
                }
                return value;
            },
            renderHeader: (params) => {
                return (
                    <Stack direction="row" alignItems="center">
                        <DragIndicator />
                        <NaceHeader columnName={params.colDef.headerName ?? ""} />
                    </Stack>
                );
            },
        };
    }
    return {
        renderCell: (params) => {
            return <TableRiskField value={params.value} />;
        },
        renderHeader: (params) => {
            return (
                <Tooltip
                    placement="top"
                    title={
                        <FormattedMessage
                            defaultMessage="This column is read-only"
                            description="Supplier table column header tooltip"
                        />
                    }
                >
                    <Stack direction="row" alignItems="center" width="100%">
                        <DragIndicator />
                        <Typography fontWeight={500} variant="inherit">
                            {params.colDef?.headerName}
                        </Typography>
                    </Stack>
                </Tooltip>
            );
        },
    };
}
