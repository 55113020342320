import { createBrowserHistory } from "history";
import React from "react";
import { createRoot } from "react-dom/client";
import { Router, BrowserRouter } from "react-router-dom";

import { AppProvider } from "@/providers/app";
import { AppProps } from "@/types";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { setupProxyAuth } from "./setupProxyAuth";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

const isProxyToProductionBackend = process.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";

let root: ReturnType<typeof createRoot> | null = null;
window.renderSuppliers = (containerId, props) => {
    renderApp(containerId, props);
};

window.unmountSuppliers = () => {
    if (root) {
        root.unmount();
    }
};

function renderApp(rootNode = "root", props: AppProps = { locale: "en-US", theme: "ignite-ui" }) {
    const container = document.getElementById(rootNode);

    const history = props.history ? props.history : createBrowserHistory();

    if (!container) {
        return;
    }
    root = createRoot(container);

    root.render(
        <React.StrictMode>
            <Router history={history}>
                <AppProvider {...props}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </AppProvider>
            </Router>
        </React.StrictMode>
    );
}

function renderAppLocally(rootNode = "root", props: AppProps = { locale: "en-US", theme: "ignite-ui" }) {
    const domNode = document.getElementById(rootNode);
    if (!domNode) return;
    const root = createRoot(domNode);

    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <AppProvider {...props}>
                    <App />
                </AppProvider>
            </BrowserRouter>
        </React.StrictMode>
    );
}

async function main() {
    if (isProxyToProductionBackend) {
        await setupProxyAuth();
    }

    if (!document.getElementById("Suppliers-container")) {
        renderAppLocally("root", {
            locale: "en-US",
            theme: "ignite-riddle",
        });
    }
}

main();
