import { useMutation } from "@apollo/client";
import { Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DataGridColumnsDocument } from "@/gql/graphql";
import { useAlert } from "@/providers/Alerts";

const deleteSelectColumnChoiceDocument = graphql(`
    mutation DeleteChoice_DeleteSelectColumnChoice($input: DeleteSelectColumnChoiceInput!) {
        deleteSelectColumnChoice(input: $input) {
            choices
        }
    }
`);

interface DeleteChoiceModalProps {
    onClose: () => void;
    deleteChoice: () => void;
    open: boolean;
}

export const DeleteChoiceModal: React.FC<DeleteChoiceModalProps> = ({ onClose, deleteChoice, open }) => {
    return (
        <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xs">
            <DialogTitle>
                <FormattedMessage defaultMessage="Delete option" description="Delete option warning title" />
            </DialogTitle>
            <DialogContent>
                <Typography>
                    <FormattedMessage
                        defaultMessage="Deleting this option will permanently remove it and un-assign it from any suppliers."
                        description="Delete option warning description"
                    />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" type="submit" variant="outlined" onClick={onClose}>
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button color="error" type="submit" variant="outlined" onClick={deleteChoice}>
                    <FormattedMessage defaultMessage="Delete" description="Delete button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface DeleteChoiceButtonProps {
    columnId: string;
    choice: string;
    removeChoice: (arg0: string) => void;
}

export const DeleteChoiceButton: React.FC<DeleteChoiceButtonProps> = ({ columnId, choice, removeChoice }) => {
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const [deleteChoiceMutation] = useMutation(deleteSelectColumnChoiceDocument, {
        refetchQueries: [DataGridColumnsDocument],
    });

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const deleteChoice = (choice: string) => {
        deleteChoiceMutation({
            variables: {
                input: {
                    columnId: columnId,
                    choice: choice,
                },
            },
            onCompleted: () => {
                removeChoice(choice);
                track("Supplier Table: Edit Column: Deleted Option");
                setOpenWarning(false);
            },
            onError: () => {
                setOpenWarning(false);

                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "Error deleting choice: {value}",
                            description: "Alert message when a select column choice could not be deleted",
                        },
                        { value: choice }
                    ),
                    severity: "error",
                });
            },
        });
    };

    return (
        <>
            <IconButton onClick={() => setOpenWarning(true)}>
                <Trash fontSize="small" />
            </IconButton>
            <DeleteChoiceModal
                onClose={() => setOpenWarning(false)}
                deleteChoice={() => deleteChoice(choice)}
                open={openWarning}
            />
        </>
    );
};
