/* eslint-disable no-alert */
const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

export async function setupProxyAuth() {
    const setupURL = `${BASE_URL}/ory/self-service/login/browser`;
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();

    if (setupData.error?.id === "session_already_available") {
        const res = await fetch(`${process.env.REACT_APP_ORY_URL}/sessions/whoami`, { credentials: "include" });
        const j = await res.json();
        localStorage.setItem("tenant", j.identity.metadata_public.tenant);
        document.cookie = `tenant=${j.identity.metadata_public.tenant}; path=/`; // notifier needs tenant cookie
        return;
    }

    const username = process.env.REACT_APP_DEFAULT_USER || prompt("Please enter your username");
    const password = process.env.REACT_APP_DEFAULT_PASSWORD || prompt("Please enter your password");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csrfToken = setupData.ui.nodes.find((node: any) => node?.attributes?.name === "csrf_token").attributes.value;
    const { action } = setupData.ui;
    const flowId = action.split("login?flow=").pop();

    const loginURL = `${BASE_URL}/ory/self-service/login?flow=${flowId}`;

    const res = await fetch(loginURL, {
        method: "POST",
        credentials: "include",
        headers: { accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({
            csrf_token: csrfToken,
            method: "password",
            identifier: username,
            password,
            password_identifier: username,
        }),
    });

    const j = await res.json();
    localStorage.setItem("tenant", j.identity.metadata_public.tenant);
    document.cookie = `tenant=${j.identity.metadata_public.tenant}; path=/`; // notifier needs tenant cookie
}
