export enum GlobalType {
    SupplierName = "Supplier name",
    SupplierCountry = "Supplier country",
    RegNumber = "Company registration number",
}

export type TableActions =
    | "add-column"
    | "add-supplier"
    | "social-risk-evaluation"
    | "edit-column"
    | "delete-column"
    | "generate-contacts"
    | "upsert-contact"
    | "onboard-existing-suppliers";

export const ADD_COLUMN_ID = "ADD_COLUMN";
export const SELECT_COLUMN_ID = "SELECT_COLUMN";
export const CONTACT_COLUMN_ID = "CONTACT_COLUMN";
export const NAME_COLUMN_ID = "name";
export const ONBOARDING_COLUMN_ID = "ONBOARDING_COLUMN";
export const ONBOARDING_APPROVER_COLUMN_ID = "ONBOARDING_APPROVER_COLUMN";
