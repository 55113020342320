import { ApolloCache, useMutation, useQuery } from "@apollo/client";
import { Calendar, ChartColumn, Text, ClipboardList, Menu, Calculator } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    ColumnType,
    DataGridColumnFragment,
    DataGridColumnsDocument,
    DataGridColumnsQuery,
    SpendColumnVariant,
} from "@/gql/graphql";
import { track } from "@/lib/track";
import { useAlert } from "@/providers/Alerts";

import { TableActions } from "../constants";
import { getLastUsedTableViewId, StoredTableState } from "../MuiDataGridTable/tableUtils";

import { AddChoices } from "./selectChoices";
import { SelectQuestionnaire } from "./selectQuestionnaire";
import SpendColumnConfirmation from "./spendColumnConfirmation";

const checkSpendColumnAvailabilityDocument = graphql(`
    query AddColumn_CheckSpendColumnAvailability {
        checkSpendColumnAvailability {
            hasConnectedSpend
            hasDateColumn
        }
    }
`);

const createSpendColumnDocument = graphql(`
    mutation AddColumn_CreateSpendColumn($input: CreateSpendColumnInput!) {
        createSpendColumn(input: $input) {
            column {
                id
                name
            }
        }
    }
`);

const createSupplierTableColumnDocument = graphql(`
    mutation AddColumn_CreateSupplierTableColumn($input: CreateSupplierTableColumnInput!) {
        createSupplierTableColumn(input: $input) {
            supplierTableColumn {
                id
                ...DataGridColumn
            }
        }
    }
`);

const createAssessmentColumnsDocument = graphql(`
    mutation AddColumn_CreateAssessmentColumns($input: CreateAssessmentColumnsInput!) {
        createAssessmentColumns(input: $input) {
            columns {
                id
                name
            }
        }
    }
`);

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<TableActions | false>>;
    onAddColumn: (columnIds: string[]) => void;
}

const defaultValues: {
    name: string;
    questionnaireId: string;
    type: Extract<ColumnType, "TEXT" | "NUMBER" | "DATE" | "SELECT" | "SPEND"> | "ASSESSMENT" | undefined;
    spendOption?: SpendColumnVariant;
    spendYear?: number;
} = {
    name: "",
    questionnaireId: "",
    type: undefined,
};

export const AddColumn: React.FC<Props> = ({ open, setOpen, onAddColumn }) => {
    const [formValues, setFormValues] = useState(defaultValues);
    const [inputError, setInputError] = useState(false);
    const [selectChoices, setSelectChoices] = useState<string[]>([]);
    const [spendColumnAdded, setSpendColumnAdded] = useState(false);

    const yearOptions = [
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3,
        new Date().getFullYear() - 4,
    ];

    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    function handleCacheUpdate(cache: ApolloCache<DataGridColumnsQuery>, newColumns: DataGridColumnFragment[]) {
        const q = cache.readQuery({ query: DataGridColumnsDocument });
        if (!q) return;

        const view = q.getAllSupplierTableConfigs.supplierTableConfigs?.find(
            (config) => config.id === getLastUsedTableViewId()
        );
        if (!view) return;
        const existingState = JSON.parse(view.state) as StoredTableState;
        //Update ordering to have the new column at the end, but before the add column button
        const newOrdering = [
            ...(existingState.columns?.orderedFields ?? []).slice(0, -1),
            ...newColumns.map((newColumn) => newColumn.id),
            "ADD_COLUMN",
        ];
        const newConfigs = q.getAllSupplierTableConfigs.supplierTableConfigs?.map((config) => {
            if (config.id === getLastUsedTableViewId()) {
                return {
                    ...config,
                    state: JSON.stringify({
                        ...existingState,
                        columns: {
                            ...existingState.columns,
                            orderedFields: newOrdering,
                        },
                    }),
                };
            }
            return config;
        });

        cache.writeQuery({
            query: DataGridColumnsDocument,
            data: {
                getSupplierTableMeta: {
                    columns: [...q.getSupplierTableMeta.columns, ...newColumns],
                    __typename: "GetSupplierTableMetaResponse",
                },
                getAllSupplierTableConfigs: {
                    supplierTableConfigs: newConfigs ?? q.getAllSupplierTableConfigs.supplierTableConfigs,
                    __typename: "GetSupplierTableConfigsResponse",
                },
                __typename: "Query",
            },
        });
    }

    const [addColumnMutation] = useMutation(createSupplierTableColumnDocument, {
        refetchQueries: [{ query: DataGridColumnsDocument }],
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to add column, please try again later.",
                    description: "Error message when adding a column fails",
                }),
                severity: "error",
            });
        },
        onCompleted(data) {
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "{name} has been added.",
                        description: "Alert message when a column is added successfully",
                    },
                    {
                        name: data.createSupplierTableColumn.supplierTableColumn.name,
                    }
                ),
            });
            onAddColumn([data.createSupplierTableColumn.supplierTableColumn.id]);
        },
        update(cache, { data }) {
            if (data?.createSupplierTableColumn) {
                handleCacheUpdate(cache, [data.createSupplierTableColumn.supplierTableColumn]);
            }
        },
    });

    const [addSpendColumnMutation] = useMutation(createSpendColumnDocument, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to add column, please try again later.",
                    description: "Error message when adding a column fails",
                }),
                severity: "error",
            });
        },
        onCompleted(data) {
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "{name} has been added.",
                        description: "Alert message when a column is added successfully",
                    },
                    {
                        name: data.createSpendColumn.column.name,
                    }
                ),
            });
            onAddColumn([data.createSpendColumn.column.id]);
        },
        update(cache, { data }) {
            if (data?.createSpendColumn.column) {
                const tableColumn = data.createSpendColumn.column;
                handleCacheUpdate(cache, [
                    {
                        __typename: "SupplierTableColumn",
                        id: tableColumn.id,
                        name: tableColumn.name,
                        type: "SPEND",
                        globalType: "SPEND",
                        typeOptions: null,
                    },
                ]);
            }
        },
    });

    const [addAssessmentColumnMutation] = useMutation(createAssessmentColumnsDocument, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to add column, please try again later.",
                    description: "Error message when adding a column fails",
                }),
                severity: "error",
            });
        },
        onCompleted(data) {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Assessment columns have been added.",
                    description: "Alert message when an assessment column is added successfully",
                }),
            });
            onAddColumn([...data.createAssessmentColumns.columns.map((column) => column.id)]);
        },
        update(cache, { data }) {
            if (data?.createAssessmentColumns.columns) {
                handleCacheUpdate(cache, [
                    ...data.createAssessmentColumns.columns.map((tableColumn) => ({
                        __typename: "SupplierTableColumn",
                        id: tableColumn.id,
                        name: tableColumn.name,
                        type: "ASSESSMENT",
                        globalType: "ASSESSMENT",
                        typeOptions: null,
                    })),
                ] as DataGridColumnFragment[]);
            }
        },
    });

    const { data } = useQuery(checkSpendColumnAvailabilityDocument, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get spend column availability, please try again later.",
                    description: "Error message when checking spend column availability",
                }),
                severity: "error",
            });
        },
    });
    const hasConnectedSpend = data?.checkSpendColumnAvailability?.hasConnectedSpend;
    const hasSpendDateColumn = data?.checkSpendColumnAvailability?.hasDateColumn;

    const addColumn = (name: string, type: ColumnType, selectChoices: string[]) => {
        addColumnMutation({
            variables: {
                input: {
                    name: name,
                    type: type,
                    choices: selectChoices,
                    viewId: getLastUsedTableViewId(),
                },
            },
        });
    };

    const handleFormChange = (name: string, value: string | string[]) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setInputError(false);
    };

    const addAssessmentColumn = () => {
        addAssessmentColumnMutation({
            variables: {
                input: {
                    referenceID: formValues.questionnaireId,
                    viewId: getLastUsedTableViewId(),
                },
            },
        });
    };

    const addSpendColumn = () => {
        let variant: SpendColumnVariant;
        let year: number | undefined;
        switch (formValues.spendOption) {
            case "YEAR":
                variant = "YEAR";
                year = formValues.spendYear;
                break;
            case "TOTAL":
                variant = "TOTAL";
                break;
            default:
                setInputError(true);
                return;
        }
        addSpendColumnMutation({
            variables: {
                input: {
                    displayName: formValues.name,
                    variant: variant,
                    viewId: getLastUsedTableViewId(),
                    year: year,
                },
            },
        });
        setSpendColumnAdded(true);
    };

    const onSubmit = () => {
        if (
            (formValues.name === "" && formValues.type !== "ASSESSMENT") ||
            formValues.type === undefined ||
            (formValues.type === "SELECT" && selectChoices.length === 0) ||
            (formValues.type === "SPEND" && !formValues.spendOption) ||
            (formValues.spendOption === "YEAR" && !formValues.spendYear) ||
            (formValues.type === "ASSESSMENT" && formValues.questionnaireId === "")
        ) {
            setInputError(true);
            return;
        }

        if (formValues.type === "SPEND" && formValues.spendOption) {
            addSpendColumn();
        } else if (formValues.type === "ASSESSMENT") {
            addAssessmentColumn();
            setOpen(false);
        } else {
            addColumn(formValues.name, formValues.type, selectChoices);
            setOpen(false);
        }
        track("Supplier Table: Added Column", {
            type: formValues.type,
        });
    };

    const options: Record<
        Extract<ColumnType, "TEXT" | "NUMBER" | "DATE" | "SELECT" | "SPEND"> | "ASSESSMENT",
        {
            icon: React.ReactNode;
            label: React.ReactNode;
            enabled?: boolean;
            helpText?: string;
        }
    > = {
        TEXT: {
            icon: <Text fontSize="small" />,
            label: <FormattedMessage defaultMessage="Text" description="'Text' column type option" />,
            enabled: true,
        },
        NUMBER: {
            icon: <Calculator fontSize="small" />,
            label: <FormattedMessage defaultMessage="Number" description="'Number' column type option" />,
            enabled: true,
        },
        DATE: {
            icon: <Calendar fontSize="small" />,
            label: <FormattedMessage defaultMessage="Date" description="'Date' column type option" />,
            enabled: true,
        },
        SELECT: {
            icon: <Menu fontSize="small" />,
            label: <FormattedMessage defaultMessage="Select" description="'Select' column type option" />,
            enabled: true,
        },
        ASSESSMENT: {
            icon: <ClipboardList fontSize="small" />,
            label: <FormattedMessage defaultMessage="Assessment" description="'Assessment' column type option" />,
            enabled: true,
        },
        SPEND: {
            icon: <ChartColumn fontSize="small" />,
            label: <FormattedMessage defaultMessage="Spend" />,
            enabled: hasConnectedSpend ?? false,
            helpText: hasConnectedSpend
                ? undefined
                : formatMessage({ defaultMessage: "To create a spend column, you need to configure a spend table" }),
        },
    };

    const icon = formValues.type ? options[formValues.type].icon : undefined;

    return (
        <>
            <form>
                <Dialog open={open && !spendColumnAdded} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
                    <DialogTitle>
                        <FormattedMessage defaultMessage="Add a Column" description="Add column title" />
                    </DialogTitle>
                    <DialogContent>
                        <Stack minHeight={(theme) => theme.spacing(35)}>
                            <Typography mb={3}>
                                <FormattedMessage
                                    defaultMessage="Select column type"
                                    description="Add column helptext"
                                />
                            </Typography>
                            <FormControl>
                                <InputLabel id="select-type" size="small">
                                    <FormattedMessage defaultMessage="Type" description="Column type label" />
                                </InputLabel>
                                <Select
                                    name="type"
                                    value={formValues.type}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        handleFormChange(name, value);
                                    }}
                                    label={<FormattedMessage defaultMessage="Type" description="Column type label" />}
                                    error={inputError && formValues.type === undefined}
                                    size="small"
                                    fullWidth
                                    startAdornment={icon && <InputAdornment position="start">{icon}</InputAdornment>}
                                    renderValue={(value) => options[value].label}
                                >
                                    {Object.entries(options).map(([key, value]) => (
                                        <MenuItem
                                            key={key}
                                            value={key}
                                            disabled={!value.enabled}
                                            sx={{
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            <ListItemIcon>{value.icon}</ListItemIcon>
                                            <Stack direction="column">
                                                {value.label}
                                                {value.helpText && <FormHelperText>{value.helpText}</FormHelperText>}
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {formValues.type && formValues.type !== "ASSESSMENT" && (
                                <TextField
                                    margin="normal"
                                    sx={{ mt: 2 }}
                                    id="name-input"
                                    name="name"
                                    label={
                                        <FormattedMessage
                                            defaultMessage="Title"
                                            description="Add column title placeholder"
                                        />
                                    }
                                    type="text"
                                    value={formValues.name}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        handleFormChange(name, value);
                                    }}
                                    error={inputError && formValues.name === ""}
                                    size="small"
                                />
                            )}
                            {formValues.type == "ASSESSMENT" && (
                                <SelectQuestionnaire
                                    value={formValues.questionnaireId}
                                    onQuestionnaireChange={(value: string) =>
                                        handleFormChange("questionnaireId", value)
                                    }
                                />
                            )}
                            {formValues.type == "SELECT" && (
                                <AddChoices
                                    selectChoices={selectChoices}
                                    updateChoices={setSelectChoices}
                                    inputError={inputError}
                                />
                            )}
                            {formValues.type == "SPEND" && (
                                <FormControl error={inputError && !formValues.spendOption}>
                                    <InputLabel id="period-label">
                                        <FormattedMessage
                                            defaultMessage="Period"
                                            description="Add spend column period label"
                                        />
                                    </InputLabel>
                                    <Select
                                        value={formValues.spendYear}
                                        onChange={(event) => {
                                            if (event.target.value === "TOTAL") {
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    spendOption: "TOTAL",
                                                    spendYear: undefined,
                                                }));
                                            } else {
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    spendOption: "YEAR",
                                                    spendYear: event.target.value as number,
                                                }));
                                            }
                                        }}
                                    >
                                        <MenuItem value="TOTAL">
                                            <FormattedMessage
                                                defaultMessage="Total spend"
                                                description="Add spend column variant total spend"
                                            />
                                        </MenuItem>

                                        {yearOptions.map((year) => (
                                            <MenuItem key={year} value={year} disabled={!hasSpendDateColumn}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!hasSpendDateColumn && (
                                        <FormHelperText>
                                            <FormattedMessage defaultMessage="You don't have a date column in your transaction table toggled with the global tag 'Spend date'. This is needed for spend columns other than 'Total'. Feel free to use the chat for guidance to set this up" />
                                        </FormHelperText>
                                    )}

                                    {formValues.spendOption === "YEAR" && !inputError && (
                                        <FormHelperText>
                                            <FormattedMessage
                                                defaultMessage="Spend for the specific calendar year."
                                                description="Spend last year column explanation"
                                            />
                                        </FormHelperText>
                                    )}
                                    {!formValues.spendOption && inputError && (
                                        <FormHelperText>
                                            <FormattedMessage
                                                defaultMessage="You must choose at least one option."
                                                description="Spend column error message"
                                            />
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="secondary" onClick={() => setOpen(false)}>
                            <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            <FormattedMessage defaultMessage="Add" description="Add button" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
            <SpendColumnConfirmation open={spendColumnAdded} handleClose={() => setOpen(false)} />
        </>
    );
};
