import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DataGridColumnsDocument, TableConfigsMenuButton_SupplierTableConfigFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { StoredTableState } from "../../../tableUtils";

type CreateViewModalProps = {
    readonly isOpen: boolean;
    readonly close: VoidFunction;
    readonly currentState?: StoredTableState;
    readonly onSuccess: (id: string, view: TableConfigsMenuButton_SupplierTableConfigFragment) => void;
};

graphql(`
    fragment CreateViewModal_SupplierTableConfig on SupplierTableConfig {
        id
        state
        createdAt
        displayName
    }
`);

const createSupplierTableConfigMutation = graphql(`
    mutation CreateViewModal_createSupplierTableConfigMutation($input: CreateSupplierTableConfigInput!) {
        createSupplierTableConfig(input: $input) {
            supplierTableConfig {
                ...CreateViewModal_SupplierTableConfig
            }
        }
    }
`);

export const CreateViewModal = ({ isOpen, close, onSuccess, currentState }: CreateViewModalProps) => {
    const [viewName, setViewName] = useState("");
    const [serverError, setServerError] = useState(false);
    const [error, setError] = useState(false);
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const closeAndClearErrors = useCallback(() => {
        close();
        setError(false);
        setServerError(false);
    }, [close, setError, setServerError]);
    const [createConfig] = useMutation(createSupplierTableConfigMutation);
    const createView = () => {
        setServerError(false);
        if (!viewName) {
            setError(true);
            return;
        }
        setError(false);
        createConfig({
            variables: {
                input: {
                    displayName: viewName,
                    state: JSON.stringify({ ...currentState, preferencePanel: { open: false } }),
                },
            },
            onCompleted: (data) => {
                close();
                const view = data?.createSupplierTableConfig?.supplierTableConfig ?? {};
                if (!view) {
                    return;
                }
                track("Supplier Table: Created new view", {
                    config: data?.createSupplierTableConfig?.supplierTableConfig,
                });
                try {
                    onSuccess(view.id, view);
                } catch (e) {
                    Sentry.captureException(e, { tags: { app: "suppliers-app" } });
                    alertUser({
                        value: formatMessage({
                            defaultMessage: "An error occurred while parsing the saved configuration.",
                            description: "Alert message when loading a saved table config fails.",
                        }),
                        severity: "error",
                    });
                }
            },
            onError: () => {
                setServerError(true);
            },
            update(cache, { data }) {
                const existingData = cache.readQuery({ query: DataGridColumnsDocument });
                if (!data?.createSupplierTableConfig.supplierTableConfig) return;
                cache.writeQuery({
                    query: DataGridColumnsDocument,
                    data: {
                        __typename: "Query",
                        getAllSupplierTableConfigs: {
                            __typename: "GetSupplierTableConfigsResponse",
                            supplierTableConfigs: [
                                ...(existingData?.getAllSupplierTableConfigs.supplierTableConfigs ?? []),
                                data?.createSupplierTableConfig.supplierTableConfig,
                            ],
                        },
                        getSupplierTableMeta: existingData?.getSupplierTableMeta ?? {
                            __typename: "GetSupplierTableMetaResponse",
                            columns: [],
                        },
                    },
                });
            },
        });
    };
    return (
        <Dialog open={isOpen} onClose={closeAndClearErrors}>
            <DialogTitle>
                <Stack>
                    <FormattedMessage defaultMessage="Create a view" description="Create a view title" />
                    <Typography variant="textXs">
                        <FormattedMessage
                            defaultMessage="Please enter a name for the new view."
                            description="Create a view modal caption"
                        />
                    </Typography>
                </Stack>
            </DialogTitle>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    createView();
                }}
            >
                <DialogContent>
                    <TextField
                        margin="dense"
                        label={formatMessage({ defaultMessage: "Name", description: "Create view input label" })}
                        fullWidth
                        value={viewName}
                        onChange={(event) => setViewName(event.target.value)}
                        error={error || serverError}
                        required
                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                        autoFocus
                        helperText={
                            serverError
                                ? formatMessage({
                                      defaultMessage: "An error occurred while saving the view",
                                      description: "Error when creating a table view fails",
                                  })
                                : error
                                  ? formatMessage({
                                        defaultMessage: "Name cannot be empty",
                                        description: "Create view input error message",
                                    })
                                  : ""
                        }
                    />
                </DialogContent>
            </form>
            <DialogActions>
                <Button variant="text" onClick={closeAndClearErrors} size="medium" color="inherit">
                    <FormattedMessage defaultMessage="Close" description="Close button" />
                </Button>
                <Button variant="contained" onClick={createView} size="medium" color="primary">
                    <FormattedMessage defaultMessage="Create" description="Create button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
