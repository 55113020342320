import { Check, Trash, ArrowTopTray } from "@ignite-analytics/icons";
import { CircularProgress, Container, Slide, Stack, Typography } from "@mui/material";
import React, { DragEventHandler, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useSupplierUploadContext } from "../providers/SupplierUpload";

interface UploadCompleteProps {
    parentRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const UploadComplete: React.FC<UploadCompleteProps> = ({ parentRef }) => {
    return (
        <Slide in direction="up" container={parentRef?.current} timeout={1000}>
            <Check sx={{ fontSize: "80px", color: "green" }} />
        </Slide>
    );
};
interface DropZoneContentProps {
    loading: boolean;
    uploadComplete: boolean;
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const DropZoneContent: React.FC<DropZoneContentProps> = ({ loading, uploadComplete, containerRef }) => {
    if (uploadComplete) {
        return <UploadComplete parentRef={containerRef} />;
    } else if (loading) {
        return <CircularProgress />;
    } else {
        return (
            <>
                <ArrowTopTray />
                <Typography variant="displaySm">
                    <FormattedMessage
                        defaultMessage="Drop your supplier file here"
                        description="Drop your supplier file here"
                    />
                </Typography>
                <Typography variant="textSm">
                    <FormattedMessage
                        defaultMessage="or click to browse local files"
                        description="or click to browse local files"
                    />
                </Typography>
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Only one Excel file" description="Only one Excel file" />
                </Typography>
            </>
        );
    }
};

interface Props {
    onFileChange: (file?: File) => void;
    uploadComplete: boolean;
    loading: boolean;
}

/**
 * A component that allows the user to drag and drop a file onto it.
 * @param onFileChange A callback that is called when a file is dropped.
 * @returns A component that allows the user to drag and drop a file onto it.
 */
export const ExcelFileDropZone = ({ onFileChange, loading, uploadComplete }: Props) => {
    const [{ file }, dispatch] = useSupplierUploadContext();

    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);

    useEffect(
        function clearFileInput() {
            // Allow the user to upload the same file again after deleting
            if (file !== undefined) return;
            if (!fileInputRef.current) return;
            // NOTE: very hacky!
            fileInputRef.current.value = "";
        },
        [file]
    );

    const dropHandler: DragEventHandler = (ev) => {
        onFileChange(ev.dataTransfer.files[0]);
        dispatch({ type: "SET_FILE", file: ev.dataTransfer.files[0] });
        ev.preventDefault();
    };

    const dragOverHandler: DragEventHandler = (ev) => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    };

    const handleDelete = () => {
        dispatch({ type: "SET_FILE", file: undefined });
        onFileChange();
    };

    const handleFileChange = (files: FileList | null) => {
        if (files?.length) {
            dispatch({ type: "SET_FILE", file: files[0] });
            onFileChange(files[0]);
        } else {
            handleDelete();
        }
    };

    return (
        <Stack justifyContent="center" alignItems="center" gap={1}>
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: "100%",
                    height: "400px",
                    border: "2px dashed",
                    borderRadius: "10px",
                    borderColor: (theme) => theme.palette.grey[500],
                    backgroundColor: (theme) => theme.palette.grey[100],
                    cursor: "pointer",
                    overflow: "hidden",
                }}
                id="drop_zone"
                onDrop={dropHandler}
                onDragOver={dragOverHandler}
                onClick={() => {
                    document.getElementById("fileInputField")?.click();
                }}
                ref={containerRef}
            >
                <DropZoneContent loading={loading} uploadComplete={uploadComplete} containerRef={containerRef} />
                <Container sx={{ opacity: 0, cursor: "pointer" }}>
                    <input
                        ref={fileInputRef}
                        style={{ cursor: "pointer" }}
                        type="file"
                        id="fileInputField"
                        accept=".xlsx"
                        onChange={(ev) => handleFileChange(ev.target.files)}
                    />
                </Container>
            </Stack>
            {file && (
                <Stack direction="row" spacing={2}>
                    <Typography variant="textMd">{file.name}</Typography>
                    <Trash sx={{ cursor: "pointer" }} onClick={handleDelete} />
                </Stack>
            )}
        </Stack>
    );
};
