import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { SUPPLIER_COLUMN_MESSAGES } from "../constants";

const RECOMMENDED_COLUMNS = ["name", "country", "regNumber", "spend"] as const;

export const RecommendedColumns: React.FC = () => (
    <Stack>
        <Typography variant="displayXs">
            <FormattedMessage
                defaultMessage="Recommended columns in your excel file:"
                description="Recommended columns in your excel file:"
            />
        </Typography>
        <ul style={{ marginTop: "0px", paddingInlineStart: "30px" }}>
            {RECOMMENDED_COLUMNS.map((column) => (
                <li key={column}>
                    <Typography>
                        <FormattedMessage {...SUPPLIER_COLUMN_MESSAGES[column]} />
                        {column === "name" && (
                            <FormattedMessage
                                defaultMessage="* (mandatory)"
                                description="Suffix for mandatory fields"
                            />
                        )}
                    </Typography>
                </li>
            ))}
        </ul>
    </Stack>
);
