import { Trash } from "@ignite-analytics/icons";
import { IconButton, Tooltip } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { ConfirmDeleteSuppliers } from "./ConfirmDeleteSuppliers";

interface DeleteSuppliersProps {
    selectedSuppliers: GridRowSelectionModel;
    onDelete: () => void;
}

export const DeleteSuppliers: React.FC<DeleteSuppliersProps> = ({ selectedSuppliers, onDelete }) => {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    return (
        <div>
            <Tooltip
                title={
                    <FormattedMessage
                        defaultMessage="Delete selected suppliers"
                        description="Delete suppliers button"
                    />
                }
            >
                <span>
                    <Tooltip title={<FormattedMessage defaultMessage="Delete" description="Delete" />}>
                        <IconButton
                            onClick={() => setConfirmDeleteOpen(true)}
                            disabled={selectedSuppliers.length === 0}
                            size="2xsmall"
                            color="secondary"
                        >
                            <Trash color="inherit" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </span>
            </Tooltip>

            {confirmDeleteOpen && (
                <ConfirmDeleteSuppliers
                    selectedSuppliers={selectedSuppliers}
                    onClose={() => setConfirmDeleteOpen(false)}
                    onDelete={onDelete}
                />
            )}
        </div>
    );
};
