import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import React, { useCallback, useMemo, useState } from "react";

import { ContentLayout } from "@/components";
import { graphql } from "@/gql";
import { UpsertContactModal_ContactFragment } from "@/gql/graphql";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/permissions";

import { AddColumn } from "../AddColumn";
import { AddSupplierModal } from "../AddSupplier";
import GenerateContacts from "../ColumnActions/GenerateContacts";
import { ADD_COLUMN_ID, CONTACT_COLUMN_ID, SELECT_COLUMN_ID, TableActions } from "../constants";
import { DeleteColumn } from "../DeleteColumn";
import { EditColumn } from "../EditColumn";
import { FilterInput } from "../InlineFilter/types";
import { SocialRiskEvaluation } from "../SocialRiskEvaluation";

import { OnboardExistingSuppliersModal } from "./Components/OnboardExistingSuppliersModal";
import UpsertContactModal from "./Components/UpsertContactModal";
import { ColumnDefinition } from "./hooks/useTableData/useDataGridColumns/columnDefinition";
import { TableData, useSupplierTable } from "./hooks/useTableData/useTableData";
import { MuiTable } from "./MuiTable";
import { SupplierTableHeader } from "./SupplierTableHeader";
import { getLastUsedTableViewId, isRiskColumnType, StoredTableState } from "./tableUtils";

type UpsertContactModalData = {
    readonly contact?: UpsertContactModal_ContactFragment;
    readonly supplierId: string;
};
export type HandleOpenUpsertContactModal = (data: UpsertContactModalData) => void;

graphql(`
    fragment ActionBar_SupplierTableColumn on SupplierTableColumn {
        id
        ...InlineFilter_SupplierTableColumn
    }
`);

interface SupplierTableProps {
    readonly baseFilter: FilterInput[];
    readonly isOnboarding?: boolean;
}

const DEFAULT_VIEW = "default";

export const SupplierTable: React.FC<SupplierTableProps> = ({ baseFilter, isOnboarding = false }) => {
    useSetBreadcrumbs(isOnboarding ? "onboardingList" : "overview");

    const [openModal, setOpenModal] = useState<TableActions | false>(false);
    const [upsertContactModalData, setUpsertContactModalData] = useState<UpsertContactModalData | null>(null);
    const [deletingColumnId, setDeletingColumnId] = useState<string>();
    const [editColumnId, setEditColumnId] = useState<string>();
    const [currentState, setCurrentState] = useState<StoredTableState>();

    const isEditor = usePermission({ object: "general", relation: "write" });
    const handleAddColumn = useCallback(() => {
        setOpenModal("add-column");
    }, []);

    const handleDeleteColumn = useCallback((columnId: string) => {
        setDeletingColumnId(columnId);
        setOpenModal("delete-column");
    }, []);

    const handleEditColumn = useCallback((columnId: string) => {
        setEditColumnId(columnId);
        setOpenModal("edit-column");
    }, []);

    const handleOpenUpsertContactModal = useCallback(
        (data: UpsertContactModalData) => {
            setOpenModal("upsert-contact");
            setUpsertContactModalData(data);
        },
        [setOpenModal]
    );

    const openGenerateContactsModal = useCallback(() => {
        setOpenModal("generate-contacts");
    }, []);

    const { dataGridProps, actionBarProps, supplierTableMeta, views, filter, setFilter, refetchSupplierContacts } =
        useSupplierTable(
            handleAddColumn,
            handleOpenUpsertContactModal,
            isEditor,
            setCurrentState,
            baseFilter,
            isOnboarding
        );

    // Sort columns by order, if the order is not provided or the view is the default view, return the columns as is
    const sortColumnsByOrder = (columns: readonly GridColDef<TableData>[], order?: string[]): ColumnDefinition[] => {
        const columnMap = new Map(columns.map((column) => [column.field, column]));
        if (getLastUsedTableViewId() === DEFAULT_VIEW || getLastUsedTableViewId() === null) {
            return columns as ColumnDefinition[];
        }
        if (!order) return columns as ColumnDefinition[];
        return order.filter((field) => columnMap.has(field)).map((field) => columnMap.get(field) as ColumnDefinition);
    };

    const orderedColumns = useMemo(() => {
        if (!currentState?.columns?.orderedFields) return dataGridProps.columns;
        return sortColumnsByOrder(dataGridProps.columns, currentState?.columns?.orderedFields);
    }, [currentState?.columns?.orderedFields, dataGridProps.columns]);

    const handleOnStateChange = useCallback(
        (state: StoredTableState) => {
            dataGridProps?.apiRef?.current?.restoreState?.({
                ...state,
                preferencePanel: { open: false },
                filter: { filterModel: { items: [] } },
            });
            if (Array.isArray(state.filter)) {
                setFilter(state.filter);
            } else {
                setFilter([]);
            }
            setCurrentState(state);
        },
        [dataGridProps?.apiRef, setFilter]
    );

    const onAddColumn = useCallback(
        (addedColumnIds: string[]) => {
            const newOrdering = [
                ...(currentState?.columns?.orderedFields ?? []).slice(0, -1),
                ...addedColumnIds,
                "ADD_COLUMN",
            ];
            handleOnStateChange({
                ...(currentState ?? {}),
                columns: {
                    ...(currentState?.columns ?? {}),
                    orderedFields: newOrdering,
                },
                filter: currentState?.filter ?? [],
            });
        },
        [currentState, handleOnStateChange]
    );

    const onDeleteColumn = useCallback(
        (columnId: string) => {
            const newOrdering = (currentState?.columns?.orderedFields ?? []).filter((id) => id !== columnId);
            handleOnStateChange({
                ...(currentState ?? {}),
                columns: {
                    ...(currentState?.columns ?? {}),
                    orderedFields: newOrdering,
                },
                filter: currentState?.filter ?? [],
            });
        },
        [currentState, handleOnStateChange]
    );

    const editingColumn = useMemo(() => {
        return supplierTableMeta?.columns.find((column) => column.id === editColumnId);
    }, [editColumnId, supplierTableMeta?.columns]);

    const tableWidth = `calc(100% - 10px)`;

    const { selectedSuppliers } = actionBarProps;

    return (
        <ContentLayout>
            <Stack direction="column">
                <SupplierTableHeader
                    isOnboarding={isOnboarding}
                    isEditor={isEditor}
                    setOpenModal={setOpenModal}
                    hasSelectedSuppliers={selectedSuppliers.length > 0}
                />
                <Stack
                    direction="row"
                    position="absolute"
                    top={0}
                    bottom={0}
                    right={0}
                    left={0}
                    display="flex"
                    paddingX={3}
                    minHeight="calc(85vh + 64px)"
                    marginTop={11}
                    paddingBottom={3}
                >
                    <Stack width={tableWidth} sx={{ transition: "width 300ms, height 300ms" }}>
                        <MuiTable
                            {...actionBarProps}
                            setOpenModal={setOpenModal}
                            tableConfigMenuProps={{
                                onViewSelect: handleOnStateChange,
                                currentTableState: currentState ?? { filter: [] },
                                isEditor,
                                views: views,
                            }}
                            dataGridProps={dataGridProps}
                            columns={orderedColumns}
                            isEditor={isEditor}
                            onDeleteColumn={handleDeleteColumn}
                            onEditColumn={handleEditColumn}
                            onGenerateContacts={openGenerateContactsModal}
                            columnsMeta={supplierTableMeta?.columns ?? []}
                        />
                    </Stack>
                    {/* Modals  */}
                    {openModal === "add-supplier" && (
                        <AddSupplierModal
                            isOpen={openModal === "add-supplier"}
                            setOpen={setOpenModal}
                            startIndex={
                                dataGridProps.paginationModel
                                    ? dataGridProps.paginationModel.page * dataGridProps.paginationModel.pageSize
                                    : 0
                            }
                            pageRange={dataGridProps.paginationModel?.pageSize ?? 100}
                            filterJson={JSON.stringify(filter)}
                        />
                    )}
                    {openModal === "social-risk-evaluation" && (
                        <SocialRiskEvaluation
                            onClose={() => setOpenModal(false)}
                            columns={supplierTableMeta?.columns}
                            handlePolling={() => null}
                        />
                    )}
                    {openModal === "add-column" && (
                        <AddColumn open={openModal === "add-column"} setOpen={setOpenModal} onAddColumn={onAddColumn} />
                    )}
                    {openModal === "delete-column" && deletingColumnId && (
                        <DeleteColumn
                            columnId={deletingColumnId}
                            open={openModal === "delete-column"}
                            onClose={(columnId?: string) => {
                                setOpenModal(false);
                                setFilter((prev) => prev.filter((f) => f.column_id !== columnId));
                            }}
                            onDeleteColumn={onDeleteColumn}
                        />
                    )}
                    {openModal === "edit-column" && editingColumn && (
                        <EditColumn
                            open={openModal === "edit-column"}
                            setOpen={setOpenModal}
                            column={editingColumn}
                            isRiskColumn={isRiskColumnType(editingColumn.type)}
                        />
                    )}
                    {openModal === "generate-contacts" && (
                        <GenerateContacts
                            open={openModal === "generate-contacts"}
                            onClose={() => setOpenModal(false)}
                            columns={dataGridProps.columns
                                .filter(
                                    ({ field, headerName }) =>
                                        field &&
                                        headerName &&
                                        ![ADD_COLUMN_ID, CONTACT_COLUMN_ID, SELECT_COLUMN_ID].includes(field)
                                )
                                .map(({ field, headerName }) => ({
                                    id: field,
                                    name: headerName ?? "",
                                }))}
                            refetch={refetchSupplierContacts}
                        />
                    )}
                    {openModal === "upsert-contact" && (
                        <UpsertContactModal
                            open={openModal === "upsert-contact"}
                            onClose={() => {
                                setOpenModal(false);
                                setUpsertContactModalData(null);
                            }}
                            contact={upsertContactModalData?.contact}
                            supplierId={upsertContactModalData?.supplierId ?? ""}
                        />
                    )}
                    {openModal === "onboard-existing-suppliers" && (
                        <OnboardExistingSuppliersModal
                            open={openModal === "onboard-existing-suppliers"}
                            onClose={() => setOpenModal(false)}
                            selectedSuppliers={selectedSuppliers}
                        />
                    )}
                </Stack>
            </Stack>
        </ContentLayout>
    );
};
