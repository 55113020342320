import { formatValue } from "@ignite-analytics/locale";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

export const HighRiskSpendShare: React.FC = () => {
    const { formatMessage } = useIntl();

    const highRiskShare = 0.156 * 100;
    return (
        <InsightBox>
            <Stack justifyContent="space-between" height="100%">
                <Stack spacing={1.5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="textSm" color="text.text-helper">
                            <FormattedMessage
                                defaultMessage="Spend with high social risk suppliers"
                                description="spend with high risk suppliers header"
                            />
                        </Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="displayMd" fontWeight="medium">{`${formatValue(
                            highRiskShare,
                            2,
                            undefined,
                            true
                        )}%`}</Typography>
                        <LinearProgress
                            variant="determinate"
                            value={highRiskShare}
                            color={highRiskShare < 33 ? "success" : highRiskShare < 66 ? "warning" : "error"}
                            sx={{
                                borderRadius: 4,
                                height: 6,
                                backgroundColor: (theme) => theme.palette.background.variant,
                            }}
                        />
                    </Stack>
                </Stack>
                <ViewButton
                    buttonText={formatMessage({
                        defaultMessage: "View Suppliers",
                        description: "View suppliers button",
                    })}
                    onClick={() => {}}
                />
            </Stack>
        </InsightBox>
    );
};
