import { DataGridPro, DataGridProProps, GridCellParams, gridClasses, GridTreeNode } from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";

import { ActionBar_SupplierTableColumnFragment } from "@/gql/graphql";

import { TableActions } from "../constants";

import { CustomColumnMenu, CustomColumnPanel } from "./Components/ColumnActions";
import { SupplierDataGridToolbar } from "./Components/SupplierTableActionBar/SupplierDataGridToolbar";
import { TableConfigsMenuButtonProps } from "./Components/SupplierTableActionBar/ViewsButton/TableConfigsMenuButton";
import { customGridClasses } from "./constants";
import { ActionBarProps, ManagedDataGridProps, TableData } from "./hooks/useTableData/useTableData";

interface MuiTableProps extends ActionBarProps {
    setOpenModal: (action: TableActions | false) => void;
    tableConfigMenuProps: TableConfigsMenuButtonProps;
    isEditor: boolean;
    onDeleteColumn: (columnId: string) => void;
    onEditColumn: (columnId: string) => void;
    onGenerateContacts: VoidFunction;
    dataGridProps: ManagedDataGridProps;
    columns: DataGridProProps["columns"];
    columnsMeta: ActionBar_SupplierTableColumnFragment[];
}

/**
 * These are purposely defined outside the component to avoid them being recreated on every render.
 */
const customDataGridStyling: DataGridProProps["sx"] = {
    "--unstable_DataGrid-radius": "0px",
    /**
     * For column headers that have `draggable="true"` attribute we display the drag indicator icon
     */
    [`& .${gridClasses.columnHeaderDraggableContainer}[draggable="true"] .${customGridClasses.dragIndicator}`]: {
        display: "inline-block",
    },
    "& .MuiDataGrid-cell": {
        borderLeft: "none", // extract out to theme
        borderRight: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
        "& .MuiDataGrid-columnHeader": {
            borderTop: (theme) => `1px solid ${theme.palette.tokens?.border.border200}`, // extract out to theme
            borderLeft: "none",
            borderRight: "none",
        },
    },
    "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: (theme) => theme.palette.tokens?.chip.primary, // extract out to theme
    },
    borderRadius: 1,
};

declare module "@mui/x-data-grid" {
    interface ColumnMenuPropsOverrides {
        slotProps: {
            onDeleteColumn: (columnId: string) => void;
            onEditColumn: (columnId: string) => void;
            onGenerateContacts: VoidFunction;
        };
    }
}

const slots: DataGridProProps["slots"] = {
    columnMenu: CustomColumnMenu,
    columnsPanel: CustomColumnPanel, // TODO: fix this to show the columns panel
};

const pageSizeOptions: DataGridProProps["pageSizeOptions"] = [50, 100, 500, 1000, 2000];

export const MuiTable: React.FC<MuiTableProps> = ({
    setOpenModal,
    tableConfigMenuProps,
    isEditor,
    onEditColumn,
    onDeleteColumn,
    onGenerateContacts,
    dataGridProps,
    columns,
    columnsMeta,
    ...actionBarProps
}) => {
    const isCellEditable: DataGridProProps["isCellEditable"] = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (params: GridCellParams<any, TableData, TableData, GridTreeNode>) =>
            Boolean(params.colDef.editable) && isEditor,
        [isEditor]
    );

    const [nameSearchTerm, setNameSearchTerm] = useState("");

    const slotProps: DataGridProProps["slotProps"] = useMemo(() => {
        if (!isEditor) return undefined;
        return {
            columnMenu: {
                slotProps: {
                    onDeleteColumn,
                    onEditColumn,
                    onGenerateContacts,
                },
            },
            panel: {
                placement: "bottom-end",
            },
        };
    }, [isEditor, onDeleteColumn, onEditColumn, onGenerateContacts]);

    const memoizedToolbar = useMemo(() => {
        return (
            <SupplierDataGridToolbar
                isEditor={isEditor}
                columns={columnsMeta}
                apiRef={dataGridProps.apiRef}
                setOpenModal={setOpenModal}
                searchTerm={nameSearchTerm}
                setSearchTerm={setNameSearchTerm}
                tableConfigMenuProps={tableConfigMenuProps}
                {...actionBarProps}
            />
        );
    }, [
        actionBarProps,
        columnsMeta,
        dataGridProps.apiRef,
        isEditor,
        nameSearchTerm,
        setOpenModal,
        tableConfigMenuProps,
    ]);

    return (
        <DataGridPro<TableData>
            {...dataGridProps}
            columns={columns}
            sx={customDataGridStyling}
            showCellVerticalBorder
            showColumnVerticalBorder
            checkboxSelection={isEditor}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            sortingMode="server"
            /**
             * Pagination logic is performed by Apollo Types policies in `@/lib/apollo`, returning a paginated
             * response for the getSuppliers query, even though there may be more suppliers present in the cache.
             */
            paginationMode="server"
            isCellEditable={isCellEditable}
            disableColumnFilter
            pagination
            pageSizeOptions={pageSizeOptions}
            slots={{
                ...slots,
                toolbar: () => memoizedToolbar,
            }}
            slotProps={slotProps}
        />
    );
};
