import { useQuery } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { AddSupplier_ColumnFragment, ColumnType, SelectOptions } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { GlobalType, TableActions } from "../constants";
import { ENRICHMENT_ID, NORMALIZED_INTO_ID } from "../MuiDataGridTable/constants";

import { useAddSupplier } from "./hooks";

const getSupplierTableMetaDocument = graphql(`
    query AddSupplier_GetSupplierTableMeta {
        getSupplierTableMeta {
            elasticIndex
            columns {
                id
                ...AddSupplier_Column
            }
        }
    }
`);

graphql(`
    fragment AddSupplier_Column on SupplierTableColumn {
        id
        name
        type
        globalType
        typeOptions {
            ... on SelectOptions {
                choices
            }
        }
    }
`);

interface Props {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<TableActions | false>>;
    startIndex: number;
    pageRange: number;
    filterJson: string | undefined;
}

export const AddSupplierModal: React.FC<Props> = ({ isOpen, setOpen, startIndex, pageRange, filterJson }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const { data } = useQuery(getSupplierTableMetaDocument, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to load supplier data",
                    description: "Alert message when supplier data fails to load",
                }),
                severity: "error",
            });
        },
    });

    const inputFields = data
        ? data.getSupplierTableMeta.columns.filter(
              (field: AddSupplier_ColumnFragment) =>
                  (field.type === "TEXT" ||
                      field.type === "NUMBER" ||
                      field.type === "DATE" ||
                      field.type === "SELECT") &&
                  field.id != NORMALIZED_INTO_ID &&
                  field.id != ENRICHMENT_ID
          )
        : [];

    const { addSupplier } = useAddSupplier(inputFields, startIndex, pageRange, filterJson, {
        onCompleted: (data) =>
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "{name} has been added",
                        description: "Alert message when a supplier is added successfully",
                    },
                    {
                        name: data.createSupplier.supplier.name,
                    }
                ),
                title: formatMessage({
                    defaultMessage: "Success",
                    description: "Alert title when a supplier is added successfully",
                }),
                severity: "success",
            }),
        onError: (error) =>
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "An error occurred while adding the supplier: {error}",
                        description: "Alert message when a supplier fails",
                    },
                    {
                        error: error.message,
                    }
                ),
                title: formatMessage({
                    defaultMessage: "Error",
                    description: "Alert title when adding a supplier fails",
                }),
                severity: "error",
            }),
    });

    const nameColumn = inputFields.find((field) => field.globalType === GlobalType.SupplierName);
    const initialValues = Object.fromEntries(
        inputFields.filter((field) => field.id !== nameColumn?.id).map((field) => [field.id, ""])
    );
    const [formValues, setFormValues] = useState(initialValues);
    const [missingName, setMissingName] = useState<boolean>(false);

    const getInputType = (fieldType: ColumnType) => {
        switch (fieldType) {
            case "NUMBER":
                return "number";
            case "DATE":
                return "date";
            case "SELECT":
                return "select";
            default:
                return "text";
        }
    };

    const handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name) {
            setFormValues({
                ...formValues,
                [name]: value ? value : "",
            });
            if (name === "name") {
                if (value !== "") {
                    setMissingName(false);
                } else {
                    setMissingName(true);
                }
            }
        }
    };

    const validateInput = () => {
        if (formValues["name"]) {
            addSupplier(formValues);
            setOpen(false);
            track("Supplier Table: Added Supplier", {
                name: formValues.name,
            });
        } else {
            setMissingName(true);
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <FormattedMessage defaultMessage="Add supplier" description="Add supplier modal title" />
                    <IconButton onClick={() => setOpen(false)}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack>
                    <Typography marginTop={2} marginBottom={1}>
                        <FormattedMessage
                            defaultMessage="Enter the name of the supplier"
                            description="Add supplier modal name label"
                        />
                    </Typography>
                    <TextField
                        required
                        error={missingName}
                        key={nameColumn?.id}
                        label={nameColumn?.name}
                        name="name"
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Stack>
                <Typography marginTop={3}>
                    <FormattedMessage
                        defaultMessage="Enter optional supplier attributes:"
                        description="Optional supplier attributes label"
                    />
                </Typography>
                <Stack
                    direction="column"
                    maxHeight={500}
                    spacing={2}
                    sx={{
                        pr: 1,
                        marginRight: "-16px",
                        paddingTop: 1,
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 8,
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "#E7EAF3",
                            borderRadius: (theme) => theme.shape.borderRadius,
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#BBC4DA",
                            borderRadius: (theme) => theme.shape.borderRadius,
                            width: 8,
                        },
                    }}
                >
                    {inputFields
                        .filter((field) => field !== nameColumn)
                        .map((field) => {
                            const type = getInputType(field.type);
                            const inputProps = {
                                name: field.id,
                                onBlur: handleInputChange,
                                fullWidth: true,
                                ...(type === "date" && { InputLabelProps: { shrink: true } }),
                            };
                            return (
                                <TextField
                                    key={`${field.id}`}
                                    label={field.name}
                                    select={type === "select"}
                                    type={type !== "select" ? type : undefined}
                                    SelectProps={{ MenuProps: { disablePortal: true } }}
                                    {...inputProps}
                                >
                                    {type === "select" && [
                                        <MenuItem key="" value="">
                                            <FormattedMessage defaultMessage="-" />
                                        </MenuItem>,
                                        ...(field.typeOptions as SelectOptions).choices.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        )),
                                    ]}
                                </TextField>
                            );
                        })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setOpen(false);
                    }}
                    size="medium"
                    color="inherit"
                >
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        validateInput();
                    }}
                    size="medium"
                >
                    <FormattedMessage defaultMessage="Add" description="Add button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
