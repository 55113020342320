import { Theme } from "@mui/material";

import { RiskScore } from "@/gql/graphql";

import { NACEOptions } from "../../MuiDataGridTable/constants";

export function getRiskColor(theme: Theme, value: RiskScore) {
    switch (value) {
        case "HIGH":
            return theme.palette.error.main;
        case "MEDIUM":
            return theme.palette.warning.main;
        default:
            return theme.palette.success.main;
    }
}

export function naceCodeToText(code: string) {
    const option = NACEOptions.find((c) => c.nace === code.slice(0, 5));
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb" || locale === "no" || locale === "nn") {
        return `${option.nace}: ${option.name_no}`;
    }
    return `${option.nace}: ${option.name_en}`;
}
