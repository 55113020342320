import { Autocomplete, Grid, TextField } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ClassificationGroup, ClassificationOptions } from "@/gql/graphql";

import { Filter } from "../types";

import { ListboxComponent, StyledPopper } from "./IncludeExcludeFilter/ListBoxComponent";
import { SelectFilterType } from "./SelectFilterType";

interface Props {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const ClassificationFilter: React.FC<Props> = ({ filter, handleUpdateFilter }) => {
    const { formatMessage } = useIntl();
    const column = filter.column;

    const classificationOptions: ClassificationGroup[] = useMemo(() => {
        const typeOptions = column?.typeOptions as ClassificationOptions;

        const emptyFields: ClassificationGroup = {
            __typename: "ClassificationGroup",
            id: formatMessage({ defaultMessage: "Empty fields" }),
            level: "0",
            value: formatMessage({ defaultMessage: "Empty fields" }),
        };

        return [emptyFields, ...(typeOptions?.groups ?? [])];
    }, [column?.typeOptions, formatMessage]);

    const value = useMemo(() => {
        if (filter.includeExcludeValues?.length) {
            return classificationOptions.filter((o) => filter.includeExcludeValues?.includes(o.id));
        }
        return [];
    }, [classificationOptions, filter.includeExcludeValues]);
    return (
        <>
            <Grid item xs={4}>
                <SelectFilterType filter={filter} handleUpdateFilter={handleUpdateFilter} />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    multiple
                    size="small"
                    disabled={!filter.type}
                    getLimitTagsText={(more) => `${more} selected`}
                    id={`filter-value-select-${filter.id}`}
                    options={classificationOptions}
                    value={value}
                    ListboxComponent={ListboxComponent}
                    PopperComponent={StyledPopper}
                    autoHighlight
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.value}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderTags={() => {
                        return `${filter.includeExcludeValues?.length} selected`;
                    }}
                    renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === "clear") {
                            handleUpdateFilter({
                                ...filter,
                                includeExcludeValues: [],
                            });
                        }
                    }}
                    onChange={(event, newValue, reason) => {
                        if (reason === "selectOption" || reason === "removeOption") {
                            handleUpdateFilter({
                                ...filter,
                                includeExcludeValues: newValue.map((v) => v.id),
                            });
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label={filter.type} />}
                />
            </Grid>
        </>
    );
};
