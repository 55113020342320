import { ApolloProvider as _ApolloProvider } from "@apollo/client";

import { getGraphqlClient } from "./client";

interface Props {
    children?: React.ReactNode;
}
export const ApolloProvider: React.FC<Props> = ({ children }) => {
    const graphqlClient = getGraphqlClient();

    return <_ApolloProvider client={graphqlClient}>{children}</_ApolloProvider>;
};
