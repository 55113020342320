import { Stack, styled } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    ".MuiDataGrid-columnHeader": {
        backgroundColor: theme.palette.grey[200],
    },
}));

export type FilePreviewData = {
    fieldKey: string;
    samples: string[];
}[];

const getSampleRows = (samples: FilePreviewData, numSamples = 5) => {
    const rows: Record<string, string>[] = [];
    if (!samples.length) return rows;
    const maxNumSamples = Math.min(numSamples, samples[0].samples.length);
    for (let i = 0; i < maxNumSamples; i += 1) {
        const row: Record<string, string> = {};
        samples.forEach((sample) => {
            row[sample.fieldKey] = sample.samples[i] ?? "";
        });
        rows.push(row);
    }
    return rows;
};

interface Props {
    filePreview: FilePreviewData | undefined;
}

export const FilePreviewTable: React.FC<Props> = ({ filePreview }) => {
    const data = filePreview ?? [];
    const rows = getSampleRows(data, 50).map((sample, i) => {
        return { id: i, ...sample };
    });
    const columns = data.map((field) => ({
        field: field.fieldKey,
        headerName: field.fieldKey,
        flex: 1,
        minWidth: 100,
        sortable: false,
    }));
    return (
        <Stack height="50vh">
            <StyledDataGrid
                rows={rows}
                columns={columns}
                loading={filePreview === undefined}
                showCellVerticalBorder
                showColumnVerticalBorder
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnReorder
                disableColumnMenu
                hideFooter
            />
        </Stack>
    );
};
