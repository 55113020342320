import { ArrowRight } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import WarningIconCircle from "@/assets/WarningIconCircle.svg?react";

export const EmptyListState: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Stack height="100%" justifyContent="center" alignItems="center" spacing={2}>
            <WarningIconCircle />
            <Typography variant="textMd">
                <FormattedMessage
                    defaultMessage="Add data to see this overview"
                    description="empty list state message"
                />
            </Typography>
            <Stack direction="row">
                <Button
                    color="secondary"
                    onClick={() => navigate("v2/suppliers/overview")}
                    endIcon={<ArrowRight fontSize="small" />}
                >
                    <FormattedMessage
                        defaultMessage="Add data"
                        description="Button to navigate to suppliers overview to add data"
                    />
                </Button>
            </Stack>
        </Stack>
    );
};
