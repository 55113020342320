import { Dialog } from "@mui/material";
import React, { useState } from "react";

import { graphql } from "@/gql";
import { SocialRiskEvaluation_SupplierTableColumnFragment } from "@/gql/graphql";

import AddSupplierEnrichment from "./AddSupplierEnrichment";
import ConfigurationStarted from "./ConfigurationStarted";
import HowWeCalculateSocialRisk from "./HowWeCalculate";

graphql(`
    fragment SocialRiskEvaluation_SupplierTableColumn on SupplierTableColumn {
        id
        ...AddSupplierEnrichment_SupplierTableColumn
    }
`);

interface Props {
    onClose: VoidFunction;
    columns?: SocialRiskEvaluation_SupplierTableColumnFragment[];
    handlePolling: () => void;
}

export const SocialRiskEvaluation: React.FC<Props> = ({ onClose, columns, handlePolling }) => {
    const [step, setStep] = useState<"1" | "2" | "3">("1");

    const renderStep = () => {
        switch (step) {
            case "1":
                return <HowWeCalculateSocialRisk onClose={onClose} setStep={setStep} />;
            case "2":
                return (
                    <AddSupplierEnrichment
                        onClose={onClose}
                        setStep={setStep}
                        columns={columns}
                        handlePolling={handlePolling}
                    />
                );
            case "3":
                return <ConfigurationStarted onClose={onClose} />;
        }
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: "495px",
                },
            }}
        >
            {renderStep()}
        </Dialog>
    );
};
