import { PaperAirplane } from "@ignite-analytics/icons";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { InviteToCampaignModal } from "./InviteToCampaignModal/index";

interface Props {
    supplierIds: string[];
    isEditor: boolean;
}

const SendCampaignAction: React.FC<Props> = ({ supplierIds, isEditor }: Props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Tooltip
                title={<FormattedMessage defaultMessage="Invite to campaign" description="Invite to campaign button" />}
                placement="top"
            >
                <Stack>
                    <IconButton
                        onClick={() => setOpen(true)}
                        disabled={!isEditor || supplierIds.length < 1}
                        color="secondary"
                        size="small"
                    >
                        <PaperAirplane fontSize="small" />
                    </IconButton>
                </Stack>
            </Tooltip>
            <InviteToCampaignModal open={open} close={() => setOpen(false)} supplierIds={supplierIds} />
        </>
    );
};

export default SendCampaignAction;
