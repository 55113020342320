import { useMutation } from "@apollo/client";
import { CircleSolid, X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";

const SetSuppliersToInOnboarding_OnboardExistingSuppliersMutation = graphql(`
    mutation OnboardExistingSuppliers_SetSuppliersToInOnboarding($input: SetSuppliersToOnboardingInput!) {
        setSuppliersToOnboarding(input: $input) {
            ok
        }
    }
`);

interface OnboardExistingSuppliersModalProps {
    open: boolean;
    onClose: () => void;
    selectedSuppliers: GridRowSelectionModel;
}

export const OnboardExistingSuppliersModal: React.FC<OnboardExistingSuppliersModalProps> = ({
    open,
    onClose,
    selectedSuppliers,
}) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const selectedSuppliersIDs = selectedSuppliers.map((id) => id.toString());
    const [setSuppliersToOnboarding, { loading }] = useMutation(
        SetSuppliersToInOnboarding_OnboardExistingSuppliersMutation
    );

    const handleUpdateOnboardingStatus = () => {
        track("Supplier Table: Update onboarding status", {
            totalSuppliersToUpdate: selectedSuppliers.length,
        });
        setSuppliersToOnboarding({
            variables: {
                input: {
                    supplierIDs: selectedSuppliersIDs,
                },
            },
        }).then(() => {
            onClose();
            navigate("/suppliers/onboarding-list");
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="textLg">
                        <FormattedMessage
                            defaultMessage="Onboard existing suppliers"
                            description="Onboard existing suppliers modal"
                        />
                    </Typography>
                    <IconButton onClick={onClose}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack mt={1} spacing={3}>
                    <Typography variant="textSm" color="GrayText">
                        <FormattedMessage
                            defaultMessage="You are about to move the selected suppliers into the onboarding process. This will update their onboarding status to {boldText}."
                            values={{
                                boldText: (
                                    <Typography fontWeight={500} variant="textSm">
                                        {formatMessage({ defaultMessage: "In onboarding" })}
                                    </Typography>
                                ),
                            }}
                            description="Onboard existing suppliers modal description"
                        />
                    </Typography>

                    <Stack direction="row" spacing={1} alignItems="center">
                        <Chip label={`${selectedSuppliers.length}`} size="small" />
                        <Typography variant="textMd" fontWeight={500}>
                            <FormattedMessage defaultMessage="selected suppliers" description="Suppliers selected" />
                        </Typography>
                    </Stack>
                    <Paper>
                        <Stack direction="row" justifyContent="space-between" padding={3} alignItems="center">
                            <Typography fontWeight={500} variant="textMd">
                                <FormattedMessage
                                    defaultMessage="Onboarding status will be updated to"
                                    description="Onboarding status change description"
                                />
                            </Typography>
                            <Chip
                                iconSize="small"
                                icon={<CircleSolid />}
                                variant="status"
                                label="In onboarding"
                                color="warning"
                            />
                        </Stack>
                    </Paper>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" display="flex" justifyContent="end" paddingTop={2} spacing={2}>
                    <Button color="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                    </Button>
                    <Button color="primary" onClick={handleUpdateOnboardingStatus}>
                        {loading ? (
                            <CircularProgress size={12} />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Update status"
                                description="Onboard existing modal primary button"
                            />
                        )}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
