import { useQuery } from "@apollo/client";
import { Collapse, Dialog } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { useAlert } from "@/providers";

import { AddToCampaignTab } from "./AddToCampaignTab";
import { InviteToNewOrExisting } from "./InviteToNewOrExisting";
import { CreateNewCampaignTab } from "./NewCampaignTab";

graphql(`
    fragment InviteToCampaignModal_Campaign on Campaign {
        id
        type
        name
        dueDate
        ...AddToCampaignTab_Campaign
    }
`);

const getCampaignsDocument = graphql(`
    query InviteToCampaignModal_GetCampaigns {
        getCampaigns {
            campaigns {
                ...InviteToCampaignModal_Campaign
            }
        }
    }
`);

type Props = {
    open: boolean;
    close: () => void;
    supplierIds: string[];
};

export const InviteToCampaignModal: React.FC<Props> = ({ open, close, supplierIds }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [step, setStep] = useState(0);
    const [newCampaign, setNewCampaign] = useState<boolean>(true);

    const { data } = useQuery(getCampaignsDocument, {
        skip: !open,
        variables: {},
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to load campaigns",
                    description: "Alert message when campaigns fail to load",
                }),
                severity: "error",
            });
        },
    });

    const externalCampaigns = data?.getCampaigns.campaigns.filter((c) => c.type === "external") ?? [];

    return (
        <Dialog
            open={open}
            onClose={(reason) => {
                if (reason !== "backdropClick") {
                    close();
                }
            }}
            maxWidth="xs"
        >
            <Collapse in={step === 0}>
                <InviteToNewOrExisting
                    close={close}
                    setStep={setStep}
                    newCampaign={newCampaign}
                    setNewCampaign={setNewCampaign}
                    noExistingCampaigns={externalCampaigns.length === 0}
                />
            </Collapse>
            <Collapse in={step === 1}>
                {newCampaign ? (
                    <CreateNewCampaignTab supplierIds={supplierIds} setStep={setStep} />
                ) : (
                    <AddToCampaignTab
                        supplierIds={supplierIds}
                        setStep={setStep}
                        existingCampaigns={externalCampaigns}
                    />
                )}
            </Collapse>
        </Dialog>
    );
};
