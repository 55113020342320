import { List, ListItem, listItemSecondaryActionClasses, ListItemText, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { ChoiceList_SupplierTableColumnFragment, SelectOptions } from "@/gql/graphql";

import { AddChoice } from "./addChoice";
import { DeleteChoiceButton } from "./deleteChoice";

graphql(`
    fragment ChoiceList_SupplierTableColumn on SupplierTableColumn {
        id
        typeOptions {
            ... on SelectOptions {
                choices
            }
        }
    }
`);
interface ChoiceListProps {
    column: ChoiceList_SupplierTableColumnFragment;
}

export const ChoiceList: React.FC<ChoiceListProps> = ({ column }) => {
    const [selectChoices, setSelectChoices] = useState<string[]>(
        column.typeOptions ? (column.typeOptions as SelectOptions).choices : []
    );
    const removeChoice = (choice: string) => {
        setSelectChoices(selectChoices.filter((existing) => choice != existing));
    };

    return (
        <Stack alignItems="flex-start">
            <Typography variant="textXs" mt={2}>
                <FormattedMessage defaultMessage="Options: " description="Options heading" />
            </Typography>
            <List
                sx={{
                    maxHeight: (theme) => theme.spacing(35),
                    overflow: "auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column-reverse",
                }}
            >
                {selectChoices
                    .map((choice) => (
                        <>
                            <ListItem
                                key={choice}
                                sx={{
                                    [`& .${listItemSecondaryActionClasses.root}`]: {
                                        display: "none",
                                    },
                                    [`&:hover .${listItemSecondaryActionClasses.root}`]: {
                                        display: "block",
                                    },
                                }}
                                secondaryAction={
                                    <DeleteChoiceButton
                                        columnId={column.id}
                                        choice={choice}
                                        removeChoice={removeChoice}
                                    />
                                }
                            >
                                <ListItemText>{choice}</ListItemText>
                            </ListItem>
                        </>
                    ))
                    .reverse()}
            </List>

            <AddChoice selectChoices={selectChoices} setSelectChoices={setSelectChoices} columnId={column.id} />
        </Stack>
    );
};
