import React from "react";
import { FormattedNumber } from "react-intl";

interface Props {
    value: { amount: number; currency: string; date: string };
}

export const MonetaryAmount: React.FC<Props> = ({ value }) => {
    return <FormattedNumber value={value.amount} style="currency" currencyDisplay="code" currency={value.currency} />;
};
