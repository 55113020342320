import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";

type NoPermissionTooltipProps = {
    children: React.ReactNode;
    hasPermission: boolean;
};

export const NoPermissionTooltip: React.FC<NoPermissionTooltipProps> = ({ children, hasPermission }) => {
    const { formatMessage } = useIntl();
    return (
        <Tooltip
            title={
                hasPermission
                    ? undefined
                    : formatMessage({
                          defaultMessage: "You must have editor permissions to perform this action.",
                      })
            }
        >
            <span>{children}</span>
        </Tooltip>
    );
};
