import { useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DataGridColumnsDocument } from "@/gql/graphql";
import { useAlert } from "@/providers/Alerts";

const addSelectColumnChoiceDocument = graphql(`
    mutation AddChoice_AddSelectColumnChoice($input: AddSelectColumnChoiceInput!) {
        addSelectColumnChoice(input: $input) {
            choices
        }
    }
`);

interface AddChoiceProps {
    selectChoices: string[];
    setSelectChoices: React.Dispatch<React.SetStateAction<string[]>>;
    columnId: string;
}

export const AddChoice: React.FC<AddChoiceProps> = ({ selectChoices, setSelectChoices, columnId }) => {
    const [addChoiceMutation] = useMutation(addSelectColumnChoiceDocument);
    const [newChoice, setNewChoice] = useState<string>();

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const addChoice = (newChoice: string) => {
        if (newChoice !== "" && !selectChoices.includes(newChoice)) {
            addChoiceMutation({
                refetchQueries: [DataGridColumnsDocument],
                variables: {
                    input: {
                        columnId: columnId,
                        choice: newChoice,
                    },
                },
                onCompleted(data) {
                    if (data?.addSelectColumnChoice) {
                        setSelectChoices(data?.addSelectColumnChoice.choices);
                    }
                    track("Supplier Table: Edit Column: Added Option");
                },
                onError: () => {
                    alertUser({
                        value: formatMessage(
                            {
                                defaultMessage: "Error adding choice: {value}",
                                description: "Alert message when a select column choice could not be added",
                            },
                            { value: newChoice }
                        ),
                        severity: "error",
                    });
                },
            });
        }
    };
    return (
        <TextField
            id="option-input"
            name="option"
            label={<FormattedMessage defaultMessage="Option" description="Option label" />}
            helperText={
                <FormattedMessage defaultMessage="Press enter to add" description="Press enter to add helptext" />
            }
            type="text"
            size="small"
            fullWidth
            value={newChoice}
            onChange={(event) => setNewChoice(event.target.value)}
            onKeyDown={(event) => {
                if (event.key === "Enter" && newChoice) {
                    addChoice(newChoice);
                    setNewChoice("");
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setNewChoice("")}
                            sx={{
                                visibility: newChoice !== undefined && newChoice !== "" ? "visible" : "hidden",
                            }}
                        >
                            <X fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};
