export async function getUserInfo(): Promise<{ email: string; tenant: string }> {
    try {
        const res = await fetch(`${process.env.REACT_APP_ORY_URL}/sessions/whoami`, { credentials: "include" });
        const j = await res.json();
        return { email: j.identity.traits.email, tenant: j.identity.metadata_public.tenant };
    } catch (e) {
        console.error(e);
    }
    return { email: "", tenant: "" };
}
