import {
    Chip as MuiChip,
    Dialog,
    DialogTitle,
    Paper,
    Stack,
    Table,
    TableCell as MuiTableCell,
    TableContainer,
    TableRow,
    styled,
    TableHead,
    TableBody,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

const Chip = styled(MuiChip)(() => ({
    variant: "filled",
    borderRadius: 4,
    size: "small",
    padding: 1,
    fontSize: "small",
    fontWeight: "500",
}));

const TableCell = styled(MuiTableCell)(() => ({
    border: "none",
}));

// eslint-disable-next-line react/jsx-no-literals
const plus = <div style={{ fontSize: "18px", fontWeight: "600", padding: "0 2px" }}>+</div>;

interface KeyboardShortcutsProps {
    open: boolean;
    onClose: VoidFunction;
}

export const KeyboardShortcuts: React.FC<KeyboardShortcutsProps> = ({ onClose, open }) => {
    const { formatMessage } = useIntl();

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <FormattedMessage defaultMessage="Keyboard shortcuts" description="Keyboard shortcuts title" />
            </DialogTitle>
            <Stack padding={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Keys" description="Keys" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Description" description="Description" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({
                                            defaultMessage: "Arrow Left",
                                            description: "Arrow Left",
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate between cell elements"
                                        description="Navigate between cell elements"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({
                                            defaultMessage: "Arrow Bottom",
                                            description: "Arrow Bottom",
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate between cell elements"
                                        description="Navigate between cell elements"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({
                                            defaultMessage: "Arrow Right",
                                            description: "Arrow Right",
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate between cell elements"
                                        description="Navigate between cell elements"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({
                                            defaultMessage: "Arrow Up",
                                            description: "Arrow Up",
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate between cell elements"
                                        description="Navigate between cell elements"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({
                                            defaultMessage: "Home",
                                            description: "Home",
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the first cell of the current row"
                                        description="Navigate to the first cell of the current row"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({
                                            defaultMessage: "End",
                                            description: "End",
                                        })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the last cell of the current row"
                                        description="Navigate to the last cell of the current row"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Ctrl",
                                                description: "Ctrl",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Home",
                                                description: "Home",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the first cell of the first row"
                                        description="Navigate to the first cell of the first row"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Ctrl",
                                                description: "Ctrl",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "End",
                                                description: "End",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the last cell of the last row"
                                        description="Navigate to the last cell of the last row"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip label={formatMessage({ defaultMessage: "Space", description: "Space" })} />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the next scrollable page"
                                        description="Navigate to the next scrollable page"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({ defaultMessage: "Page Up", description: "Page Up" })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the previous scrollable page"
                                        description="Navigate to the previous scrollable page"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Chip
                                        label={formatMessage({ defaultMessage: "Page Down", description: "Page Down" })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Navigate to the next scrollable page"
                                        description="Navigate to the next scrollable page"
                                    />
                                </TableCell>
                            </TableRow>
                            {/* </TableBody>
                    </Table>

                    <Typography variant="displayXs" sx={{ mt: 3 }}>
                        <FormattedMessage defaultMessage="Selection" description="Selection" />
                    </Typography>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Keys" description="Keys" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Description" description="Description" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody> */}
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Shift",
                                                description: "Shift",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Space",
                                                description: "Space",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Select the current row"
                                        description="Select the current row"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Shift",
                                                description: "Shift",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Arrow Up/Down",
                                                description: "Arrow Up/Down",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Select the current row and the row above or below"
                                        description="Select the current row and the row above or below"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Shift",
                                                description: "Shift",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Click on cell",
                                                description: "Click on cell",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Select the range of rows between the first and the last clicked rows"
                                        description="Select the range of rows between the first and the last clicked rows"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Ctrl",
                                                description: "Ctrl",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "A",
                                                description: "A",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Select all rows" description="Select all rows" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Ctrl",
                                                description: "Ctrl",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "C",
                                                description: "C",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage
                                        defaultMessage="Copy the currently selected rows"
                                        description="Copy the currently selected rows"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Stack direction="row">
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Ctrl",
                                                description: "Ctrl",
                                            })}
                                        />
                                        {plus}
                                        <Chip
                                            label={formatMessage({
                                                defaultMessage: "Click",
                                                description: "Click",
                                            })}
                                        />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Select the row" description="Select the row" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Dialog>
    );
};
