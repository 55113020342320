import { autocompleteClasses, Popper, styled, Typography } from "@mui/material";
import { createContext, forwardRef, useCallback, useContext, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import { ClassificationGroup } from "@/gql/graphql";

const LISTBOX_PADDING = 8; // px

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = "OuterElementType";

export const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});

function useResetCache(data: number) {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

interface SizeRef {
    [key: number]: number | null;
}

export const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData: React.ReactChild[] = [];
        (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const rowHeightRefs = useRef<SizeRef>({});

        const itemCount = itemData.length;
        const gridRef = useResetCache(itemCount);

        const setRowHeight = useCallback(
            (index: number, height: number | null) => {
                rowHeightRefs.current = { ...rowHeightRefs.current, [index]: height };
                gridRef?.current?.resetAfterIndex(index);
            },
            [gridRef]
        );

        const getItemSize = (index: number) => {
            const size = rowHeightRefs?.current[index] || 50;
            return size;
        };

        const getCurtainHeight = () => {
            const height = itemData.map((_, index) => getItemSize(index)).reduce((a, b) => a + b, 0);
            if (height > 300) {
                return 300;
            }
            return height + LISTBOX_PADDING * 2;
        };

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getCurtainHeight()}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        itemSize={(index) => getItemSize(index)}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {(props) => (
                            <div style={props.style}>
                                <Row props={props} setRowHeight={setRowHeight} />
                            </div>
                        )}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    }
);

interface RowProps {
    props: ListChildComponentProps;
    setRowHeight: (index: number, height: number | null) => void;
}

const Row: React.FC<RowProps> = ({ props, setRowHeight }) => {
    const { formatMessage } = useIntl();
    const rowRef = useRef<HTMLSpanElement>(null);

    const { data, index, style } = props;
    const dataSet = data[index];

    useEffect(() => {
        setRowHeight(index, rowRef?.current?.getBoundingClientRect()?.height ?? null);
    }, [setRowHeight, index, rowRef]);

    const rowValue = (dataSet: string | ClassificationGroup) => {
        if (typeof dataSet === "string") {
            return dataSet;
        }
        return dataSet.value;
    };

    const emptyFields = formatMessage({ defaultMessage: "Empty fields" });

    return (
        <Typography
            component="li"
            {...dataSet[0]}
            sx={{ top: (style.top as number) + LISTBOX_PADDING }}
            ref={rowRef}
            fontSize="small"
            fontWeight={rowValue(dataSet[1]) === emptyFields ? 600 : 400}
        >
            {rowValue(dataSet[1])}
        </Typography>
    );
};
