import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { GetRiskColumnConfigResponse } from "@/gql/graphql";

import { FilterInput } from "../../InlineFilter/types";

import { HighRiskSpendShare } from "./Components/HighRiskSpendShare";
import { ResponseRateQuestionnaires } from "./Components/ResponseRateQuestionnaires";
import { SuppliersWithSocialRisk } from "./Components/SuppliersWithoutSocialRisk";
import { TotalHighRiskSuppliers } from "./Components/TotalHighRiskSuppliers";

interface KeyInsightsProps {
    totalHighRiskSuppliers?: number;
    totalSuppliers?: number;
    totalSuppliersLoading: boolean;
    totalHighRiskSuppliersLoading: boolean;
    riskColumnConfig?: Omit<GetRiskColumnConfigResponse, "id">;
    spendColumnIds: string[];
    lastSpendYearColumnId: string;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}

export const KeyInsights: React.FC<KeyInsightsProps> = ({
    totalHighRiskSuppliers,
    totalSuppliers,
    riskColumnConfig,
    spendColumnIds,
    lastSpendYearColumnId,
    onNavigate,
    totalSuppliersLoading,
    totalHighRiskSuppliersLoading,
}) => {
    return (
        <Stack spacing={2}>
            <Typography variant="textLg" component="h2" fontWeight="medium">
                <FormattedMessage defaultMessage="Key insights" description="Key insights sub header" />
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={2}>
                <Grid xs={12} md={6}>
                    <TotalHighRiskSuppliers
                        totalHighRiskSuppliers={totalHighRiskSuppliers}
                        totalSuppliers={totalSuppliers}
                        riskColumnConfig={riskColumnConfig}
                        onNavigate={onNavigate}
                        totalSuppliersLoading={totalSuppliersLoading}
                        totalHighRiskSuppliersLoading={totalHighRiskSuppliersLoading}
                    />
                </Grid>
                <Grid xs={12} md={6} sx={{}}>
                    <HighRiskSpendShare
                        riskColumnConfig={riskColumnConfig}
                        spendColumnIds={spendColumnIds}
                        onNavigate={onNavigate}
                        lastSpendYearColumnId={lastSpendYearColumnId}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <SuppliersWithSocialRisk
                        totalNumberOfSuppliers={totalSuppliers}
                        riskColumnConfig={riskColumnConfig}
                        onNavigate={onNavigate}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <ResponseRateQuestionnaires />
                </Grid>
            </Grid>
        </Stack>
    );
};
