import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { useMemo } from "react";

import { Filter, dateFieldFilters, numberFieldFilters, textFieldFilters } from "../types";

interface SelectFilterTypeProps {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const SelectFilterType: React.FC<SelectFilterTypeProps> = ({ filter, handleUpdateFilter }) => {
    const fromCamelCaseToCapitalized = (str: string) => {
        return str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
    };

    const options = useMemo(() => {
        const column = filter.column;

        if (!column) {
            return [];
        } else if (column.type === "NUMBER") {
            return numberFieldFilters.map((type) => {
                return {
                    type,
                    label: fromCamelCaseToCapitalized(type),
                };
            });
        } else if (column.type === "DATE") {
            return dateFieldFilters.map((type) => {
                return {
                    type,
                    label: fromCamelCaseToCapitalized(type),
                };
            });
        } else if (
            column.type === "ASSESSMENT_STATUS" ||
            column.type === "CLASSIFICATION" ||
            column.type === "SELECT" ||
            column.type === "RISK" ||
            column.type === "NACE" ||
            column.type === "TEXT"
        ) {
            return textFieldFilters.map((type) => ({
                type,
                label: fromCamelCaseToCapitalized(type),
            }));
        }

        return [];
    }, [filter]);

    return (
        <Autocomplete
            id={`filter-type-select-${filter.id}`}
            size="small"
            options={options}
            autoHighlight
            disabled={!filter.column?.id}
            getOptionLabel={(option) => option.label}
            value={options.find((option) => option.type === filter.type) || null}
            renderOption={(props, option) => (
                <MenuItem {...props} key={"column-select-" + filter.id + "-" + option.type} value={option.type}>
                    {option.label}
                </MenuItem>
            )}
            onInputChange={(event, newInputValue, reason) => {
                if (reason === "clear") {
                    handleUpdateFilter({
                        ...filter,
                        type: null,
                        includeExcludeValues: [],
                    });
                    return;
                }
            }}
            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} label="Filter" />}
            onChange={(event, newValue, reason) => {
                if (reason === "selectOption" || reason === "removeOption") {
                    if (newValue === null) {
                        handleUpdateFilter({
                            ...filter,
                            type: null,
                            includeExcludeValues: [],
                        });
                        return;
                    }
                    handleUpdateFilter({
                        ...filter,
                        type: newValue.type,
                        includeExcludeValues: [],
                    });
                }
            }}
        />
    );
};
