import { useApolloClient, useMutation } from "@apollo/client";
import {
    Button,
    CircularProgress,
    Dialog,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { useAlert } from "@/providers";

const deleteSuppliersDocument = graphql(`
    mutation DeleteSuppliers($input: DeleteSuppliersInput!) {
        deleteSuppliers(input: $input) {
            ids
        }
    }
`);

interface ConfirmDeleteSuppliersProps {
    onClose: () => void;
    selectedSuppliers: GridRowSelectionModel;
    onDelete: () => void;
}

export const ConfirmDeleteSuppliers: React.FC<ConfirmDeleteSuppliersProps> = ({
    onClose,
    onDelete,
    selectedSuppliers,
}) => {
    const { formatMessage } = useIntl();

    const [deleteSuppliers, { loading }] = useMutation(deleteSuppliersDocument, {});

    const { alertUser } = useAlert();

    const confirmTitle = formatMessage({
        defaultMessage: "Are you sure you want to delete the following suppliers?",
        description: "Confirm delete suppliers title",
    });

    const handleDelete = () => {
        if (!selectedSuppliers.length) return;

        deleteSuppliers({
            variables: {
                input: {
                    ids: selectedSuppliers.map((id) => (typeof id === "string" ? id : id.toString())),
                },
            },
            onCompleted: (data) => {
                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "{numberOfDeletedSuppliers} suppliers have been deleted.",
                            description: "Alert message when a column is added successfully",
                        },
                        {
                            numberOfDeletedSuppliers: data.deleteSuppliers.ids.length,
                        }
                    ),
                });
                onDelete();
                onClose();
            },
            onError: () => {
                onClose();
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Failed to delete suppliers",
                        description: "Alert message when failing to delete suppliers",
                    }),
                });
            },
            optimisticResponse(vars) {
                return {
                    __typename: "Mutation",
                    deleteSuppliers: {
                        ids: vars.input.ids,
                        __typename: "DeleteSuppliersResponse",
                    },
                };
            },
            update(cache, { data }) {
                data?.deleteSuppliers.ids.forEach((id) => {
                    cache.evict({
                        id: cache.identify({
                            __typename: "Supplier",
                            id,
                        }),
                    });
                });
            },
        });
    };

    const suppliersToDelete = useSuppliersFromRowSelectionState(selectedSuppliers);

    return (
        <Dialog open={true} onClose={onClose}>
            <Stack padding={4} spacing={2} maxHeight={800}>
                <Stack>
                    <Typography variant="textMd"> {confirmTitle}</Typography>
                </Stack>
                <Stack maxHeight={300} sx={{ overflowY: "scroll" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableCell>
                                <Typography variant="textSm" noWrap>
                                    <FormattedMessage defaultMessage="Supplier name" description="Supplier Name" />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="textSm" noWrap>
                                    <FormattedMessage
                                        defaultMessage="Registration number"
                                        description="Registration number"
                                    />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="textSm" noWrap>
                                    <FormattedMessage defaultMessage="Country" description="Country" />
                                </Typography>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {suppliersToDelete.map(({ id, name, orgNumber, country }) => (
                                <TableRow key={id}>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{orgNumber}</TableCell>
                                    <TableCell>{country}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>
                <Stack direction="row" display="flex" justifyContent="end" paddingTop={2} spacing={2}>
                    <Button color="secondary" variant="text" onClick={onClose}>
                        <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                    </Button>
                    <Button color="error" onClick={handleDelete} disabled={loading}>
                        {loading ? (
                            <CircularProgress size={18} />
                        ) : (
                            <FormattedMessage defaultMessage="Delete" description="Delete button" />
                        )}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
};

function useSuppliersFromRowSelectionState(selectedSuppliers: GridRowSelectionModel) {
    const client = useApolloClient();

    const suppliersToDelete = selectedSuppliers.map((id) => {
        return client.readFragment({
            id: client.cache.identify({
                __typename: "Supplier",
                id,
            }),
            fragment: graphql(`
                fragment ConfirmDeleteSuppliers_supplierFields on Supplier {
                    id
                    name
                    orgNumber
                    country
                }
            `),
        });
    });
    return suppliersToDelete.filter(isNotNull);
}

function isNotNull<T>(value: T): value is NonNullable<typeof value> {
    return value !== null;
}
