import { useQuery } from "@apollo/client";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { SelectQuestionnaire_QuestionnaireFragment } from "@/gql/graphql";

graphql(`
    fragment SelectQuestionnaire_Questionnaire on Questionnaire {
        id
        name
        campaigns {
            id
            name
        }
    }
`);

const SelectQuestionnaire_GetQuestionnairesQuery = graphql(`
    query SelectQuestionnaire_GetQuestionnairesQuery {
        getQuestionnaires {
            questionnaires {
                ...SelectQuestionnaire_Questionnaire
            }
        }
    }
`);

interface SelectQuestionnaireProps {
    value: string;
    onQuestionnaireChange: (value: string) => void;
}

export const SelectQuestionnaire: React.FC<SelectQuestionnaireProps> = ({ value, onQuestionnaireChange }) => {
    const { formatMessage } = useIntl();

    const { data: QuestionnaireData, loading: isQuestionnaireLoading } = useQuery(
        SelectQuestionnaire_GetQuestionnairesQuery
    );

    const questionnaires = QuestionnaireData?.getQuestionnaires?.questionnaires;

    const options: { id: string; name: string }[] =
        questionnaires
            ?.map((item: SelectQuestionnaire_QuestionnaireFragment) => {
                return {
                    id: item.id,
                    name: item.name,
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    const selectedOption = options.find((o) => o.id === value);
    return (
        <FormControl>
            <Autocomplete
                id="questionnaireId"
                loading={isQuestionnaireLoading}
                sx={{ pt: 2 }}
                options={options}
                renderInput={(params) => (
                    <TextField {...params} label={formatMessage({ defaultMessage: "Select questionnaire" })} />
                )}
                getOptionLabel={(option) => option.name}
                value={selectedOption}
                onChange={(_, newValue) => {
                    if (!newValue) {
                        return;
                    }
                    onQuestionnaireChange(newValue.id);
                }}
                size="small"
                fullWidth
            />
        </FormControl>
    );
};
