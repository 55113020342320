import { Autocomplete, Checkbox, Stack, TableCell, TableRow, TextField, Theme, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { useSupplierUploadContext } from "../../providers/SupplierUpload";

import { MappingStatus } from "./MappingStatus";

interface BaseMappingRowProps {
    columnName: string;
    explicitMapping: string | undefined;
    implicitMapping: string | undefined;
    required?: boolean;
    newField?: boolean;
    onChange?: (value: string | null) => void;
}

type MappingVariantProps =
    | { newField: true; onChange?: undefined }
    | {
          newField?: false;
          onChange: (value: string | null) => void;
      };

export const MappingRow: React.FC<BaseMappingRowProps & MappingVariantProps> = ({
    columnName,
    explicitMapping,
    implicitMapping,
    required,
    newField,
    onChange,
}) => {
    const { formatMessage } = useIntl();
    const [
        { mapping, includedUnmappedColumns, filePreview, fileColumns, dataColumnIdToFieldNameMapping, fileInformation },
        dispatch,
    ] = useSupplierUploadContext();

    const mappedField = explicitMapping ?? implicitMapping ?? null;
    const isMappedElsewhere =
        mappedField !== null &&
        (Object.values(mapping).includes(mappedField) ||
            Object.values(dataColumnIdToFieldNameMapping).includes(mappedField));
    const isImplicitlyMappedHere = !explicitMapping && implicitMapping !== undefined;

    // Don't allow clearing an implicit mapping
    const disableClearable = isImplicitlyMappedHere || explicitMapping === implicitMapping;
    // Don't show an implicit mapping when the field is explicity mapped elsewhere
    const value = isMappedElsewhere && isImplicitlyMappedHere ? null : mappedField;

    const missingRequiredField = required && !value;

    const isIncluded = !newField || includedUnmappedColumns.has(columnName);

    // Grey out the text if the file column is not included
    const textColor = isIncluded ? undefined : (theme: Theme) => theme.palette.grey[500];

    return (
        <TableRow>
            <TableCell>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Typography variant="textMd" color={textColor}>
                        {columnName}
                    </Typography>
                    {newField && (
                        <Checkbox
                            checked={isIncluded}
                            onChange={(_, checked) => {
                                dispatch({
                                    type: checked ? "INCLUDE_UNMAPPED_COLUMN" : "EXCLUDE_UNMAPPED_COLUMN",
                                    columnName,
                                    fileId: fileInformation?.id ?? "no-file",
                                });
                            }}
                        />
                    )}
                </Stack>
            </TableCell>
            <TableCell>
                <Autocomplete
                    fullWidth
                    disableClearable={disableClearable}
                    options={fileColumns}
                    value={value}
                    onChange={(_, value) => onChange?.(value)}
                    disabled={newField}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={required}
                            label={formatMessage({
                                defaultMessage: "Select a column",
                                description: "Select a column",
                            })}
                            error={missingRequiredField}
                            helperText={
                                missingRequiredField && <FormattedMessage defaultMessage="This field is required" />
                            }
                        />
                    )}
                />
            </TableCell>
            <TableCell align="center">
                <MappingStatus
                    value={value}
                    newField={newField}
                    ignored={newField && value !== null && !includedUnmappedColumns.has(value)}
                />
            </TableCell>
            <TableCell>
                <Typography variant="textSm" color={textColor}>
                    {useMemo(
                        () =>
                            value
                                ? filePreview
                                      ?.find(({ fieldKey }) => fieldKey === value)
                                      ?.samples.slice(0, 5)
                                      .join(" | ")
                                : null,
                        [value, filePreview]
                    )}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
