import { Paper, Stack, styled } from "@mui/material";

const Box = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    height: "100%",
}));

interface BoxItemProps {
    children?: React.ReactNode;
}

export const BoxItem: React.FC<BoxItemProps> = ({ children }) => {
    return (
        <Box>
            <Stack padding={3} height="100%">
                {children}
            </Stack>
        </Box>
    );
};
