import { Reducer, useReducer } from "react";

import { FilePreviewData } from "./components/FilePreview";
import { FileInformation } from "./hooks/useFileManager";
import { BaseSupplierColumnMapping } from "./interfaces";

export type ReducerState = {
    file: File | undefined;
    fileInformation: FileInformation | undefined;
    uploadProgress: number;
    uploadComplete: boolean;
    filePreview: FilePreviewData | undefined;
    userMapping: Partial<BaseSupplierColumnMapping>;
    dataColumnIdToFieldNameMapping: Record<string, string>; // Additional fields
    includedUnmappedColumns: Set<string>;
    warnings: string | undefined;
    errors: string | undefined;
    duplicatesInFile:
        | {
              duplicateValues: string[];
              uniqueColumnIndicies: number[];
          }
        | undefined;
    blankRowsInfo:
        | {
              indices: number[];
              columnIndex: number;
          }
        | undefined;
};

const defaultState: ReducerState = {
    file: undefined,
    fileInformation: undefined,
    uploadProgress: 0,
    uploadComplete: false,
    filePreview: undefined,
    userMapping: {},
    dataColumnIdToFieldNameMapping: {},
    includedUnmappedColumns: new Set(),
    warnings: undefined,
    errors: undefined,
    duplicatesInFile: undefined,
    blankRowsInfo: undefined,
};

export type Action =
    | { type: "SET_FILE"; file: File | undefined }
    | { type: "SET_UPLOAD_PROGRESS"; uploadProgress: number; fileId: string | undefined }
    | { type: "SET_FILE_INFORMATION"; fileInformation: FileInformation; file: File }
    | { type: "UPLOAD_COMPLETE"; fileId: string }
    | { type: "FILE_PARSE_COMPLETE"; filePreview: FilePreviewData; warnings: string | undefined; fileId: string }
    | { type: "FILE_PARSE_ERROR"; errors: string | undefined; warnings: string | undefined; fileId: string }
    | { type: "FILE_UPLOAD_ERROR"; errors: string; fileId: string | undefined }
    | { type: "SET_USER_MAPPING"; mapping: Partial<BaseSupplierColumnMapping>; fileId: string }
    | { type: "SET_DATA_COLUMN_ID_TO_FIELD_NAME_MAPPING"; mapping: Record<string, string>; fileId: string }
    | { type: "INCLUDE_UNMAPPED_COLUMN"; columnName: string; fileId: string }
    | { type: "EXCLUDE_UNMAPPED_COLUMN"; columnName: string | null; fileId: string }
    | { type: "FILE_DUPLICATES_RESULT"; duplicateValues: string[]; uniqueColumnIndicies: number[]; fileId: string }
    | { type: "FILE_DUPLICATES_ERROR"; error: string; uniqueColumnIndicies: number[]; fileId: string }
    | { type: "FILE_BLANKS_RESULT"; blankRowIndices: number[]; columnIndex: number; fileId: string }
    | { type: "FILE_BLANKS_ERROR"; error: string; columnIndex: number; fileId: string };

const reducer: Reducer<ReducerState, Action> = (prevState, action) => {
    if (action.type === "SET_FILE") return { ...defaultState, file: action.file };
    if (prevState.file === undefined) return prevState;

    if (action.type === "SET_FILE_INFORMATION") {
        if (prevState.file !== action.file) return prevState; // If we have already changed the file, ignore the fileInformation
        return { ...prevState, fileInformation: action.fileInformation };
    }

    // If we in one render cycle first change the file and then do some other, unrelated, action -> ignore the other action
    if (action.fileId !== prevState.fileInformation?.id) return prevState;

    switch (action.type) {
        case "SET_UPLOAD_PROGRESS":
            // NOTE: Use fileId: undefined to set progess before we have received the fileInformation
            return { ...prevState, uploadProgress: action.uploadProgress };
        case "UPLOAD_COMPLETE":
            return { ...prevState, uploadComplete: true, uploadProgress: 0 };
        case "FILE_UPLOAD_ERROR":
            return {
                ...prevState,
                uploadComplete: false,
                errors: action.errors,
                warnings: undefined,
                filePreview: undefined,
                uploadProgress: 0,
            };
        case "FILE_PARSE_COMPLETE":
            return {
                ...prevState,
                filePreview: action.filePreview,
                warnings: action.warnings,
                errors: undefined,
                uploadProgress: 0,
            };
        case "FILE_PARSE_ERROR":
            return {
                ...prevState,
                errors: prevState.errors === undefined ? action.errors : prevState.errors,
                warnings: action.warnings,
                filePreview: undefined,
                uploadProgress: 0,
            };
        case "SET_USER_MAPPING":
            return { ...prevState, userMapping: action.mapping };
        case "SET_DATA_COLUMN_ID_TO_FIELD_NAME_MAPPING":
            return { ...prevState, dataColumnIdToFieldNameMapping: action.mapping };
        case "INCLUDE_UNMAPPED_COLUMN": {
            const includedUnmappedColumns = new Set(prevState.includedUnmappedColumns);
            includedUnmappedColumns.add(action.columnName);

            return { ...prevState, includedUnmappedColumns };
        }
        case "EXCLUDE_UNMAPPED_COLUMN": {
            if (action.columnName === null) return prevState;

            const includedUnmappedColumns = new Set(prevState.includedUnmappedColumns);
            includedUnmappedColumns.delete(action.columnName);

            return { ...prevState, includedUnmappedColumns };
        }
        case "FILE_DUPLICATES_RESULT":
            return {
                ...prevState,
                duplicatesInFile: {
                    duplicateValues: action.duplicateValues,
                    uniqueColumnIndicies: action.uniqueColumnIndicies,
                },
            };
        case "FILE_DUPLICATES_ERROR":
            return {
                ...prevState,
                errors: prevState.errors === undefined ? action.error : prevState.errors,
                duplicatesInFile: undefined,
            };
        case "FILE_BLANKS_RESULT":
            return {
                ...prevState,
                blankRowsInfo: {
                    indices: action.blankRowIndices,
                    columnIndex: action.columnIndex,
                },
            };
        case "FILE_BLANKS_ERROR":
            return {
                ...prevState,
                errors: prevState.errors === undefined ? action.error : prevState.errors,
                blankRowsInfo: undefined,
            };
    }
};

export const useSupplierUploadReducer = () => useReducer(reducer, defaultState);
