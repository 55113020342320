import { CheckCircle, DotsHorizontal, InformationCircle } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Box,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { SelectViews_SupplierTableConfigFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { DeleteViewModal } from "./DeleteViewModal";
import { RenameViewModal } from "./RenameViewModal";

type SelectViewsProps = {
    readonly selectedViewId: string;
    readonly onViewSelect: (view: SelectViews_SupplierTableConfigFragment) => void;
    readonly views: SelectViews_SupplierTableConfigFragment[];
    readonly isEditor: boolean;
    readonly toggleOpen: () => void;
    readonly onViewDelete: (id: string) => void;
    readonly onViewRename: (id: string, displayName: string, state: string) => void;
};

graphql(`
    fragment SelectViews_SupplierTableConfig on SupplierTableConfig {
        id
        displayName
        state
        createdAt
        ...DeleteViewModal_SupplierTableConfig
        ...RenameViewModal_SupplierTableConfig
    }
`);

export const SelectViews = ({
    selectedViewId,
    onViewSelect,
    views,
    isEditor,
    toggleOpen,
    onViewDelete,
    onViewRename,
}: SelectViewsProps) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const [menuState, setMenuState] = useState<{
        renamingOpen: boolean;
        deletingOpen: boolean;
        anchorEl: null | HTMLElement;
        selectedView: SelectViews_SupplierTableConfigFragment | null;
    }>({
        renamingOpen: false,
        deletingOpen: false,
        anchorEl: null,
        selectedView: null,
    });

    return (
        <Stack maxHeight={500}>
            <Stack direction="row" alignItems="center" sx={{ paddingLeft: 2, height: 40 }}>
                <Typography variant="textSm" component="div">
                    <FormattedMessage defaultMessage="Company views" description="Views title" />
                </Typography>
                <Box sx={{ marginLeft: 1, marginRight: 2 }}>
                    <CustomWidthTooltip placement="top" title={viewExplanation}>
                        <InformationCircle fontSize="inherit" />
                    </CustomWidthTooltip>
                </Box>
            </Stack>
            {views
                .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                .map((view) => {
                    const selectedItem = selectedViewId === view.id;
                    return (
                        <MenuItem
                            key={view.id}
                            selected={selectedItem}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: (theme) => theme.palette.grey[200],
                                },
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                try {
                                    onViewSelect(view);
                                    track("Supplier Table: Selected view", { config: view });
                                    toggleOpen();
                                } catch (e) {
                                    Sentry.captureException(e, { tags: { app: "suppliers-app" } });
                                    alertUser({
                                        value: formatMessage({
                                            defaultMessage: "An error occurred while parsing the saved configuration.",
                                            description: "Alert message when loading a saved table config fails.",
                                        }),
                                        severity: "error",
                                    });
                                }
                            }}
                        >
                            <Stack spacing={4} direction="row" justifyContent="space-between">
                                <Stack direction="row" alignItems="center">
                                    <Typography
                                        variant="textSm"
                                        width={120}
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            flexGrow: 1,
                                        }}
                                    >
                                        {view.displayName}
                                    </Typography>
                                    <CheckCircle
                                        sx={{ visibility: selectedItem ? "visible" : "hidden" }}
                                        fontSize="inherit"
                                    />
                                </Stack>
                                {view.id !== "default" && isEditor ? (
                                    <IconButton
                                        onClick={(event) => {
                                            setMenuState((prevState) => ({
                                                ...prevState,
                                                anchorEl: event.currentTarget,
                                                selectedView: view,
                                            }));
                                            event.stopPropagation();
                                        }}
                                        size="2xsmall"
                                    >
                                        <DotsHorizontal />
                                    </IconButton>
                                ) : null}
                            </Stack>
                        </MenuItem>
                    );
                })}
            <Menu
                id="simple-menu"
                anchorEl={menuState.anchorEl}
                keepMounted
                open={Boolean(menuState.anchorEl)}
                onClose={() => setMenuState((prevState) => ({ ...prevState, anchorEl: null }))}
            >
                <MenuItem onClick={() => setMenuState((prevState) => ({ ...prevState, renamingOpen: true }))}>
                    <FormattedMessage defaultMessage="Rename" description="Rename view menu item" />
                </MenuItem>
                <MenuItem onClick={() => setMenuState((prevState) => ({ ...prevState, deletingOpen: true }))}>
                    <FormattedMessage defaultMessage="Delete" description="Delete view menu item" />
                </MenuItem>
            </Menu>

            {menuState.renamingOpen && menuState.selectedView && (
                <RenameViewModal
                    onClose={(newName?: string) => {
                        setMenuState({
                            renamingOpen: false,
                            deletingOpen: false,
                            anchorEl: null,
                            selectedView: null,
                        });
                        newName &&
                            menuState.selectedView &&
                            onViewRename(menuState.selectedView.id, newName, menuState.selectedView.state);
                    }}
                    view={menuState.selectedView}
                />
            )}
            {menuState.deletingOpen && menuState.selectedView && (
                <DeleteViewModal
                    onClose={(deleted: boolean, id: string) => {
                        setMenuState({
                            renamingOpen: false,
                            deletingOpen: false,
                            anchorEl: null,
                            selectedView: null,
                        });
                        if (deleted) onViewDelete(id);
                    }}
                    view={menuState.selectedView}
                />
            )}
        </Stack>
    );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

const viewExplanation: React.ReactElement = (
    <React.Fragment>
        <Typography variant="textSm" component="div" gutterBottom>
            <strong>
                <FormattedMessage
                    defaultMessage="Supplier table views"
                    description="Supplier table views tooltip title"
                />
            </strong>
            <br />
            <FormattedMessage
                defaultMessage="A view is a table configuration consisting of filters, sorting, and column visibility, ordering and width. This decides how the table looks and behaves. "
                description="Views explanation"
            />
            <Link
                href="https://ignite-changelog.webflow.io/posts/introducing-supplier-table-views"
                target="_blank"
                rel="noreferrer"
                color="primary"
                marginLeft={1}
            >
                <FormattedMessage defaultMessage="Learn more" description="Views learn more" />
            </Link>
        </Typography>
    </React.Fragment>
);
