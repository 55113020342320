import { useLazyQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { MagicWand } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";

import {
    ALL_BASE_SUPPLIER_COLUMNS,
    BASE_SUPPLIER_COLUMN_GLOBAL_TYPES,
    SUPPLIER_COLUMN_MESSAGES,
} from "../../constants";
import { BaseSupplierColumnMapping } from "../../interfaces";
import { useSupplierUploadContext } from "../../providers/SupplierUpload";
import { DataValidationErrors } from "../DataValidationErrors";

import { MappingRow } from "./MappingRow";

const GetSupplierFieldsMappingSuggestionDocument = graphql(`
    query ColumnMappingModal_GetSupplierFieldsMappingSuggestions($input: GetSupplierFieldsMappingSuggestionInput!) {
        getSupplierFieldsMappingSuggestion(input: $input) {
            suggestions {
                columnId
                fileColumn
            }
        }
    }
`);

interface Props {
    open: boolean;
    canSubmit: boolean;
    onClose: () => void;
    onMapComplete: (mapping: Partial<BaseSupplierColumnMapping>) => void;
}

export const ColumnMappingModal: React.FC<Props> = ({ open, canSubmit, onClose, onMapComplete }) => {
    const [
        {
            userMapping,
            mapping,
            fileInformation,
            supplierFieldsByColumn,
            fileColumns,
            unmappedFileColumns,
            baseColumnDataColumnMapping,
            dataColumnIdToFieldNameMapping,
            isFirstTimeUpload,
        },
        dispatch,
    ] = useSupplierUploadContext();

    const [automapError, setAutomapError] = useState(false);

    const supplierAIMappingEnabled = useFeatureToggle("supplier-upload-ai-mapping", false);

    const [getSupplierFieldsMappingSuggestion, mappingSuggestionResult] = useLazyQuery(
        GetSupplierFieldsMappingSuggestionDocument
    );

    const doAIMapping = () => {
        track("Supplier Table: Upload Suppliers - AI mapping button");
        getSupplierFieldsMappingSuggestion({
            variables: {
                input: {
                    supplierColumns: supplierFieldsByColumn.map(({ dataColumnId, dataColumnName }) => ({
                        id: dataColumnId,
                        name: dataColumnName,
                    })),
                    fileColumns,
                },
            },
        })
            .then(({ data }) => {
                if (!data) {
                    setAutomapError(true);
                    return;
                }
                let baseMapping: Partial<BaseSupplierColumnMapping> = {};

                // Strip global typed suggestions from extra fields and put them into baseMapping
                const extraMapping = data.getSupplierFieldsMappingSuggestion.suggestions.reduce(
                    (acc, { columnId, fileColumn }) => {
                        const dataColumn = supplierFieldsByColumn.find(({ dataColumnId }) => dataColumnId === columnId);
                        switch (dataColumn?.globalType) {
                            case BASE_SUPPLIER_COLUMN_GLOBAL_TYPES.name:
                                baseMapping = { ...baseMapping, name: fileColumn };
                                return acc;
                            case BASE_SUPPLIER_COLUMN_GLOBAL_TYPES.country:
                                baseMapping = { ...baseMapping, country: fileColumn };
                                return acc;
                            case BASE_SUPPLIER_COLUMN_GLOBAL_TYPES.regNumber:
                                baseMapping = { ...baseMapping, regNumber: fileColumn };
                                return acc;
                            default:
                                return { ...acc, [columnId]: fileColumn };
                        }
                    },
                    {} as Record<string, string>
                );
                dispatch({
                    type: "SET_USER_MAPPING",
                    mapping: baseMapping,
                    fileId: fileInformation?.id ?? "no-file",
                });

                dispatch({
                    type: "SET_DATA_COLUMN_ID_TO_FIELD_NAME_MAPPING",
                    mapping: extraMapping,
                    fileId: fileInformation?.id ?? "no-file",
                });
            })
            .catch(() => {
                setAutomapError(true);
            });
    };

    const { formatMessage } = useIntl();

    const autoMappingFailed = formatMessage({
        defaultMessage: "Automatic column mapping failed",
        description: "Automatic column mapping failed",
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FormattedMessage
                        defaultMessage="Map columns from your file to Ignite's columns"
                        description="Map columns from your file to Ignite's columns"
                    />
                    {supplierAIMappingEnabled && (
                        <Tooltip
                            title={formatMessage({
                                defaultMessage: "Automatically map columns using AI. (Experimental)",
                                description: "Automatically map columns using AI. (Experimental)",
                            })}
                        >
                            <IconButton onClick={doAIMapping} disabled={mappingSuggestionResult.loading}>
                                {mappingSuggestionResult.loading ? <CircularProgress /> : <MagicWand />}
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Snackbar
                    open={automapError}
                    autoHideDuration={5000}
                    onClose={() => setAutomapError(false)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    <Alert severity="error" title={autoMappingFailed}>
                        <AlertTitle>{autoMappingFailed}</AlertTitle>
                        {formatMessage({
                            defaultMessage: "Please map the columns manually.",
                            description: "Please map the columns manually.",
                        })}
                    </Alert>
                </Snackbar>
                <Stack gap={2}>
                    <Typography>
                        <FormattedMessage
                            defaultMessage="Please help us identify which column in your file represents the following"
                            description="Please help us identify which column in your file represents the following"
                        />
                    </Typography>
                    <DataValidationErrors />
                    {!isFirstTimeUpload && (
                        <Alert severity="info">
                            <FormattedMessage
                                defaultMessage="Suppliers that already exist in Ignite will be updated with new data."
                                description="Suppliers that already exist in Ignite will be updated with new data."
                            />
                        </Alert>
                    )}
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Ignite column" description="Ignite column" />
                                </TableCell>
                                <TableCell sx={{ minWidth: 250 }}>
                                    <FormattedMessage defaultMessage="File column" description="File column" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Mapping status" description="Mapping status" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage defaultMessage="Data examples" description="Data examples" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ALL_BASE_SUPPLIER_COLUMNS.map((baseColumn) => {
                                const explicitMapping = mapping[baseColumn];
                                const dataColumn = supplierFieldsByColumn.find(
                                    (field) =>
                                        field.globalType &&
                                        field.globalType === BASE_SUPPLIER_COLUMN_GLOBAL_TYPES[baseColumn]
                                );
                                const implicitMapping = fileColumns.find(
                                    (fileColumn) => fileColumn === dataColumn?.fieldName
                                );
                                return (
                                    <MappingRow
                                        key={`basecolumn-${baseColumn}`}
                                        required={baseColumn === "name"}
                                        columnName={formatMessage(SUPPLIER_COLUMN_MESSAGES[baseColumn])}
                                        explicitMapping={explicitMapping}
                                        implicitMapping={implicitMapping}
                                        onChange={(fileColumn) => {
                                            const baseFieldMappingEntry = Object.entries(mapping).find(
                                                ([, fieldName]) => fieldName === fileColumn
                                            );

                                            dispatch({
                                                type: "SET_USER_MAPPING",
                                                mapping: {
                                                    ...userMapping,
                                                    // Unmap previous mapping
                                                    ...(baseFieldMappingEntry
                                                        ? { [baseFieldMappingEntry[0]]: undefined }
                                                        : {}),
                                                    [baseColumn]: fileColumn ?? undefined,
                                                },
                                                fileId: fileInformation?.id ?? "no-file",
                                            });

                                            // Don't create a new column for this file column
                                            dispatch({
                                                type: "EXCLUDE_UNMAPPED_COLUMN",
                                                columnName: fileColumn,
                                                fileId: fileInformation?.id ?? "no-file",
                                            });

                                            const dataColumnMappingEntry = Object.entries(
                                                dataColumnIdToFieldNameMapping
                                            ).find(([, fieldName]) => fieldName === fileColumn);
                                            if (!dataColumnMappingEntry) return;

                                            // Unmap this file field from any other data columns
                                            const {
                                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                [dataColumnMappingEntry[0]]: _removedMapping,
                                                ...newDataColumnIdToFieldNameMapping
                                            } = dataColumnIdToFieldNameMapping;

                                            dispatch({
                                                type: "SET_DATA_COLUMN_ID_TO_FIELD_NAME_MAPPING",
                                                mapping: newDataColumnIdToFieldNameMapping,
                                                fileId: fileInformation?.id ?? "no-file",
                                            });
                                        }}
                                    />
                                );
                            })}
                            {supplierFieldsByColumn
                                .filter((field) => !Object.values(baseColumnDataColumnMapping).includes(field))
                                .map(({ dataColumnId, dataColumnName, fieldName }) => {
                                    const explicitMapping = dataColumnIdToFieldNameMapping[dataColumnId];
                                    const implicitMapping = fileColumns.find((fileColumn) => fileColumn === fieldName);

                                    return (
                                        <MappingRow
                                            key={`supplierfield-${fieldName}`}
                                            columnName={dataColumnName}
                                            explicitMapping={explicitMapping}
                                            implicitMapping={implicitMapping}
                                            onChange={(fileColumn) => {
                                                // Remove all mappings from this field
                                                const mappingWithDataColumnRemoved: Record<string, string> =
                                                    Object.entries(dataColumnIdToFieldNameMapping)
                                                        .filter(([, storedFileColumn]) => {
                                                            return storedFileColumn !== fileColumn;
                                                        })
                                                        .reduce((acc, [storedDataColumnId, storedFileColumn]) => {
                                                            return {
                                                                ...acc,
                                                                [storedDataColumnId]: storedFileColumn,
                                                            };
                                                        }, {});

                                                // Map this column to the selected field
                                                if (fileColumn !== null) {
                                                    mappingWithDataColumnRemoved[dataColumnId] = fileColumn;
                                                } else {
                                                    delete mappingWithDataColumnRemoved[dataColumnId];
                                                }

                                                dispatch({
                                                    type: "SET_DATA_COLUMN_ID_TO_FIELD_NAME_MAPPING",
                                                    mapping: mappingWithDataColumnRemoved,
                                                    fileId: fileInformation?.id ?? "no-file",
                                                });

                                                // Don't create a new column for this file column
                                                dispatch({
                                                    type: "EXCLUDE_UNMAPPED_COLUMN",
                                                    columnName: fileColumn,
                                                    fileId: fileInformation?.id ?? "no-file",
                                                });

                                                const baseFieldMappingEntry = Object.entries(mapping).find(
                                                    ([, fieldName]) => fieldName === fileColumn
                                                );
                                                if (!baseFieldMappingEntry) return;

                                                dispatch({
                                                    type: "SET_USER_MAPPING",
                                                    mapping: {
                                                        ...userMapping,
                                                        [baseFieldMappingEntry[0]]: undefined,
                                                    },
                                                    fileId: fileInformation?.id ?? "no-file",
                                                });
                                            }}
                                        />
                                    );
                                })}
                            {!isFirstTimeUpload &&
                                unmappedFileColumns.map((fileColumn) => (
                                    <MappingRow
                                        key={`unmappedfield-${fileColumn}`}
                                        newField
                                        columnName={fileColumn}
                                        explicitMapping={fileColumn}
                                        implicitMapping={fileColumn}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                    <Stack direction="row" gap={1} justifyContent="flex-end">
                        <Button variant="text" onClick={onClose}>
                            <Typography>
                                <FormattedMessage defaultMessage="Cancel" description="Cancel" />
                            </Typography>
                        </Button>
                        <Button onClick={() => onMapComplete(mapping)} disabled={!canSubmit}>
                            <FormattedMessage defaultMessage="Confirm" description="Confirm" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
