import useSWR, { Fetcher } from "swr";

type Relation = {
    object: "general";
    relation: "read" | "write";
};

const fetcher: Fetcher<boolean[], Relation[]> = async (relationTuples) => {
    const result = await fetch(`${process.env.REACT_APP_AUTH_SIDECAR_API_URL}/permissions/check/suppliers`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    if (!result.ok) {
        throw new Error(`Failed to check permissions: ${result.statusText}`);
    }
    const data: boolean[] = await result.json();
    return Array.isArray(data) ? data : Array(relationTuples.length).fill(false);
};

export function usePermission(relation: Relation): boolean {
    const { data, error } = useSWR([relation], fetcher);
    if (error) return true;
    if (data?.length === 1) return data[0];
    return false;
}
