import { Stack, Typography } from "@mui/material";

import { DataGridColumnFragment } from "@/gql/graphql";

import { ColumnDefinition } from "./columnDefinition";
import { DragIndicator } from "./DragIndicator";

export function getDefaultColumn(column: DataGridColumnFragment): ColumnDefinition {
    return {
        field: column.id,
        headerName: column.name,
        width: 150,
        renderHeader: (params) => {
            return (
                <Stack direction="row" alignItems="center">
                    <DragIndicator />
                    <Typography variant="inherit" fontWeight={500}>
                        {params.colDef?.headerName}
                    </Typography>
                </Stack>
            );
        },
    };
}
