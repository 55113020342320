import { formatValue } from "@ignite-analytics/locale";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

export const SuppliersWithSocialRisk: React.FC = () => {
    const { formatMessage } = useIntl();
    const percentageSuppliersWithSocialRisk = 0.742 * 100;
    return (
        <InsightBox>
            <Stack justifyContent="space-between" height="100%">
                <Stack spacing={1.5}>
                    <Typography variant="textSm" color="text.text-helper">
                        <FormattedMessage
                            defaultMessage="Suppliers with social risk score"
                            description="Suppliers with social risk score header"
                        />
                    </Typography>

                    <Stack spacing={1}>
                        <Typography variant="displayMd" fontWeight="medium">
                            {`${formatValue(percentageSuppliersWithSocialRisk, 2, undefined, true)}%`}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={percentageSuppliersWithSocialRisk}
                            color={
                                percentageSuppliersWithSocialRisk < 33
                                    ? "error"
                                    : percentageSuppliersWithSocialRisk < 66
                                      ? "warning"
                                      : "success"
                            }
                            sx={{
                                borderRadius: 4,
                                height: 6,
                                backgroundColor: (theme) => theme.palette.background.variant,
                            }}
                        />
                        <Typography variant="textSm" color="text.text-helper" paddingBottom={1}>
                            <FormattedMessage
                                defaultMessage="Add missing data to increase data coverage"
                                description="Add missing data to increase data coverage helper text"
                            />
                        </Typography>
                    </Stack>
                </Stack>
                <ViewButton
                    buttonText={formatMessage({
                        defaultMessage: "Add data",
                        description: "Add data button",
                    })}
                    onClick={() => {}}
                />
            </Stack>
        </InsightBox>
    );
};
