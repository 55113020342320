import { ChevronDown } from "@ignite-analytics/icons";
import { Button, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface TableViewHeaderProps {
    tableViews: string[];
    currentTable: number;
    setCurrentTable: (table: number) => void;
    onViewAllClick: () => void;
}

export const TableViewHeader: React.FC<TableViewHeaderProps> = ({
    tableViews,
    currentTable,
    setCurrentTable,
    onViewAllClick,
}) => (
    <Stack direction="row" alignItems="center" justifyContent="space-between" paddingBottom={1.5}>
        <Typography variant="textLg" component="h2" fontWeight="medium">
            <FormattedMessage defaultMessage="High risk suppliers" description="high risk suppliers list header" />
        </Typography>
        <Stack direction="row" spacing={2}>
            {tableViews.length > 0 && (
                <FormControl>
                    <Select
                        sx={{
                            margin: 0,
                            fontWeight: "medium",
                            ["& .MuiSelect-icon"]: { color: "inherit", alignItems: "center" },
                        }}
                        size="small"
                        value={currentTable}
                        onChange={(e) => setCurrentTable(e.target.value as number)}
                        IconComponent={(props) => <ChevronDown fontSize="small" {...props} />}
                    >
                        {tableViews.map((view: string, index: number) => (
                            <MenuItem key={view} value={index}>
                                {view}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Stack justifyContent="center">
                <Button size="small" color="secondary" onClick={onViewAllClick}>
                    <FormattedMessage defaultMessage="View all" description="view all button" />
                </Button>
            </Stack>
        </Stack>
    </Stack>
);
