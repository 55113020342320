import { Box } from "@mui/material";

import { BoxItem } from "./TableViewsDemoComponents/BoxItem";
import { IndustryDistributionList } from "./TableViewsDemoComponents/IndustryDistributionList";
import { TableViewHeader } from "./TableViewsDemoComponents/TableViewHeader";

export const TableViewsDemo: React.FC = () => {
    return (
        <Box px={1.5}>
            <BoxItem>
                <TableViewHeader />
                <IndustryDistributionList />
            </BoxItem>
        </Box>
    );
};
