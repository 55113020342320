import React, { useEffect, useState, useCallback } from "react";

import { getUserInfo } from "@/lib/token";

import { StartRfxButton } from "./dumb/StartRfxButton";

interface Props {
    deepStreamId: string;
    supplierIds: string[];
}

type UserInfo = {
    userEmail: string;
    tenant: string;
};

const url = process.env.REACT_APP_DEEP_STREAM_URL;
const pilotUrl = process.env.REACT_APP_PILOT_DEEP_STREAM_URL;
const TEST_TENANTS = ["ostoyapl", "deepstreamignitedemo", "ignitedeepstreamdemo"];

export const StartRfx: React.FC<Props> = ({ deepStreamId, supplierIds }: Props) => {
    const [userInfo, setUserInfo] = useState<UserInfo>({ userEmail: "", tenant: "" });

    useEffect(() => {
        getUserInfo().then(({ email, tenant }) => setUserInfo({ userEmail: email, tenant: tenant }));
    }, []);

    const handleStartRfx = useCallback(
        (supplierIds: string[]) => {
            const computeBaseLink = (): string => {
                const trimmedId = deepStreamId.trim();
                let baseUrl = url;
                if (TEST_TENANTS.includes(userInfo.tenant)) {
                    baseUrl = pilotUrl;
                }
                return `${baseUrl}/${trimmedId}/requests?tab=templates&`;
            };

            const params = new URLSearchParams({ systemId: userInfo.tenant, creator: userInfo.userEmail });
            supplierIds?.forEach((id) => {
                params.append("supplierIds", id);
            });
            const rfxUrl = computeBaseLink() + params.toString();

            window.open(rfxUrl, "_blank");
        },
        [userInfo, deepStreamId]
    );

    return (
        <StartRfxButton
            disabled={supplierIds.length < 1 || supplierIds.length > 20}
            onClick={() => handleStartRfx(supplierIds)}
        />
    );
};
