import { Drag } from "@ignite-analytics/icons";

import { customGridClasses } from "../../../constants";

export function DragIndicator(): JSX.Element {
    return (
        <Drag
            className={customGridClasses.dragIndicator}
            sx={{
                fontSize: 14,
                paddingRight: "4px",
                display: "none",
                color: "grey.500",
            }}
        />
    );
}
