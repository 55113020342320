import { ChevronDown } from "@ignite-analytics/icons";
import { formatValue } from "@ignite-analytics/locale";
import { Autocomplete, Chip, FormControl, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

export const ResponseRateQuestionnaires: React.FC = () => {
    const { formatMessage } = useIntl();

    const selectedResponseRate = 0.8134 * 100;

    return (
        <InsightBox>
            <Stack height="100%">
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Typography variant="textSm" color="text.text-helper">
                        <FormattedMessage
                            defaultMessage="Response rate on Questionnaire"
                            description="Response rate on questionnaire header"
                        />
                    </Typography>
                    <Stack
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        justifyContent="flex-start"
                    >
                        <FormControl fullWidth margin="none" sx={{ width: 250 }}>
                            <Autocomplete
                                id="questionnaireId"
                                sx={{
                                    zIndex: 999,
                                }}
                                options={[{ id: "1", name: "Transparency Act" }]}
                                popupIcon={<ChevronDown fontSize="small" />}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                margin: 0,
                                            },
                                            fontWeight: "medium",
                                        }}
                                        size="small"
                                    />
                                )}
                                getOptionLabel={(option) => option.name}
                                value={{ id: "1", name: "Transparency Act" }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                size="small"
                                fullWidth
                            />
                        </FormControl>
                    </Stack>
                </Stack>
                {selectedResponseRate ? (
                    <Stack justifyContent="space-between" height="100%" spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="displayMd" fontWeight="medium">{`${formatValue(
                                selectedResponseRate,
                                2
                            )}%`}</Typography>
                            {selectedResponseRate < 50 && (
                                <Chip
                                    color="error"
                                    label={formatMessage({
                                        defaultMessage: "Low response rate",
                                        description: "low response rate on questionnaire chip",
                                    })}
                                />
                            )}
                        </Stack>
                        <ViewButton
                            buttonText={formatMessage({
                                defaultMessage: "View answers",
                                description: "View answers button",
                            })}
                            onClick={() => {}}
                        />
                    </Stack>
                ) : (
                    <Typography variant="displayMd">- %</Typography>
                )}
            </Stack>
        </InsightBox>
    );
};
