import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { Filter } from "../types";

const parseDate = (dateStr: string | null) => {
    if (!dateStr) return null;
    return dayjs(dateStr, "YYYY-MM-DD");
};

interface DateFilterProps {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const DateFilter: React.FC<DateFilterProps> = ({ filter, handleUpdateFilter }) => {
    return (
        <>
            <Grid item xs={4}>
                <DatePicker
                    label="From"
                    value={parseDate(filter.minDate)}
                    onChange={(date) =>
                        handleUpdateFilter({
                            ...filter,
                            minDate: date ? date.format("YYYY-MM-DD") : null,
                        })
                    }
                    format="DD-MM-YYYY"
                    slotProps={{ textField: { size: "small", fullWidth: true } }}
                />
            </Grid>
            <Grid item xs={4}>
                <DatePicker
                    label="To"
                    value={parseDate(filter.maxDate)}
                    onChange={(date) =>
                        handleUpdateFilter({
                            ...filter,
                            maxDate: date ? date.format("YYYY-MM-DD") : null,
                        })
                    }
                    format="DD-MM-YYYY"
                    slotProps={{ textField: { size: "small", fullWidth: true } }}
                />
            </Grid>
        </>
    );
};
