import { Chip, Divider, Grid, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";

interface ListRowProps {
    risk: "HIGH" | "MEDIUM" | "LOW";
    name: string;
    value: string;
    onClick?: () => void;
}

export const ListRow: React.FC<ListRowProps> = ({ risk, name, value, onClick }) => {
    const { formatMessage } = useIntl();
    const [chipText, chipType] = (() => {
        switch (risk) {
            case "HIGH":
                return [formatMessage({ defaultMessage: "High", description: "High chip label" }), "error"];

            case "MEDIUM":
                return [formatMessage({ defaultMessage: "Medium", description: "Medium chip label" }), "warning"];

            case "LOW":
                return [formatMessage({ defaultMessage: "Low", description: "Low chip label" }), "success"];

            default:
                return [formatMessage({ defaultMessage: "Unknown", description: "Unknown chip label" }), "default"];
        }
    })();

    return (
        <Stack>
            <ListItem
                sx={{
                    cursor: onClick ? "pointer" : "default",
                    ":hover": { backgroundColor: (theme) => theme.palette.action.hover },
                    paddingY: 2,
                    paddingX: 3,
                }}
                onClick={onClick}
            >
                <Grid container width="100%" alignItems="center">
                    <Grid item xs={8}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Tooltip title={name}>
                                <Typography variant="textSm" fontWeight="medium" noWrap>
                                    {name}
                                </Typography>
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item xs={2}>
                        <Chip
                            color={(chipType as "error" | "warning" | "success" | "primary") ?? "primary"}
                            label={chipText}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Stack width="100%" alignItems="flex-end" overflow="hidden">
                            <Typography variant="textSm" fontWeight="medium">
                                {value}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </Stack>
    );
};
