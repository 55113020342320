import { Plus, Trash, Pen, X } from "@ignite-analytics/icons";
import {
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    listItemSecondaryActionClasses,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

interface AddChoicesProps {
    selectChoices: string[];
    updateChoices: (choices: string[]) => void;
    inputError: boolean;
}

export const AddChoices: React.FC<AddChoicesProps> = ({ selectChoices, updateChoices, inputError }) => {
    const [addingChoices, setAddingChoices] = useState<boolean>(false);
    const [newChoice, setNewChoice] = useState<string>();

    const addChoice = (newChoice: string) => {
        if (newChoice !== "" && !selectChoices.includes(newChoice)) {
            updateChoices([...selectChoices, newChoice]);
        }
    };
    const deleteChoice = (i: number) => updateChoices(selectChoices.filter((_, idx) => i !== idx));
    const editChoice = (editedChoice: string, i: number) =>
        updateChoices(selectChoices.map((choice, idx) => (i === idx ? editedChoice : choice)));

    return (
        <Stack alignItems="flex-start">
            {!addingChoices && (
                <>
                    <Typography variant="textXs" mt={2}>
                        <FormattedMessage defaultMessage="Options: " description="Options title" />
                    </Typography>
                    <Button
                        startIcon={<Plus />}
                        variant="text"
                        color="secondary"
                        onClick={() => setAddingChoices(true)}
                    >
                        <FormattedMessage defaultMessage="Add options" description="Add option button" />
                    </Button>
                </>
            )}
            {addingChoices && (
                <>
                    <Typography variant="textXs" mt={2}>
                        <FormattedMessage defaultMessage="Options: " description="Options heading" />
                    </Typography>
                    <List
                        sx={{
                            maxHeight: (theme) => theme.spacing(35),
                            overflow: "auto",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                    >
                        {selectChoices
                            .map((choice, i) => (
                                <Choice
                                    key={choice}
                                    choice={choice}
                                    onDelete={() => deleteChoice(i)}
                                    onEdit={(editedChoice: string) => editChoice(editedChoice, i)}
                                />
                            ))
                            .reverse()}
                    </List>

                    <TextField
                        id="option-input"
                        name="option"
                        label={<FormattedMessage defaultMessage="Option" description="Option label" />}
                        helperText={
                            <FormattedMessage
                                defaultMessage="Press enter to add"
                                description="Press enter to add helptext"
                            />
                        }
                        type="text"
                        size="small"
                        fullWidth
                        error={(inputError && selectChoices.length) === 0}
                        value={newChoice}
                        onChange={(event) => setNewChoice(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter" && newChoice) {
                                addChoice(newChoice);
                                setNewChoice("");
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setNewChoice("")}
                                        sx={{
                                            visibility:
                                                newChoice !== undefined && newChoice !== "" ? "visible" : "hidden",
                                        }}
                                    >
                                        <X fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </>
            )}
        </Stack>
    );
};

interface ChoiceProps {
    choice: string;
    onDelete: () => void;
    onEdit: (editedChoice: string) => void;
}

const Choice: React.FC<ChoiceProps> = ({ choice, onDelete, onEdit }) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [editedChoice, setEditedChoice] = useState<string>(choice);

    if (!editing) {
        return (
            <ListItem
                sx={{
                    [`& .${listItemSecondaryActionClasses.root}`]: {
                        display: "none",
                    },
                    [`&:hover .${listItemSecondaryActionClasses.root}`]: {
                        display: "block",
                    },
                }}
                secondaryAction={
                    <>
                        <IconButton
                            onClick={() => {
                                setEditing(true);
                            }}
                        >
                            <Pen fontSize="small" />
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <Trash fontSize="small" />
                        </IconButton>
                    </>
                }
            >
                <ListItemText>{choice}</ListItemText>
            </ListItem>
        );
    }
    return (
        <ListItem disableGutters>
            <TextField
                key={choice}
                size="small"
                fullWidth
                value={editedChoice}
                label={<FormattedMessage defaultMessage="Edit option" description="Edit option label" />}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && editing) {
                        onEdit(editedChoice);
                        setEditing(false);
                    }
                }}
                onBlur={() => {
                    onEdit(editedChoice);
                    setEditing(false);
                }}
                onChange={(event) => setEditedChoice(event.target.value)}
            />
        </ListItem>
    );
};
