import { Alert } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { useSupplierUploadContext } from "../providers/SupplierUpload";

export const DataValidationErrors: React.FC = () => {
    const [{ supplierNameIndex, blankRowsInfo, duplicatesInFile }] = useSupplierUploadContext();

    const haveBlanks =
        blankRowsInfo && supplierNameIndex === blankRowsInfo.columnIndex && blankRowsInfo.indices.length > 0;
    const haveDuplicates =
        duplicatesInFile &&
        supplierNameIndex === duplicatesInFile.uniqueColumnIndicies[0] &&
        duplicatesInFile.duplicateValues.some((duplicate) => duplicate !== "");

    return (
        <React.Fragment>
            {haveBlanks && (
                <Alert severity="error">
                    <FormattedMessage
                        defaultMessage="You have missing supplier names in your file. Please remove the following rows in excel and reupload:"
                        description="You have missing supplier names in your file. Please remove the following rows in excel and reupload:"
                    />{" "}
                    {/* +1 for 0 -> 1 based indexing, +1 for skipping the header row */}
                    {blankRowsInfo?.indices.map((index) => `${index + 2}`).join(", ")}
                </Alert>
            )}
            {haveDuplicates && (
                <Alert severity="error">
                    <FormattedMessage
                        defaultMessage="You have duplicate supplier names in your file. Please remove the following suppliers in excel and reupload:"
                        description="You have duplicate supplier names in your file. Please remove the following suppliers in excel and reupload:"
                    />{" "}
                    {duplicatesInFile.duplicateValues
                        .filter((duplicate) => duplicate !== "")
                        .map((duplicateValue) => `"${duplicateValue}"`)
                        .join(", ")}
                </Alert>
            )}
        </React.Fragment>
    );
};
