import { COMPANY_REG_NR_GT, SUPPLIER_COUNTRY_GT, SUPPLIER_NAME_GT } from "@ignite-analytics/global-types";
import { defineMessage } from "react-intl";

import { PrimitiveDataColumnTypeInput } from "@/gql/graphql";

import { BaseSupplierColumn, BaseSupplierColumnMapping } from "./interfaces";

// Typing hack: Make sure we keep the number of base columns in sync with the type
const DUMMY_SUPPLIER_COLUMN_MAPPING: BaseSupplierColumnMapping = {
    name: "",
    country: "",
    regNumber: "",
};
export const ALL_BASE_SUPPLIER_COLUMNS = Object.keys(DUMMY_SUPPLIER_COLUMN_MAPPING) as BaseSupplierColumn[];

export const BASE_IMPORT_CONFIG = {
    contentType: "XLSX",
    csvConfiguration: null,
    xlsxConfiguration: {
        dataStartIndex: 2,
        headerRowIndex: 1,
        numberOfEndLinesToDrop: 0,
    },
} as const;

export const BASE_SUPPLIER_COLUMN_DATA_TYPES = {
    name: "TEXT",
    country: "TEXT",
    regNumber: "TEXT",
} as const;

// Type assertion for as const variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __: Record<BaseSupplierColumn, PrimitiveDataColumnTypeInput> = BASE_SUPPLIER_COLUMN_DATA_TYPES;

export const BASE_SUPPLIER_COLUMN_GLOBAL_TYPES = {
    name: SUPPLIER_NAME_GT,
    country: SUPPLIER_COUNTRY_GT,
    regNumber: COMPANY_REG_NR_GT,
} as const;

// Type assertion for as const variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ___: Record<
    BaseSupplierColumn,
    typeof SUPPLIER_COUNTRY_GT | typeof COMPANY_REG_NR_GT | typeof SUPPLIER_NAME_GT | null
> = BASE_SUPPLIER_COLUMN_GLOBAL_TYPES;

export const SUPPLIER_COLUMN_MESSAGES: Record<
    BaseSupplierColumn | "spend" | "contactEmail",
    { defaultMessage: string; description: string }
> = {
    name: defineMessage({ defaultMessage: "Supplier name", description: "Supplier name" }),
    country: defineMessage({ defaultMessage: "Supplier country", description: "Supplier country" }),
    regNumber: defineMessage({
        defaultMessage: "Organization number/VAT number",
        description: "Organization number/VAT number",
    }),
    spend: defineMessage({ defaultMessage: "Spend amount", description: "Spend amount" }),
    contactEmail: defineMessage({ defaultMessage: "Contact email", description: "Contact email" }),
};

export const SUPPLIER_COLUMN_STANDARD_NAMES: Record<BaseSupplierColumn | "contactEmail", string> = {
    name: "Supplier name",
    country: "Country",
    regNumber: "VAT Id",
    contactEmail: "Contact email",
};
