import { useLazyQuery } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import {
    Stack,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    IconButton,
    DialogTitle,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { columnsDocument } from "../MuiDataGridTable/hooks/useTableData/useDataGridColumns";

import timeImage from "./time.svg";

interface Props {
    onClose: VoidFunction;
}

export const ConfigurationStarted: React.FC<Props> = ({ onClose }) => {
    const [fetchSupplierTableMeta, { loading }] = useLazyQuery(columnsDocument, {
        onCompleted: () => {
            onClose();
        },
        onError: () => {
            onClose();
        },
    });

    const handleClose = () => {
        fetchSupplierTableMeta();
    };

    return (
        <Stack>
            <DialogTitle>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <img src={timeImage} width="50px" alt="Time icon" crossOrigin="anonymous" />

                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.gray.dark,
                            position: "absolute",
                            top: 4,
                            right: 4,
                        }}
                    >
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack alignItems="center" spacing={2} paddingTop={2}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Configuring..."
                            description="How we calculate social risk label"
                        />
                    </Typography>
                    <Typography variant="textMd" fontWeight={400} color={(theme) => theme.palette.text["text-helper"]}>
                        <FormattedMessage
                            defaultMessage="It will take some time for the social risk configuration to be completed. Your supplier table will be updated automatically."
                            description="Link to help center"
                        />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant="outlined" onClick={handleClose} size="medium" color="secondary">
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <FormattedMessage defaultMessage="Close" description="Close button" />
                    )}
                </Button>
            </DialogActions>
        </Stack>
    );
};

export default ConfigurationStarted;
