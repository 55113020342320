import { Divider, List } from "@mui/material";

import { RiskScore, TableViews_SupplierFragment } from "@/gql/graphql";

import { FilterInput } from "../../../InlineFilter/types";
import { naceCodeToText } from "../helpers";

import { ListContentContainer } from "./ListContentContainer";
import { ListRow } from "./ListRow";

interface IndustryDistributionListProps {
    industryRiskColumnId?: string;
    naceColumnId?: string;
    topSuppliers?: TableViews_SupplierFragment[];
    topSuppliersLoading: boolean;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}

interface ListNace {
    numberOfSuppliers: number;
    risk: RiskScore;
}

export const IndustryDistributionList: React.FC<IndustryDistributionListProps> = ({
    industryRiskColumnId,
    naceColumnId,
    onNavigate,
    topSuppliers,
    topSuppliersLoading,
}) => {
    const naceDistribution =
        topSuppliers
            ?.filter((sup) => sup.risk?.social === "HIGH")
            .reduce(
                (prev, supplier) => {
                    const industryRiskValue = supplier.risk?.industry;
                    if (!industryRiskValue) return prev;
                    const naceValue = supplier.nace;
                    if (!naceValue) return prev;
                    const prevValue = prev[naceValue];
                    if (prevValue === undefined) {
                        prev[naceValue] = { numberOfSuppliers: 1, risk: industryRiskValue };
                        return prev;
                    }
                    prevValue.numberOfSuppliers += 1;
                    return prev;
                },
                {} as { [country: string]: ListNace }
            ) ?? [];

    const sortedNaceDistribution = Object.entries(naceDistribution).sort(
        (a, b) => b[1].numberOfSuppliers - a[1].numberOfSuppliers
    );

    const handleOnClick = (nace: string) => {
        if (!industryRiskColumnId || !naceColumnId) return;
        const filter: FilterInput[] = [
            {
                type: "include",
                column_id: industryRiskColumnId,
                include_exclude_values: ["high"],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: false,
            },
            {
                type: "include",
                column_id: naceColumnId,
                include_exclude_values: [nace],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: false,
            },
        ];
        onNavigate(filter, ["name", "country", industryRiskColumnId, naceColumnId], "DistributionHighRiskIndustries");
    };

    return (
        <List>
            <Divider />
            <ListContentContainer loading={topSuppliersLoading}>
                {sortedNaceDistribution.slice(0, 10).map(([nace, { numberOfSuppliers, risk }]) => {
                    return (
                        <ListRow
                            key={nace}
                            risk={risk}
                            name={naceCodeToText(nace)}
                            value={numberOfSuppliers.toString()}
                            onClick={() => handleOnClick(nace)}
                        />
                    );
                })}
            </ListContentContainer>
        </List>
    );
};
