import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { AddToCampaignTab_CampaignFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

graphql(`
    fragment AddToCampaignTab_Campaign on Campaign {
        id
        name
        type
        dueDate
    }
`);

const addToCampaignDocument = graphql(`
    mutation AddToCampaign_AddSuppliersToExternalCampaignMutation($input: AddSuppliersToExternalCampaignInput!) {
        addSuppliersToExternalCampaign(input: $input) {
            assessments {
                id
            }
        }
    }
`);

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    supplierIds: string[];
    existingCampaigns: AddToCampaignTab_CampaignFragment[];
};

export const AddToCampaignTab: React.FC<Props> = ({ setStep, supplierIds, existingCampaigns }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const ongoingCampaigns = existingCampaigns.filter(
        (c) => Date.parse(c.dueDate) > Date.now() && c.type === "external"
    );
    const expiredCampaigns = existingCampaigns.filter(
        (c) => Date.parse(c.dueDate) < Date.now() && c.type === "external"
    );

    const [error, setError] = useState<boolean>(false);

    const validate = () => {
        if (!selectedCampaign) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    };

    const navigate = useNavigate();
    const location = useLocation();
    const onV2 = location.pathname.includes("/v2/");
    const [addSuppliersToExternalCampaign] = useMutation(addToCampaignDocument);
    const [selectedCampaign, setSelectedCampaign] = useState<AddToCampaignTab_CampaignFragment>();
    const addToCampaign = () => {
        if (!validate()) return;
        addSuppliersToExternalCampaign({
            variables: {
                input: {
                    campaignId: selectedCampaign?.id ?? "",
                    supplierIds: supplierIds,
                },
            },
            onCompleted: () => {
                track("Supplier Table: Added suppliers to Campaign", {
                    campaignId: selectedCampaign?.id,
                    numberOfSuppliers: supplierIds.length,
                });
                navigate(`${onV2 ? "/v2" : ""}/assessments/campaigns/${selectedCampaign?.id}/`);
            },
            onError: () => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "An error occured when adding suppliers to campaign",
                        description: "Alert on adding suppliers to campaign error",
                    }),
                    severity: "error",
                });
            },
        });
    };

    useEffect(() => {
        if (selectedCampaign) {
            setError(false);
        }
    }, [selectedCampaign]);

    return (
        <>
            <DialogTitle>
                <FormattedMessage
                    defaultMessage="Add to existing campaign"
                    description="Add to existing campaign title"
                />
            </DialogTitle>

            <DialogContent>
                <Stack mt={2}>
                    <Stack>
                        <FormControl error={error}>
                            <Select
                                value={selectedCampaign?.id || ""}
                                onChange={(event) => {
                                    setSelectedCampaign(
                                        existingCampaigns?.find((campaign) => campaign.id === event.target.value)
                                    );
                                }}
                                displayEmpty
                                MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                            >
                                <MenuItem value="" disabled>
                                    <FormattedMessage
                                        defaultMessage="Select campaign"
                                        description="Questionnaire selection placeholder"
                                    />
                                </MenuItem>
                                {ongoingCampaigns?.map((campaign) => {
                                    return (
                                        <MenuItem value={campaign.id} key={campaign.id}>
                                            <Stack>
                                                <Typography variant="textSm">{campaign.name}</Typography>
                                                <Typography variant="textXs" color="text.text-helper">
                                                    <FormattedMessage defaultMessage="Ongoing" />
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    );
                                })}
                                <Divider />
                                {expiredCampaigns?.map((campaign) => {
                                    return (
                                        <MenuItem value={campaign.id} key={campaign.id}>
                                            <Stack>
                                                <Typography variant="textSm">{campaign.name}</Typography>
                                                <Typography variant="textXs" color="text.text-helper">
                                                    <FormattedMessage defaultMessage="Expired" />
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>

                    {error && (
                        <Typography color="error">
                            <FormattedMessage
                                defaultMessage="Please select a campaign"
                                description="Please select a campaign"
                            />
                        </Typography>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} justifyContent="end">
                        <Button
                            variant="text"
                            onClick={() => {
                                setStep(0);
                            }}
                            size="medium"
                            color="secondary"
                        >
                            <FormattedMessage defaultMessage="Back" description="Back button" />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                addToCampaign();
                            }}
                            size="medium"
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Continue" description="continue button" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </>
    );
};
