import { Autocomplete, Grid, TextField } from "@mui/material";
import { useIntl } from "react-intl";

import { Filter } from "../types";

import { ListboxComponent, StyledPopper } from "./IncludeExcludeFilter/ListBoxComponent";
import { SelectFilterType } from "./SelectFilterType";

interface OnboardingFilter {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const OnboardingFilter: React.FC<OnboardingFilter> = ({ filter, handleUpdateFilter }) => {
    const { formatMessage } = useIntl();
    const emptyFields = formatMessage({ defaultMessage: "Empty fields" });
    const valuesMinusEmptyFields = filter.includeExcludeValues?.filter((v) => v != emptyFields);
    function getOnboardingLabel(option: string) {
        switch (option) {
            case "approved":
                return formatMessage({ defaultMessage: "Approved" });
            case "in_onboarding":
                return formatMessage({ defaultMessage: "In onboarding" });
            case "rejected":
                return formatMessage({ defaultMessage: "Rejected" });
            default:
                return option;
        }
    }

    const options = ["approved", "in_onboarding", "rejected"];

    return (
        <>
            <Grid item xs={4}>
                <SelectFilterType filter={filter} handleUpdateFilter={handleUpdateFilter} />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    multiple
                    size="small"
                    disabled={!filter.type}
                    getLimitTagsText={(more) => `${more} selected`}
                    id={`filter-value-select-${filter.id}`}
                    options={options}
                    value={valuesMinusEmptyFields ?? []}
                    ListboxComponent={ListboxComponent}
                    PopperComponent={StyledPopper}
                    autoHighlight
                    disableCloseOnSelect
                    renderTags={() => {
                        return `${valuesMinusEmptyFields.length} selected`;
                    }}
                    renderOption={(props, option, state) =>
                        [props, getOnboardingLabel(option), state.index] as React.ReactNode
                    }
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === "clear") {
                            handleUpdateFilter({
                                ...filter,
                                includeExcludeValues: [],
                            });
                        }
                    }}
                    onChange={(event, newValue, reason) => {
                        const returnNonOnboardedSuppliers = newValue.includes("approved") && filter.type === "include";
                        if (reason === "selectOption" || reason === "removeOption") {
                            handleUpdateFilter({
                                ...filter,
                                includeExcludeValues: newValue,
                                includeBlanks: returnNonOnboardedSuppliers,
                            });
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label={filter.type} />}
                />
            </Grid>
        </>
    );
};
