import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";

import {
    ALL_BASE_SUPPLIER_COLUMNS,
    BASE_SUPPLIER_COLUMN_DATA_TYPES,
    BASE_SUPPLIER_COLUMN_GLOBAL_TYPES,
    SUPPLIER_COLUMN_MESSAGES,
    SUPPLIER_COLUMN_STANDARD_NAMES,
} from "../constants";
import { evictDataGridColumns } from "../helpers";
import { useAutomappedColumns } from "../hooks/useAutomappedColumns";
import { useFileUploadAndValidation } from "../hooks/useFileUploadAndValidation";
import { BaseSupplierColumn, BaseSupplierColumnMapping, DataSource } from "../interfaces";
import { Action, ReducerState, useSupplierUploadReducer } from "../reducer";

const GetSupplierTableDataSourcesDocument = graphql(`
    query SupplierUpload_GetSupplierTableDataSources {
        getSupplierTableDataSources {
            sources {
                id
                name
                fields {
                    id
                    name
                }
            }
            dataPipelineIds
            dataTableId
        }
    }
`);

const GetDataRepositoryRowCountDocument = graphql(`
    query SupplierUpload_GetDataRepositoryRowCount($input: GetDataRepositoryRowCountInput!) {
        getDataRepositoryRowCount(input: $input) {
            rowCount
        }
    }
`);

const GetSupplierFieldsByColumnDocument = graphql(`
    query SupplierUpload_GetSupplierFieldsByColumn {
        getSupplierFieldsByColumn {
            fields {
                fieldId
                fieldName
                dataColumnId
                dataColumnName
                globalType
            }
        }
    }
`);

const EnsureSupplierTableSpendColumnsDocument = graphql(`
    mutation SupplierUpload_EnsureSupplierTableSpendColumns($input: EnsureSupplierTableSpendColumnsInput!) {
        ensureSupplierTableSpendColumns(input: $input) {
            updated
        }
    }
`);

const SetupSupplierTableDocument = graphql(`
    mutation SupplierUpload_SetupSupplierTable($input: SetupSupplierTableInput!) {
        setupSupplierTable(input: $input) {
            updated
        }
    }
`);

interface ConnectedFieldAndColumn {
    fieldId: string;
    fieldName: string;
    dataColumnId: string;
    dataColumnName: string;
    globalType?: string | null;
}

interface ContextValue extends ReducerState {
    fileColumns: string[];
    unmappedFileColumns: string[];
    mapping: Partial<BaseSupplierColumnMapping>;
    supplierNameIndex: number | undefined;
    supplierTableInfo: { sources: DataSource[]; dataPipelineIds: string[]; dataTableId?: string | null } | undefined;
    supplierFieldsByColumn: ConnectedFieldAndColumn[];
    baseColumnDataColumnMapping: Partial<Record<BaseSupplierColumn, ConnectedFieldAndColumn>>;
    fileSubmittedRef: React.MutableRefObject<boolean>;
    isFirstTimeUpload: boolean;
}

const Context = React.createContext<readonly [ContextValue, React.Dispatch<Action>] | null>(null);

interface SupplierUploadContextProviderProps {
    children: React.ReactNode;
}

export const SupplierUploadContextProvider: React.FC<SupplierUploadContextProviderProps> = ({ children }) => {
    const [
        {
            file,
            fileInformation,
            filePreview,
            userMapping,
            dataColumnIdToFieldNameMapping,
            includedUnmappedColumns,
            uploadComplete,
            duplicatesInFile,
            blankRowsInfo,
            uploadProgress,
            warnings,
            errors,
        },
        dispatch,
    ] = useSupplierUploadReducer();

    const { formatMessage } = useIntl();

    // Info about the supplier table: data sources, data pipeline ids, data table id
    const { data: supplierTableDataSourcesData, refetch: refetchSupplierInfo } = useQuery(
        GetSupplierTableDataSourcesDocument,
        {
            // Force a refetch if we upload first time and then go straight back to upload a second time
            fetchPolicy: "network-only",
        }
    );

    // Number of rows in the data repository
    const [getDataRepositoryRowCount] = useLazyQuery(GetDataRepositoryRowCountDocument, {
        fetchPolicy: "network-only",
    });

    // List of data repo fields matched to their data column
    const { data: supplierFieldsByColumnData, refetch: refetchSupplierFields } = useQuery(
        GetSupplierFieldsByColumnDocument,
        {
            // Force a refetch if we upload first time and then go straight back to upload a second time
            fetchPolicy: "network-only",
        }
    );
    const supplierFieldsByColumn = useMemo(() => {
        const fields = supplierFieldsByColumnData?.getSupplierFieldsByColumn.fields ?? [];
        // Sort by data column name
        return [...fields].sort((a, b) => a.dataColumnName.localeCompare(b.dataColumnName));
    }, [supplierFieldsByColumnData?.getSupplierFieldsByColumn.fields]);

    const [ensureSupplierTableSpendColumns] = useMutation(EnsureSupplierTableSpendColumnsDocument);
    const [setupSupplierTable] = useMutation(SetupSupplierTableDocument);

    const [isFirstTimeUpload, setIsFirstTimeUpload] = React.useState(false);

    useEffect(() => {
        if (supplierTableDataSourcesData === undefined) return;
        const sources = supplierTableDataSourcesData.getSupplierTableDataSources.sources;
        if (sources.length === 0) return;
        getDataRepositoryRowCount({
            variables: {
                input: {
                    dataRepositoryId: sources[0].id,
                },
            },
        })
            .then(({ data }) => {
                setIsFirstTimeUpload(data?.getDataRepositoryRowCount.rowCount === 0);
            })
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
            });
    }, [supplierTableDataSourcesData, getDataRepositoryRowCount, setIsFirstTimeUpload]);

    useEffect(
        function setupSupplierTableOnPageLoad() {
            // Had some issues with double rendering causing duplicate pipelines - hack it with a 1s timeout
            const timeout = setTimeout(() => {
                setupSupplierTable({
                    variables: {
                        input: {
                            name: {
                                dataColumnName: formatMessage(SUPPLIER_COLUMN_MESSAGES.name),
                                repositoryFieldName: SUPPLIER_COLUMN_STANDARD_NAMES.name,
                                globalType: BASE_SUPPLIER_COLUMN_GLOBAL_TYPES.name,
                                dataType: BASE_SUPPLIER_COLUMN_DATA_TYPES.name,
                            },
                            orgno: {
                                dataColumnName: formatMessage(SUPPLIER_COLUMN_MESSAGES.regNumber),
                                repositoryFieldName: SUPPLIER_COLUMN_STANDARD_NAMES.regNumber,
                                globalType: BASE_SUPPLIER_COLUMN_GLOBAL_TYPES.regNumber,
                                dataType: BASE_SUPPLIER_COLUMN_DATA_TYPES.regNumber,
                            },
                            country: {
                                dataColumnName: formatMessage(SUPPLIER_COLUMN_MESSAGES.country),
                                repositoryFieldName: SUPPLIER_COLUMN_STANDARD_NAMES.country,
                                globalType: BASE_SUPPLIER_COLUMN_GLOBAL_TYPES.country,
                                dataType: BASE_SUPPLIER_COLUMN_DATA_TYPES.country,
                            },
                            contact: {
                                dataColumnName: formatMessage(SUPPLIER_COLUMN_MESSAGES.contactEmail),
                                repositoryFieldName: SUPPLIER_COLUMN_STANDARD_NAMES.contactEmail,
                                globalType: undefined,
                                dataType: "TEXT",
                            },
                        },
                    },
                    update: (cache, { data }) => {
                        if (data?.setupSupplierTable.updated) {
                            evictDataGridColumns(cache);
                        }
                    },
                })
                    .then(({ data: setupData }) => {
                        ensureSupplierTableSpendColumns({
                            variables: { input: {} },
                            update: (cache, { data }) => {
                                if (data?.ensureSupplierTableSpendColumns.updated) {
                                    evictDataGridColumns(cache);
                                }
                            },
                        })
                            .then(({ data: spendColumnsData }) => {
                                if (
                                    setupData?.setupSupplierTable.updated ||
                                    spendColumnsData?.ensureSupplierTableSpendColumns.updated
                                ) {
                                    refetchSupplierFields();
                                    refetchSupplierInfo();
                                }
                            })
                            .catch((e) => {
                                if (setupData?.setupSupplierTable.updated) {
                                    refetchSupplierFields();
                                    refetchSupplierInfo();
                                }
                                Sentry.captureException(e, {
                                    tags: { app: "suppliers-app", feature: "supplier-upload" },
                                });
                            });
                    })
                    .catch((e) => {
                        Sentry.captureException(e, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
                    });
            }, 1000);
            return () => clearTimeout(timeout);
        },
        [formatMessage, setupSupplierTable, ensureSupplierTableSpendColumns, refetchSupplierFields, refetchSupplierInfo]
    );

    // Automatically map file columns to base columns based on name
    const automappedColumns = useAutomappedColumns(filePreview);

    // User preference (userMapping) takes precedence
    const mapping = useMemo(() => ({ ...automappedColumns, ...userMapping }), [automappedColumns, userMapping]);

    // List of file column names
    const fileColumns = useMemo(() => {
        if (!filePreview) return [];
        return filePreview.map(({ fieldKey }) => fieldKey);
    }, [filePreview]);

    // List of file columns that are not explicitly or implicitly mapped to an existing data column
    const unmappedFileColumns = useMemo(() => {
        const baseMappedFields = Object.values(mapping);
        const mappedToDataColumn = Object.values(dataColumnIdToFieldNameMapping);
        const matchedToExistingRepoField = fileColumns.filter((fileColumn) =>
            supplierFieldsByColumn.find(({ fieldName }) => fieldName === fileColumn)
        );

        const mappedFields = new Set([...baseMappedFields, ...mappedToDataColumn, ...matchedToExistingRepoField]);
        return fileColumns.filter((fileColumn) => !mappedFields.has(fileColumn));
    }, [supplierFieldsByColumn, fileColumns, mapping, dataColumnIdToFieldNameMapping]);

    // Mapping from base column to data column {name: Data column {id: 123, name: Supplier name}, ...}
    const baseColumnDataColumnMapping = useMemo(
        () =>
            ALL_BASE_SUPPLIER_COLUMNS.reduce<Partial<Record<BaseSupplierColumn, ConnectedFieldAndColumn>>>(
                (acc, baseColumn) => ({
                    ...acc,
                    [baseColumn]: supplierFieldsByColumn.find(
                        ({ globalType }) => globalType && globalType === BASE_SUPPLIER_COLUMN_GLOBAL_TYPES[baseColumn]
                    ),
                }),
                {}
            ),
        [supplierFieldsByColumn]
    );

    // Which file column maps to supplier name
    const supplierNameMapping = useMemo(() => {
        // Since name is a base column it is either explicitly mapped in mapping
        if (mapping.name !== undefined) return mapping.name;

        // or implicitly mapped by a file field matching the name of the data repo field that maps to the supplier name column
        const supplierNameDataColumn = baseColumnDataColumnMapping.name;
        if (supplierNameDataColumn === undefined) return undefined;

        const supplierNameFileColumn = fileColumns.find(
            (fileColumn) => fileColumn === supplierNameDataColumn.fieldName
        );
        if (supplierNameFileColumn === undefined) return undefined;

        // as long as that field is not explicitly mapped to something else
        if (Object.values(mapping).includes(supplierNameFileColumn)) return undefined;
        if (Object.values(dataColumnIdToFieldNameMapping).includes(supplierNameFileColumn)) return undefined;

        return supplierNameFileColumn;
    }, [baseColumnDataColumnMapping, dataColumnIdToFieldNameMapping, fileColumns, mapping]);

    // Column index in the file of the supplier name (needed for duplicates check)
    const supplierNameIndex = useMemo(() => {
        const index = fileColumns.findIndex((fileColumn) => fileColumn === supplierNameMapping);
        return index === -1 ? undefined : index;
    }, [supplierNameMapping, fileColumns]);

    // Set to true when we submit the file to prevent us deleting it or submitting it again
    const fileSubmittedRef = useRef(false);

    // Handle uploading, parsing and validating the file
    useFileUploadAndValidation(file, fileInformation, uploadComplete, supplierNameIndex, fileSubmittedRef, dispatch);

    const value = useMemo(
        () =>
            [
                {
                    file,
                    fileInformation,
                    filePreview,
                    userMapping,
                    dataColumnIdToFieldNameMapping,
                    includedUnmappedColumns,
                    uploadComplete,
                    duplicatesInFile,
                    blankRowsInfo,
                    uploadProgress,
                    warnings,
                    errors,
                    fileColumns,
                    unmappedFileColumns,
                    mapping,
                    supplierNameIndex,
                    fileSubmittedRef,
                    supplierTableInfo: supplierTableDataSourcesData?.getSupplierTableDataSources,
                    supplierFieldsByColumn,
                    baseColumnDataColumnMapping,
                    isFirstTimeUpload,
                },
                dispatch,
            ] as const,
        [
            file,
            fileInformation,
            filePreview,
            userMapping,
            dataColumnIdToFieldNameMapping,
            includedUnmappedColumns,
            uploadComplete,
            duplicatesInFile,
            blankRowsInfo,
            uploadProgress,
            warnings,
            errors,
            fileColumns,
            unmappedFileColumns,
            mapping,
            supplierNameIndex,
            supplierTableDataSourcesData?.getSupplierTableDataSources,
            supplierFieldsByColumn,
            baseColumnDataColumnMapping,
            fileSubmittedRef,
            isFirstTimeUpload,
            dispatch,
        ]
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useSupplierUploadContext() {
    const context = useContext(Context);
    if (!context) {
        throw new Error("Cannot use hook 'useSupplierUploadContext' outside an SupplierUploadContextProvider");
    }
    return context;
}
