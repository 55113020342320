import { formatValue } from "@ignite-analytics/locale";
import { Typography } from "@mui/material";

import { DataGridColumnFragment } from "@/gql/graphql";

import { ColumnDefinition } from "./columnDefinition";

export function getEditableCustomColumn(column: DataGridColumnFragment): Partial<ColumnDefinition> | null {
    switch (column.type) {
        case "DATE": {
            return {
                editable: true,
                type: "date",
                valueGetter: ({ value }) => (value ? new Date(value) : null),
                valueSetter: (params) => {
                    if (!params.value)
                        return {
                            ...params.row,
                            [column.id]: null,
                        };

                    const date = new Date(params.value);
                    const offset = date.getTimezoneOffset();
                    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
                    const newValue = adjustedDate.toISOString().split("T")[0];
                    return {
                        ...params.row,
                        [column.id]: newValue,
                    };
                },
                valueParser: (value) => {
                    if (!value) return null;
                    const newValue = new Date(value);
                    if (newValue.toString() === "Invalid Date") return null;
                    return newValue;
                },
            };
        }
        case "NUMBER": {
            return {
                editable: true,
                renderCell: (params) => {
                    return <Typography variant="textSm">{formatValue(params.value, 2)}</Typography>;
                },
                type: "number",
                valueFormatter: (params) => {
                    return params.value?.toString().replace(",", ".");
                },
                headerAlign: "left",
            };
        }
        case "TEXT": {
            return {
                editable: true,
            };
        }
        case "SELECT": {
            return {
                editable: true,
                valueOptions: () => {
                    if (column.typeOptions?.__typename === "SelectOptions") {
                        const valueOptions = column.typeOptions.choices.map((choice) => ({
                            value: choice,
                            label: choice,
                        }));
                        const emptyOption = { value: "", label: "-" };
                        return [emptyOption, ...valueOptions];
                    }
                    return [];
                },
                type: "singleSelect",
                renderCell: (params) => {
                    return <Typography variant="textSm">{params.value}</Typography>;
                },
                valueSetter: (params) => {
                    if (
                        column.typeOptions?.__typename !== "SelectOptions" ||
                        !column.typeOptions?.choices.includes(params.value)
                    )
                        return {
                            ...params.row,
                            [column.id]: null,
                        };
                    return {
                        ...params.row,
                        [column.id]: params.value,
                    };
                },
            };
        }
        default: {
            return null;
        }
    }
}
