import { ApolloCache } from "@apollo/client";

/**
 * Evict data grid columns from cache to force refetch when we get back to the table
 * Simply using refetchQueries on the mutation doesn't work because the query is not active on the upload page
 *
 * From the Apollo docs:
 * You can only refetch active queries. Active queries are those used by components on the current page.
 * If the data you want to update is not fetched by a component on the current page, it's best to update
 * your cache directly.
 */
export const evictDataGridColumns = (cache: ApolloCache<unknown>) => {
    cache.evict({ fieldName: "getSupplierTableMeta", broadcast: true });
};
