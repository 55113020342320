import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

export const SELECT_SUPPLIER_COLUMN_ID = GRID_CHECKBOX_SELECTION_COL_DEF.field;
export const NAME_COLUMN_ID = "name";
export const REG_NR_COLUMN_ID = "reg_nr";
export const COUNTRY_COLUMN_ID = "country";
export const NORMALIZED_INTO_ID = "normalized_into";
export const ENRICHMENT_ID = "enrichment_id";

export const POLLING_INTERVAL_ON = 5_000;
export const POLLING_INTERVAL_OFF = 0;

export const customGridClasses = {
    dragIndicator: "CustomMuiDataGrid-dragIndicator",
    openSupplierButton: "CustomMuiDataGrid-openSupplierButton",
} as const;
