import { SupplierTableColumn } from "@/gql/graphql";

export type FilterType = "include" | "exclude" | "range" | "date";

export const textFieldFilters: FilterType[] = ["include", "exclude"];

export const numberFieldFilters: FilterType[] = ["range"];

export const dateFieldFilters: FilterType[] = ["date"];

export interface Filter {
    id: string;
    type: FilterType | null;
    column: SupplierTableColumn | null;
    includeExcludeValues: string[];
    includeBlanks?: boolean;
    maxRange?: string;
    minRange?: string;
    maxDate: string | null;
    minDate: string | null;
    hasChanged: boolean;
}

export interface FilterInput {
    type: FilterType | null;
    column_id: string | null;
    include_exclude_values: string[];
    min_range: number | null;
    max_range: number | null;
    min_date: string | null;
    max_date: string | null;
    include_blanks: boolean;
}
