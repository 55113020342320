import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { useUser } from "@/providers/UserContext";

import { BASE_IMPORT_CONFIG } from "../constants";
import {
    DataManagementEvent,
    FindBlankCellsInColumnEvent,
    FindDuplicatesInFileEvent,
    ValidateFileDataEvent,
    useDataManagementEventListener,
} from "../hooks/useDataManagementEventListener";
import useFileManager, { FileInformation } from "../hooks/useFileManager";
import { Action } from "../reducer";

const ParseFileMutation = graphql(`
    mutation useFileUploadAndValidation_ParseFile($input: ParseFileInput!) {
        parseFile(input: $input) {
            fields {
                fieldKey
                samples
            }
        }
    }
`);

const FindDuplicatesInFileMutation = graphql(`
    mutation useFileUploadAndValidation_FindDuplicatesInFile($input: FindDuplicatesInFileInput!) {
        findDuplicatesInFile(input: $input) {
            dummyOutput
        }
    }
`);

const FindBlankCellsInColumnMutation = graphql(`
    mutation useFileUploadAndValidation_FindBlankCellsInColumn($input: FindBlankCellsInColumnInput!) {
        findBlankCellsInColumn(input: $input) {
            dummyOutput
        }
    }
`);

export const useFileUploadAndValidation = (
    file: File | undefined,
    fileInformation: FileInformation | undefined,
    uploadComplete: boolean,
    supplierNameIndex: number | undefined,
    fileSubmittedRef: React.RefObject<boolean>,
    dispatch: React.Dispatch<Action>
) => {
    const [parseFile] = useMutation(ParseFileMutation);
    const [findDuplicatesInFile] = useMutation(FindDuplicatesInFileMutation);
    const [findBlankCellsInColumn] = useMutation(FindBlankCellsInColumnMutation);

    const user = useUser();

    const fileManager = useFileManager("supplier-upload");

    const { formatMessage } = useIntl();

    // Refs used in the event listener callbacks
    const fileIdRef = useRef<string | undefined>();
    fileIdRef.current = fileInformation?.id;

    const supplierNameIndexRef = useRef<number | undefined>();
    supplierNameIndexRef.current = supplierNameIndex;

    const handleValidateFileDataEvent = useCallback(
        (event: ValidateFileDataEvent) => {
            switch (event.type) {
                case "PROCESS_FINISHED":
                    dispatch({
                        type: "FILE_PARSE_COMPLETE",
                        filePreview: event.data.fields,
                        warnings: event.data.warnings.join(", "),
                        fileId: event.fileId,
                    });
                    break;
                case "FAILED":
                    dispatch({
                        type: "FILE_PARSE_ERROR",
                        errors: event.data.errors.length ? event.data.errors.join(", ") : "Something went wrong.",
                        warnings: event.data.warnings.length ? event.data.warnings.join(", ") : "Something went wrong",
                        fileId: event.fileId,
                    });
                    break;
            }
            // NOTE: Do not add dependencies here other than dispatch, as recreating the callback will cause the websocket to reconnect
        },
        [dispatch]
    );
    const handleFindDuplicatesInFileEvent = useCallback(
        (event: FindDuplicatesInFileEvent) => {
            if (supplierNameIndexRef.current !== event.data.uniqueColumnIndicies[0]) return;
            switch (event.type) {
                case "PROCESS_FINISHED":
                    dispatch({
                        type: "FILE_DUPLICATES_RESULT",
                        duplicateValues: event.data.duplicateValues,
                        uniqueColumnIndicies: event.data.uniqueColumnIndicies,
                        fileId: event.fileId,
                    });
                    break;
                case "FAILED":
                    dispatch({
                        type: "FILE_DUPLICATES_ERROR",
                        error: event.data.error,
                        uniqueColumnIndicies: event.data.uniqueColumnIndicies,
                        fileId: event.fileId,
                    });
                    break;
            }
            // NOTE: Do not add dependencies here other than dispatch, as recreating the callback will cause the websocket to reconnect
        },
        [dispatch]
    );
    const handleFindBlankCellsInColumnEvent = useCallback(
        (event: FindBlankCellsInColumnEvent) => {
            if (supplierNameIndexRef.current !== event.data.columnIndex) return;
            switch (event.type) {
                case "PROCESS_FINISHED":
                    dispatch({
                        type: "FILE_BLANKS_RESULT",
                        blankRowIndices: event.data.blankRowIndices,
                        columnIndex: event.data.columnIndex,
                        fileId: event.fileId,
                    });
                    break;
                case "FAILED":
                    dispatch({
                        type: "FILE_BLANKS_ERROR",
                        error: event.data.error,
                        columnIndex: event.data.columnIndex,
                        fileId: event.fileId,
                    });
                    break;
            }
            // NOTE: Do not add dependencies here other than dispatch, as recreating the callback will cause the websocket to reconnect
        },
        [dispatch]
    );
    const handleDataManagementEvent = useCallback(
        (event: DataManagementEvent) => {
            if (fileIdRef.current !== event.fileId) return;
            switch (event.process) {
                case "ValidateFile":
                    handleValidateFileDataEvent(event);
                    break;
                case "FindDuplicatesInFile":
                    handleFindDuplicatesInFileEvent(event);
                    break;
                case "FindBlankCellsInColumn":
                    handleFindBlankCellsInColumnEvent(event);
                    break;
            }
        },
        // NOTE: Should only have constant callbacks as dependencies
        [handleFindDuplicatesInFileEvent, handleValidateFileDataEvent, handleFindBlankCellsInColumnEvent]
    );

    const channel = 0;
    useDataManagementEventListener(user.id, channel, handleDataManagementEvent);

    const fileManagerRef = useRef(fileManager);
    useEffect(
        function warnAboutNonConstantFileManager() {
            if (fileManagerRef.current === fileManager) return;
            fileManagerRef.current = fileManager;
            console.warn("File manager changed! This will cause duplicate calls in SupplierUpload context");
        },
        [fileManager]
    );

    useEffect(
        function getFileInformation() {
            let uploadCancelled = false;

            if (file === undefined) return;

            dispatch({ type: "SET_UPLOAD_PROGRESS", uploadProgress: 1e-6, fileId: undefined });

            fileManager
                .createUploadUrl(file)
                .then((fileInformation) => {
                    if (uploadCancelled) return;
                    dispatch({ type: "SET_FILE_INFORMATION", fileInformation, file });
                })
                .catch((error) => {
                    if (uploadCancelled) return;
                    dispatch({
                        type: "FILE_UPLOAD_ERROR",
                        errors: error,
                        fileId: undefined,
                    });
                });

            return () => {
                uploadCancelled = true;
            };
        },
        // NOTE: Should only have dispatch, file, and fileManager (static) as dependencies to only run when file changes
        [dispatch, file, fileManager]
    );

    useEffect(
        function uploadFile() {
            let uploadCancelled = false;

            if (file === undefined || fileInformation === undefined) {
                return;
            }

            const controller = new AbortController();

            fileManager
                .uploadFile(
                    file,
                    (uploadProgress: number) => {
                        dispatch({ type: "SET_UPLOAD_PROGRESS", uploadProgress, fileId: fileInformation.id });
                    },
                    fileInformation,
                    controller
                )
                .catch((error: Error) => {
                    if (uploadCancelled) return;
                    dispatch({
                        type: "FILE_UPLOAD_ERROR",
                        errors: error.message,
                        fileId: fileInformation.id,
                    });
                    return;
                })
                .then(() => {
                    if (uploadCancelled) return;

                    // Upload complete
                    dispatch({ type: "UPLOAD_COMPLETE", fileId: fileInformation.id });
                });

            return () => {
                uploadCancelled = true;

                // We want the most recent value of fileSubmittedRef.current
                // eslint-disable-next-line react-hooks/exhaustive-deps
                if (fileSubmittedRef.current) return; // Don't delete the file if we have submitted it

                // Cancel the upload and delete the file
                controller.abort();
                fileManager.deleteFile(fileInformation.id).catch((error) => {
                    Sentry.captureException(error, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
                });
            };
        },
        // NOTE: Should only have file, fileInformation, and dispatch+fileManager+fileSubmittedRef (static) as dependencies
        [file, fileInformation, dispatch, fileManager, fileSubmittedRef]
    );

    useEffect(
        function getFilePreview() {
            if (fileInformation === undefined || !uploadComplete) return;
            parseFile({
                variables: { input: { ...BASE_IMPORT_CONFIG, fileId: fileInformation.id } },
            }).catch((error: unknown) => {
                // Ignore gateway timeout errors - still listening on websocket
                if (error instanceof Error && error.message.includes("504")) return;

                dispatch({
                    type: "FILE_PARSE_ERROR",
                    errors: formatMessage({
                        defaultMessage:
                            "Failed parsing file. Please copy the sheet into a new Excel file and try again.",
                        description: "Failed parsing file. Please copy the sheet into a new Excel file and try again.",
                    }),
                    warnings: undefined,
                    fileId: fileInformation.id,
                });

                Sentry.captureException(error, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
            });
        },
        // NOTE: Should only have fileInformation, uploadComplete, and parseFile+dispatch+formatMessage (static) as dependencies
        [fileInformation, uploadComplete, parseFile, dispatch, formatMessage]
    );

    useEffect(
        function getDuplicatesInFile() {
            if (fileInformation === undefined || supplierNameIndex === undefined || !uploadComplete) return;

            const uniqueColumnIndicies = [supplierNameIndex];
            findDuplicatesInFile({
                variables: {
                    input: {
                        fileId: fileInformation.id,
                        uniqueColumnIndicies,
                        contentType: "XLSX",
                        xlsxConfiguration: BASE_IMPORT_CONFIG.xlsxConfiguration,
                        csvConfiguration: null,
                    },
                },
            }).catch((error: unknown) => {
                // Ignore gateway timeout errors - still listening on websocket
                if (error instanceof Error && error.message.includes("504")) return;

                dispatch({
                    type: "FILE_DUPLICATES_ERROR",
                    error: error instanceof Error ? error.message : "Something went wrong",
                    uniqueColumnIndicies,
                    fileId: fileInformation.id,
                });

                Sentry.captureException(error, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
            });
        },
        // NOTE: Should only have fileInformation, supplierNameIndex, uploadComplete, and findDuplicatesInFile+dispatch (static) as dependencies
        [fileInformation, supplierNameIndex, uploadComplete, findDuplicatesInFile, dispatch]
    );

    useEffect(
        function getBlankRows() {
            if (fileInformation === undefined || supplierNameIndex === undefined || !uploadComplete) return;
            findBlankCellsInColumn({
                variables: {
                    input: {
                        fileId: fileInformation.id,
                        columnIndex: supplierNameIndex,
                        contentType: "XLSX",
                        xlsxConfiguration: BASE_IMPORT_CONFIG.xlsxConfiguration,
                        csvConfiguration: null,
                    },
                },
            }).catch((error: unknown) => {
                // Ignore gateway timeout errors - still listening on websocket
                if (error instanceof Error && error.message.includes("504")) return;

                dispatch({
                    type: "FILE_BLANKS_ERROR",
                    error: error instanceof Error ? error.message : "Something went wrong",
                    columnIndex: supplierNameIndex,
                    fileId: fileInformation.id,
                });

                Sentry.captureException(error, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
            });
        },
        // NOTE: Should only have fileInformation, supplierNameIndex, uploadComplete, and findBlankCellsInColumn+dispatch (static) as dependencies
        [fileInformation, supplierNameIndex, uploadComplete, findBlankCellsInColumn, dispatch]
    );
};
