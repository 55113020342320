import { ArrowRight } from "@ignite-analytics/icons";
import { Stack, Button } from "@mui/material";

interface ViewButtonProps {
    buttonText: string;
    onClick: () => void;
}

export const ViewButton: React.FC<ViewButtonProps> = ({ buttonText, onClick }) => (
    <Stack direction="row">
        <Button size="xsmall" color="secondary" onClick={onClick} endIcon={<ArrowRight fontSize="small" />}>
            {buttonText}
        </Button>
    </Stack>
);
