import { Filter } from "../types";

import { ClassificationFilter } from "./ClassificationFilter";
import { DateFilter } from "./DateFilter";
import { IncludeExcludeFilter } from "./IncludeExcludeFilter";
import { OnboardingFilter } from "./OnboardingFilter";
import { RangeFilter } from "./RangeFilter";

interface FilterProps {
    filter: Filter;
    handleUpdateFilter: (filter: Filter) => void;
}

export const FilterComponent: React.FC<FilterProps> = ({ filter, handleUpdateFilter }) => {
    switch (filter.type) {
        case "range":
            return <RangeFilter filter={filter} handleUpdateFilter={handleUpdateFilter} />;
        case "date":
            return <DateFilter filter={filter} handleUpdateFilter={handleUpdateFilter} />;
        default:
            if (filter?.column?.type === "CLASSIFICATION") {
                return <ClassificationFilter filter={filter} handleUpdateFilter={handleUpdateFilter} />;
            }
            if (filter?.column?.id === "onboarding.status") {
                return <OnboardingFilter filter={filter} handleUpdateFilter={handleUpdateFilter} />;
            }
            return <IncludeExcludeFilter filter={filter} handleUpdateFilter={handleUpdateFilter} />;
    }
};
