import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    TextField,
    Typography,
} from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid-pro";

import { NACEOption, NACEOptions } from "../constants";

function getLabel(option: NACEOption | null) {
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb" || locale === "no" || locale === "nn") {
        return `${option.nace}: ${option.name_no}`;
    }
    return `${option.nace}: ${option.name_en}`;
}

const renderInput = (params: AutocompleteRenderInputParams) => {
    // Autofocus is needed here to focus on the input when the cell is edited
    // eslint-disable-next-line jsx-a11y/no-autofocus
    return <TextField {...params} size="small" variant="outlined" autoFocus />;
};

export function NaceEditField(
    params: GridRenderCellParams<GridValidRowModel, unknown, unknown, GridTreeNodeWithRender>
) {
    const { id, value, field, api } = params;

    const handleChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: NACEOption | null,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === "selectOption") {
            api.setEditCellValue({ id, field, value: value?.nace });
        } else if (reason === "clear") {
            api.setEditCellValue({ id, field, value: null });
        }
    };

    return (
        <Autocomplete
            autoHighlight
            options={NACEOptions}
            value={NACEOptions.find((option) => option.nace === value) ?? null}
            fullWidth
            getOptionLabel={(option) => `${getLabel(option)}`}
            renderInput={renderInput}
            onChange={handleChange}
            openOnFocus
            sx={{
                "& .MuiOutlinedInput-root": {
                    border: "none",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
            }}
        />
    );
}

export function NaceField(params: GridRenderCellParams<GridValidRowModel, string, unknown, GridTreeNodeWithRender>) {
    const selectedValue = NACEOptions.find((option) => option.nace === params.value);
    const label = selectedValue ? getLabel(selectedValue) : "";
    return <Typography variant="textSm">{label}</Typography>;
}
