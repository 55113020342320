import { track } from "@ignite-analytics/track";
import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { AppLayout } from "@/components";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";

import { UploadFileStep } from "./components/Steps/UploadFileStep";
import { ValidateFileStep } from "./components/Steps/ValidateFileStep";
import { SupplierUploadContextProvider, useSupplierUploadContext } from "./providers/SupplierUpload";

const UploadSuppliersContent: React.FC = () => {
    useSetBreadcrumbs("upload");

    const [{ duplicatesInFile, blankRowsInfo }, dispatch] = useSupplierUploadContext();
    const [activeStep, setActiveStep] = React.useState<"upload" | "validate">("upload");

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const onV2 = pathname.includes("/v2");

    const gotoNextStep = useCallback(() => {
        setActiveStep("validate");
    }, [setActiveStep]);

    const onDeleteFile = useCallback(() => {
        dispatch({ type: "SET_FILE", file: undefined });
        setActiveStep("upload");
        track("Supplier Table: Upload Suppliers - Delete file", {
            duplicatesInFile: duplicatesInFile?.duplicateValues.length,
            blankRows: blankRowsInfo?.indices.length,
        });
    }, [duplicatesInFile, blankRowsInfo, dispatch, setActiveStep]);

    const navigateToOverview = useCallback(() => {
        navigate(`${onV2 ? "/v2" : ""}/suppliers/overview`);
    }, [navigate, onV2]);

    return (
        <AppLayout>
            <Stack padding={2}>
                {activeStep === "upload" && (
                    <UploadFileStep gotoNextStep={gotoNextStep} navigateToOverview={navigateToOverview} />
                )}
                {activeStep === "validate" && (
                    <ValidateFileStep onDeleteFile={onDeleteFile} navigateToOverview={navigateToOverview} />
                )}
            </Stack>
        </AppLayout>
    );
};

export const UploadSuppliers: React.FC = () => {
    return (
        <SupplierUploadContextProvider>
            <UploadSuppliersContent />
        </SupplierUploadContextProvider>
    );
};
