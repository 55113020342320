import { ArrowLeft, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useTryToUploadSuppliers } from "../../hooks/useTryToUploadSuppliers";
import { useSupplierUploadContext } from "../../providers/SupplierUpload";
import { ColumnMappingModal } from "../ColumnMappingModal";
import { DataValidationErrors } from "../DataValidationErrors";
import { FilePreviewTable } from "../FilePreview";
import { RecommendedColumns } from "../RecommendedColumns";

interface Props {
    onDeleteFile: () => void;
    navigateToOverview: () => void;
}

export const ValidateFileStep: React.FC<Props> = ({ onDeleteFile, navigateToOverview }) => {
    const [
        {
            file,
            errors,
            warnings,
            fileInformation,
            filePreview,
            fileColumns,
            duplicatesInFile,
            blankRowsInfo,
            mapping,
            dataColumnIdToFieldNameMapping,
            includedUnmappedColumns,
            supplierFieldsByColumn,
            unmappedFileColumns,
            supplierNameIndex,
            supplierTableInfo,
            fileSubmittedRef,
        },
    ] = useSupplierUploadContext();

    const [tryToUploadSuppliers, { invalidUploadState, loading, error }] = useTryToUploadSuppliers();

    const [showMappingModal, setShowMappingModal] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const intl = useIntl();

    // NOTE: Only supporting one unique column for now
    const haveDesiredDuplicatesInfo =
        duplicatesInFile !== undefined &&
        supplierNameIndex !== undefined &&
        supplierNameIndex === duplicatesInFile.uniqueColumnIndicies[0];

    const waitingForDuplicatesResult = supplierNameIndex !== undefined && !haveDesiredDuplicatesInfo;
    const haveDuplicates = haveDesiredDuplicatesInfo && duplicatesInFile.duplicateValues.length > 0;

    const haveDesiredBlanksInfo = blankRowsInfo !== undefined && supplierNameIndex === blankRowsInfo.columnIndex;
    const waitingForBlanksResult = supplierNameIndex !== undefined && !haveDesiredBlanksInfo;
    const haveBlanks = haveDesiredBlanksInfo && blankRowsInfo.indices.length > 0;

    const canSubmit =
        file !== undefined &&
        fileInformation !== undefined &&
        supplierTableInfo !== undefined &&
        !hasSubmitted &&
        haveDesiredDuplicatesInfo &&
        !haveDuplicates &&
        haveDesiredBlanksInfo &&
        !haveBlanks;

    const submitUpload = () => {
        track("Supplier Table: Upload Suppliers - Complete");

        if (!canSubmit) {
            // Invalid submission
            // TODO: Inform the user
            return;
        }

        if (fileSubmittedRef.current) return; // Don't allow this to run more than once
        fileSubmittedRef.current = true;
        setHasSubmitted(true);
        setShowMappingModal(false);

        // Include all implicit mappings where the column name in the file matches a repo field name
        const dataColumnIdMappingWithRepoFieldMappings = fileColumns.reduce((acc, fileColumn) => {
            // Don't implicitly map columns that are already mapped
            if (Object.values(mapping).includes(fileColumn)) return acc;
            if (Object.values(dataColumnIdToFieldNameMapping).includes(fileColumn)) return acc;

            // Find the repo field that matches the file column name
            const field = supplierFieldsByColumn.find((field) => field.fieldName === fileColumn);
            if (field === undefined) return acc;

            return {
                ...acc,
                [field.dataColumnId]: fileColumn,
            };
        }, dataColumnIdToFieldNameMapping);

        tryToUploadSuppliers(
            file,
            fileInformation.id,
            mapping,
            dataColumnIdMappingWithRepoFieldMappings,
            includedUnmappedColumns,
            unmappedFileColumns,
            supplierTableInfo
        )
            .then(navigateToOverview)
            .catch((e) => {
                Sentry.captureException(e, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
                fileSubmittedRef.current = false;
                setHasSubmitted(false);
            });
    };

    return (
        <Stack gap={2}>
            <Stack direction="row" alignItems="start">
                <Stack direction="row" gap={1} onClick={onDeleteFile} sx={{ cursor: "pointer" }}>
                    <ArrowLeft />
                    <Typography variant="displaySm">
                        <FormattedMessage defaultMessage="Back" description="Back" />
                    </Typography>
                </Stack>
            </Stack>

            {invalidUploadState && (
                <Alert
                    title={intl.formatMessage({
                        defaultMessage: "Unsupported data configuration",
                        description: "Unsupported data configuration",
                    })}
                    severity="error"
                >
                    <FormattedMessage
                        defaultMessage="Unsupported data configuration. Please contact your CSM."
                        description="Unsupported data configuration. Please contact your CSM."
                    />
                </Alert>
            )}
            <DataValidationErrors />
            {error !== undefined && (
                <Alert title={error.message} severity="error">
                    {error.message}
                </Alert>
            )}
            {errors && (
                <Alert title={errors} severity="error">
                    {errors}
                </Alert>
            )}
            {warnings && (
                <Alert title={warnings} severity="warning">
                    {warnings}
                </Alert>
            )}

            <Stack direction="row" spacing={2}>
                <Typography variant="textMd">
                    <FormattedMessage
                        defaultMessage="Preview: {filename}"
                        description="Preview: {filename}"
                        values={{ filename: file?.name ?? "" }}
                    />
                </Typography>
                <Trash sx={{ cursor: "pointer" }} onClick={onDeleteFile} />
            </Stack>
            <FilePreviewTable filePreview={filePreview} />

            {(loading || waitingForDuplicatesResult || waitingForBlanksResult) && (
                <Stack alignItems="center" gap={2}>
                    {waitingForBlanksResult && (
                        <Typography variant="displayXs">
                            <FormattedMessage
                                defaultMessage="Checking for missing supplier names in file"
                                description="Checking for missing supplier names in file"
                            />
                        </Typography>
                    )}
                    {waitingForDuplicatesResult && (
                        <Typography variant="displayXs">
                            <FormattedMessage
                                defaultMessage="Checking for duplicates in file"
                                description="Checking for duplicates in file"
                            />
                        </Typography>
                    )}
                    {loading && (
                        <Typography variant="displayXs">
                            <FormattedMessage defaultMessage="Processing file" description="Processing file" />
                        </Typography>
                    )}
                    <CircularProgress sx={{ fontSize: "80px" }} />
                </Stack>
            )}
            <RecommendedColumns />

            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button variant="text" onClick={onDeleteFile}>
                    <Typography>
                        <FormattedMessage defaultMessage="Cancel" description="Cancel" />
                    </Typography>
                </Button>
                <Button
                    onClick={() => {
                        setShowMappingModal(true);
                        track("Supplier Table: Upload Suppliers - Show mapping modal");
                    }}
                    disabled={filePreview === undefined || invalidUploadState || hasSubmitted}
                >
                    <Typography>
                        <FormattedMessage defaultMessage="Next" description="Next" />
                    </Typography>
                </Button>
            </Stack>

            <ColumnMappingModal
                open={showMappingModal}
                onMapComplete={submitUpload}
                onClose={() => setShowMappingModal(false)}
                canSubmit={canSubmit}
            />
        </Stack>
    );
};
