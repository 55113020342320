import { useMutation } from "@apollo/client";
import { Alert, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import PositiveCheckmark from "@/assets/PositiveCheckmark.svg?react";
import { graphql } from "@/gql";
import { track } from "@/lib/track";

interface NoSpendDataCardProps {
    onDiscard: () => void;
}

const requestNoSpendData = graphql(`
    mutation NoSpendDataCard_RequestSpendSetup {
        requestSpendSetup {
            ok
        }
    }
`);

export const NoSpendDataCard: React.FC<NoSpendDataCardProps> = ({ onDiscard }) => {
    const [requestSpendSetup] = useMutation(requestNoSpendData);
    const [open, setOpen] = React.useState(false);
    const handleRequestSpendSetup = async () => {
        track("Social Risk Domain Page: Request Spend Setup");
        setOpen(true);
        await requestSpendSetup();
    };
    const handleModalClose = () => {
        onDiscard();
        setOpen(false);
    };
    return (
        <Alert severity="warning" onClose={() => onDiscard()}>
            <Stack spacing={1}>
                <Typography variant="textSm" fontWeight="500">
                    <FormattedMessage
                        defaultMessage="Upload your spend data"
                        description="No spend date alert header"
                    />
                </Typography>
                <Typography variant="textSm">
                    <FormattedMessage
                        defaultMessage="We notice that you are missing some configurations, which might make you miss out on insights, opportunities and filters."
                        description="No spend date alert body"
                    />
                </Typography>

                <Stack direction="row">
                    <Button
                        size="xsmall"
                        variant="outlined"
                        color="warning"
                        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
                        onClick={handleRequestSpendSetup}
                    >
                        <FormattedMessage
                            defaultMessage="Request Spend Upload"
                            description="No spend date alert button"
                        />
                    </Button>
                </Stack>
            </Stack>
            <Dialog open={open} onClose={handleModalClose} maxWidth="xs" fullWidth>
                <DialogContent>
                    <Stack alignItems="center" justifyContent="center">
                        <PositiveCheckmark />
                    </Stack>
                    <Stack spacing={1} paddingBottom={4} paddingTop={2} justifyContent="center" alignItems="center">
                        <Typography variant="textLg">
                            <FormattedMessage
                                defaultMessage="Our team has been notified"
                                description="Notified team message"
                            />
                        </Typography>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="We will reach out to you soon."
                                description="Notified team info message"
                            />
                        </Typography>
                    </Stack>
                    <Stack>
                        <Button onClick={handleModalClose}>
                            <FormattedMessage
                                defaultMessage="Done"
                                description="No spend date alert dialog close button"
                            />
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Alert>
    );
};
