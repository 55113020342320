import { useQuery } from "@apollo/client";
import { ArrowRight } from "@ignite-analytics/icons";
import { formatValue } from "@ignite-analytics/locale";
import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import WarningIcon from "@/assets/WarningIcon.svg?react";
import { graphql } from "@/gql";
import { GetRiskColumnConfigResponse } from "@/gql/graphql";

import { FilterInput } from "../../../InlineFilter/types";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

interface SupplierWithoutSocialRiskProps {
    totalNumberOfSuppliers?: number;
    riskColumnConfig?: Omit<GetRiskColumnConfigResponse, "id">;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}

const getSuppliersWithoutSocialRisk = graphql(`
    query SuppliersWithoutSocialRisk_GetSuppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
            }
            total
        }
    }
`);

export const SuppliersWithSocialRisk: React.FC<SupplierWithoutSocialRiskProps> = ({
    totalNumberOfSuppliers,
    riskColumnConfig,
    onNavigate,
}) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [percentageSuppliersWithSocialRisk, setPercentageSuppliersWithSocialRisk] = useState<number | undefined>(
        undefined
    );
    const { loading: dataLoading } = useQuery(getSuppliersWithoutSocialRisk, {
        variables: {
            input: {
                pageRange: 1,
                pageIndex: 0,
                filterJson: `[{"type":"include","column_id":"${riskColumnConfig?.socialRiskColumn}","include_exclude_values":[],"min_range":null,"max_range":null,"min_date":null,"max_date":null,"include_blanks":true}]`,
                nameSearch: "",
            },
        },
        onCompleted: (data) => {
            totalNumberOfSuppliers &&
                setPercentageSuppliersWithSocialRisk((1 - data.getSuppliers.total / totalNumberOfSuppliers) * 100);
        },
        skip: !riskColumnConfig || !totalNumberOfSuppliers,
    });

    const handleOnClick = () => {
        if (!riskColumnConfig) return;
        const filter: FilterInput[] = [
            {
                type: "include",
                column_id: riskColumnConfig.socialRiskColumn,
                include_exclude_values: [],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: true,
            },
        ];
        onNavigate(filter, Object.values(riskColumnConfig), "SuppliersWithSocialRisk");
    };

    const errorState = !percentageSuppliersWithSocialRisk && (
        <Stack justifyContent="space-between" height="100%" spacing={1.5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="textSm" color="text.text-helper">
                    <FormattedMessage
                        defaultMessage="Suppliers with social risk score"
                        description="Suppliers with social risk score header"
                    />
                </Typography>
                <WarningIcon />
            </Stack>
            {!riskColumnConfig ? (
                <Stack justifyContent="space-between" height="100%">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Please configure the social risk column in the settings"
                            description="Please configure social risk column"
                        />
                    </Typography>
                    <Stack direction="row">
                        <Button
                            color="secondary"
                            onClick={() => navigate("v2/suppliers/overview")}
                            endIcon={<ArrowRight fontSize="small" />}
                        >
                            <FormattedMessage
                                defaultMessage="Run social risk"
                                description="Button to navigate to suppliers overview to run social risk"
                            />
                        </Button>
                    </Stack>
                </Stack>
            ) : (
                <Stack justifyContent="space-between" height="100%">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="We need to know which data you want us to use to craft this insight."
                            description="Please configure social risk column"
                        />
                    </Typography>
                    <Stack direction="row">
                        <Button
                            color="secondary"
                            onClick={() => navigate("v2/suppliers/overview")}
                            endIcon={<ArrowRight fontSize="small" />}
                            size="xsmall"
                        >
                            <FormattedMessage
                                defaultMessage="Add data"
                                description="Button to navigate to suppliers overview to run social risk"
                            />
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );

    return (
        <InsightBox loading={dataLoading}>
            {percentageSuppliersWithSocialRisk ? (
                <Stack justifyContent="space-between" height="100%">
                    <Stack spacing={1.5}>
                        <Typography variant="textSm" color="text.text-helper">
                            <FormattedMessage
                                defaultMessage="Suppliers with social risk score"
                                description="Suppliers with social risk score header"
                            />
                        </Typography>

                        <Stack spacing={1}>
                            <Typography variant="displayMd" fontWeight="medium">
                                {`${formatValue(percentageSuppliersWithSocialRisk, 2, undefined, true)}%`}
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={percentageSuppliersWithSocialRisk}
                                color={
                                    percentageSuppliersWithSocialRisk < 33
                                        ? "error"
                                        : percentageSuppliersWithSocialRisk < 66
                                          ? "warning"
                                          : "success"
                                }
                                sx={{
                                    borderRadius: 4,
                                    height: 6,
                                    backgroundColor: (theme) => theme.palette.background.variant,
                                }}
                            />
                            <Typography variant="textSm" color="text.text-helper" paddingBottom={1}>
                                <FormattedMessage
                                    defaultMessage="Add missing data to increase data coverage"
                                    description="Add missing data to increase data coverage helper text"
                                />
                            </Typography>
                        </Stack>
                    </Stack>
                    <ViewButton
                        buttonText={formatMessage({
                            defaultMessage: "Add data",
                            description: "Add data button",
                        })}
                        onClick={handleOnClick}
                    />
                </Stack>
            ) : (
                errorState
            )}
        </InsightBox>
    );
};
