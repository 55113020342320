import { styled } from "@mui/material/styles";
import React from "react";

import { Chip, colors as chipColors } from "../../Chip";

interface ClassificationFieldProps {
    ids: string[];
    valueById?: Map<string, string>;
}

export const TableClassificationField: React.FC<ClassificationFieldProps> = ({ ids, valueById }) => {
    const numGroups = ids?.length;
    if (numGroups === 0 || valueById === undefined || valueById?.size === 0) {
        return <></>;
    }
    return (
        <ClassificationCell>
            {ids?.map((id: string, i: number) => {
                return (
                    <Chip key={id} color={chipColors[i % numGroups]}>
                        {valueById?.get(id)}
                    </Chip>
                );
            })}
        </ClassificationCell>
    );
};

const ClassificationCell = styled("div")(() => ({
    gap: 10,
    padding: "0 10px",
    maxWidth: "100%",
    overflow: "hidden",
    flexDirection: "row",
    display: "flex",
}));
