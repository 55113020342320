import { Plus } from "@ignite-analytics/icons";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "../../../../../components/NoPermissionTooltip";
import { ADD_COLUMN_ID } from "../../../../constants";

import { ColumnDefinition } from "./columnDefinition";

export function getActionColumn(onAddColumn: () => void, isEditor: boolean): ColumnDefinition {
    return {
        field: ADD_COLUMN_ID,
        headerName: "",
        width: 50,
        pinnable: true,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        hideable: false,
        resizable: false,
        renderHeader: () => {
            const cellItem = (
                <GridActionsCellItem
                    label="Add"
                    icon={<Plus color={isEditor ? "primary" : "secondary"} fontSize="small" />}
                    onClick={() => onAddColumn()}
                    disabled={!isEditor}
                    sx={{ paddingRight: 4 }}
                />
            );
            if (isEditor) {
                return (
                    <Tooltip title={<FormattedMessage defaultMessage="Add a Column" description="Add column title" />}>
                        {cellItem}
                    </Tooltip>
                );
            } else {
                return <NoPermissionTooltip hasPermission={isEditor}>{cellItem}</NoPermissionTooltip>;
            }
        },
        getActions: () => [],
    };
}
