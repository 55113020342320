import { useMutation } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { ArrowTopTray, Plus } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";

import { TableActions } from "../constants";
import { NoPermissionTooltip } from "../NoPermissionTooltip";

const EnsureValidSupplierUploadStateMutation = graphql(`
    mutation ActionBar_EnsureValidSupplierUploadState($input: EnsureValidSupplierUploadStateInput!) {
        ensureValidSupplierUploadState(input: $input) {
            isValid
        }
    }
`);

interface SupplierTableHeaderProps {
    isOnboarding: boolean;
    isEditor: boolean;
    setOpenModal: (action: TableActions | false) => void;
    hasSelectedSuppliers: boolean;
}

export const SupplierTableHeader: React.FC<SupplierTableHeaderProps> = ({
    isOnboarding,
    isEditor,
    setOpenModal,
    hasSelectedSuppliers,
}) => {
    const [isValidUploadState, setIsValidUploadState] = useState(false);

    const navigate = useNavigate();

    const newSupplierUploadURL = "/suppliers/overview/upload/";

    const supplierOnboardingEnabled = useFeatureToggle("supplier-onboarding", false);
    const newSupplierUploadEnabled = useFeatureToggle("new-supplier-upload", false);
    const newReOnboardingFlow = useFeatureToggle("onboard-existing", false);

    const [ensureValidSupplierUploadState] = useMutation(EnsureValidSupplierUploadStateMutation, {
        variables: { input: { checkOnly: true } },
        onCompleted: (data) => {
            setIsValidUploadState(data?.ensureValidSupplierUploadState.isValid ?? false);
        },
    });

    useEffect(() => {
        ensureValidSupplierUploadState();
    }, [ensureValidSupplierUploadState]);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            paddingRight={4.5}
            paddingTop={3}
        >
            <Typography
                variant="displayXs"
                fontWeight={(theme) => theme.typography.fontWeightBold}
                paddingX={3}
                paddingTop={0.5}
            >
                {isOnboarding ? (
                    <FormattedMessage defaultMessage="Onboarding" description="Onboarding overview page header" />
                ) : (
                    <FormattedMessage defaultMessage="Supplier list" description="Supplier list page header" />
                )}
            </Typography>
            <Stack direction="row" spacing={1}>
                {newSupplierUploadEnabled && isValidUploadState && (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Tooltip
                            title={
                                <FormattedMessage
                                    defaultMessage="Upload suppliers"
                                    description="Upload suppliers button"
                                />
                            }
                            placement="top"
                        >
                            <IconButton
                                color="secondary"
                                size="small"
                                disabled={!isEditor}
                                onClick={(e) => {
                                    // Navigate to the upload page using react router
                                    // NOTE: We still have an href on this button to get link preview and middle click support,
                                    //       but we prefer `navigate` as it does not refresh the page.
                                    track("Supplier Table: Upload Suppliers - Start");
                                    navigate(newSupplierUploadURL);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                href={newSupplierUploadURL}
                            >
                                <ArrowTopTray fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </NoPermissionTooltip>
                )}
                {!isOnboarding && newReOnboardingFlow && (
                    <Button
                        onClick={() => {
                            track("Supplier Table: Clicked Onboard Existing Suppliers");
                            setOpenModal("onboard-existing-suppliers");
                        }}
                        size="small"
                        disabled={!isEditor || !hasSelectedSuppliers}
                        color="secondary"
                    >
                        <FormattedMessage
                            defaultMessage="Onboard existing suppliers"
                            description="Onboard existing suppliers button"
                        />
                    </Button>
                )}
                {isOnboarding && (
                    <Button
                        onClick={() => {
                            track("Onboarding: Clicked Onboard Existing Suppliers");
                            navigate("onboarding/existing");
                        }}
                        size="small"
                        disabled={!isEditor}
                        color="secondary"
                    >
                        <FormattedMessage
                            defaultMessage="Onboard existing suppliers"
                            description="Onboard existing suppliers button"
                        />
                    </Button>
                )}
                {supplierOnboardingEnabled ? (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button
                            startIcon={<Plus fontSize="small" />}
                            onClick={() => {
                                track("Supplier Table: Clicked Start Onboarding");
                                navigate("onboarding/new");
                            }}
                            size="small"
                            disabled={!isEditor}
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Onboard supplier" description="Onboard supplier button" />
                        </Button>
                    </NoPermissionTooltip>
                ) : (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button
                            startIcon={<Plus fontSize="small" />}
                            onClick={() => setOpenModal("add-supplier")}
                            size="small"
                            disabled={!isEditor}
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Add supplier" description="Add supplier button" />
                        </Button>
                    </NoPermissionTooltip>
                )}
            </Stack>
        </Stack>
    );
};
