import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { HighRiskSpendShare } from "./KeyInsightsDemoComponents/HighRiskSpendShare";
import { ResponseRateQuestionnaires } from "./KeyInsightsDemoComponents/ResponseRateQuestionnaires";
import { SuppliersWithSocialRisk } from "./KeyInsightsDemoComponents/SuppliersWithoutSocialRisk";
import { TotalHighRiskSuppliers } from "./KeyInsightsDemoComponents/TotalHighRiskSuppliersDemo";

export const KeyInsightsDemo: React.FC = () => {
    return (
        <Stack spacing={2}>
            <Typography variant="textLg" component="h2" fontWeight="medium">
                <FormattedMessage defaultMessage="Key insights" description="Key insights sub header" />
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={2}>
                <Grid xs={12} md={6}>
                    <TotalHighRiskSuppliers />
                </Grid>
                <Grid xs={12} md={6}>
                    <HighRiskSpendShare />
                </Grid>
                <Grid xs={12} md={6}>
                    <SuppliersWithSocialRisk />
                </Grid>
                <Grid xs={12} md={6}>
                    <ResponseRateQuestionnaires />
                </Grid>
            </Grid>
        </Stack>
    );
};
