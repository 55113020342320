import { ArrowLeft } from "@ignite-analytics/icons";
import { Alert, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useSupplierUploadContext } from "../../providers/SupplierUpload";
import { ExcelFileDropZone } from "../ExcelFileDropZone";
import { RecommendedColumns } from "../RecommendedColumns";

interface Props {
    gotoNextStep: () => void;
    navigateToOverview: () => void;
}

export const UploadFileStep: React.FC<Props> = ({ gotoNextStep, navigateToOverview }) => {
    const [{ uploadComplete, uploadProgress, errors }, dispatch] = useSupplierUploadContext();

    useEffect(() => {
        if (!uploadComplete) return;
        // Wait for the animation to finish before going to the next step
        const timer = setTimeout(gotoNextStep, 1600);
        return () => clearTimeout(timer);
    }, [uploadComplete, gotoNextStep]);

    const onFileChange = useCallback(
        (file?: File) => {
            dispatch({ type: "SET_FILE", file });
        },
        [dispatch]
    );

    return (
        <Stack gap={2}>
            <Stack direction="row" alignItems="start">
                <Stack direction="row" gap={1} onClick={navigateToOverview} sx={{ cursor: "pointer" }}>
                    <ArrowLeft />
                    <Typography variant="displaySm">
                        <FormattedMessage defaultMessage="Back" description="Back" />
                    </Typography>
                </Stack>
            </Stack>
            <ExcelFileDropZone
                onFileChange={onFileChange}
                loading={uploadProgress != 0}
                uploadComplete={uploadComplete}
            />
            {uploadProgress !== 0 && <LinearProgress variant="determinate" value={uploadProgress} />}
            {errors && (
                <Alert title={errors} severity="error">
                    {errors}
                </Alert>
            )}
            <RecommendedColumns />
        </Stack>
    );
};
