import { Card, CardContent, Skeleton, Stack } from "@mui/material";

interface BoxItemProps {
    children?: React.ReactNode;
    loading?: boolean;
}

export const InsightBox: React.FC<BoxItemProps> = ({ children, loading }) => {
    return (
        <Card sx={{ minHeight: "208px", height: "100%", width: "100%" }} component={Stack}>
            <CardContent component={Stack} sx={{ flexGrow: 1 }}>
                {loading ? (
                    <Stack justifyContent="center">
                        <Skeleton height="40px" width="100%" />
                        <Skeleton height="120px" width="100%" />
                    </Stack>
                ) : (
                    children
                )}
            </CardContent>
        </Card>
    );
};
