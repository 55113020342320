import { Pen } from "@ignite-analytics/icons";
import { Box, IconButton, MenuItem, Popper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { ContactCell_ContactFragment } from "@/gql/graphql";

export type ContactCellDropdownProps = {
    readonly contacts: ContactCell_ContactFragment[];
    readonly anchor: HTMLElement | null;
    readonly onEditContact: (contact: ContactCell_ContactFragment) => void;
    readonly onAddContact: () => void;
};
const ContactCellDropdown = ({ contacts, anchor, onEditContact, onAddContact }: ContactCellDropdownProps) => {
    return (
        <Popper
            open={true}
            anchorEl={anchor}
            disablePortal={true}
            placement="bottom-start"
            sx={{
                width: anchor?.getBoundingClientRect().width,
            }}
        >
            <Box
                sx={{
                    border: 0.5,
                    borderColor: (theme) => theme.palette.grey[200],
                    bgcolor: "background.paper",
                    boxShadow: 4,
                }}
            >
                {contacts.map((contact) => {
                    return (
                        <Stack
                            height="36px"
                            key={contact.id}
                            direction="row"
                            padding="0px 28px 0px 10px"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                "&:hover": {
                                    "& > button": {
                                        display: "block",
                                    },
                                },
                            }}
                        >
                            <Typography variant="textSm">{contact.email}</Typography>
                            <IconButton
                                size="small"
                                sx={{
                                    display: "none",
                                }}
                                onClick={() => onEditContact(contact)}
                            >
                                <Pen fontSize="inherit" />
                            </IconButton>
                        </Stack>
                    );
                })}
                <MenuItem key="new-contact-button" onClick={onAddContact} sx={{ paddingLeft: "10px" }}>
                    <Typography variant="textSm" color="primary">
                        <FormattedMessage defaultMessage="Add contact" description="Add contact cell" />
                    </Typography>
                </MenuItem>
            </Box>
        </Popper>
    );
};

export default ContactCellDropdown;
