import { useMutation } from "@apollo/client";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    SupplierContacts,
    UpsertContactModal_ContactFragment,
    UpsertContactModal_UpsertContactsDocument,
} from "@/gql/graphql";
import { useAlert } from "@/providers";

type UpsertContactModalProps = {
    open: boolean;
    onClose: () => void;
    contact?: UpsertContactModal_ContactFragment;
    supplierId: string;
};

graphql(`
    fragment UpsertContactModal_Contact on Contact {
        id
        email
        firstName
        lastName
        phone {
            number
        }
        position
        companyId
    }

    mutation UpsertContactModal_UpsertContacts($input: UpsertContactsInput!) {
        upsertContacts(input: $input) {
            result {
                ...UpsertContactModal_Contact
            }
        }
    }
`);

const UpsertContactModal = ({ open, onClose, contact, supplierId }: UpsertContactModalProps) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const [error, setError] = useState(false);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [newContact, setNewContact] = useState<{
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
        position: string;
    }>({
        email: contact?.email ?? "",
        firstName: contact?.firstName ?? "",
        lastName: contact?.lastName ?? "",
        phone: contact?.phone?.number ?? "",
        position: contact?.position ?? "",
    });
    useEffect(() => {
        emailInputRef?.current?.focus();
    }, []);

    const [upsertContacts, { loading }] = useMutation(UpsertContactModal_UpsertContactsDocument, {
        variables: {
            input: {
                contacts: [
                    {
                        id: contact?.id ?? "",
                        companyId: supplierId,
                        position: newContact.position,
                        firstName: newContact.firstName,
                        lastName: newContact.lastName,
                        email: newContact.email,
                        phone: {
                            number: newContact.phone,
                        },
                    },
                ],
            },
        },
        onCompleted: () => {
            if (contact) {
                alertUser({
                    severity: "success",
                    value: formatMessage({
                        defaultMessage: "Contact updated",
                        description: "Contact updated alert message",
                    }),
                });
            } else {
                alertUser({
                    severity: "success",
                    value: formatMessage({
                        defaultMessage: "Contact added",
                        description: "Contact added alert message",
                    }),
                });
            }
            onClose();
        },
        update: (cache, data) => {
            const addedContact = data?.data?.upsertContacts.result[0];
            if (!addedContact) return;
            cache.modify({
                fields: {
                    getContactsBySupplierIds: (exisitingSupplierContacts) => {
                        const supplierContacts = exisitingSupplierContacts?.result.find((c: SupplierContacts) => {
                            return c.supplierId === addedContact.companyId;
                        })?.contacts;
                        return {
                            ...exisitingSupplierContacts,
                            result: [
                                supplierContacts
                                    ? [...supplierContacts, addedContact]
                                    : {
                                          supplierId: addedContact.companyId,
                                          contacts: addedContact,
                                          __typename: "SupplierContacts",
                                      },
                            ],
                        };
                    },
                },
            });
        },

        onError: () => {
            alertUser({
                severity: "error",
                value: formatMessage({
                    defaultMessage: "Error updating contacts",
                    description: "Error updating contacts alert message",
                }),
            });
        },
    });

    const handleFormChange = (event: { target: { id: string; value: React.SetStateAction<string> } }) => {
        if (error && event.target.id == "email" && event.target.value != "") {
            setError(false);
        }
        setNewContact({ ...newContact, [event.target.id]: event.target.value });
    };

    const handleSubmit = () => {
        const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
        if (!emailRegex.test(newContact.email ?? "")) {
            setError(true);
            return false;
        }
        upsertContacts();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {contact
                    ? formatMessage({ defaultMessage: "Edit contact", description: "Edit contact modal title" })
                    : formatMessage({ defaultMessage: "Add contact", description: "Add contact modal title" })}
            </DialogTitle>
            {loading && open ? (
                <Stack direction="row" alignItems="center" justifyContent="center" height="200px" width="100%">
                    <CircularProgress />
                </Stack>
            ) : (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextField
                            ref={emailInputRef}
                            margin="dense"
                            id="email"
                            label={formatMessage({ defaultMessage: "Email" })}
                            type="email"
                            fullWidth
                            value={newContact.email}
                            onChange={handleFormChange}
                            error={error}
                            required
                            helperText={error ? formatMessage({ defaultMessage: "Invalid email" }) : ""}
                        />
                        <TextField
                            margin="dense"
                            id="firstName"
                            label={formatMessage({ defaultMessage: "First Name" })}
                            type="text"
                            fullWidth
                            value={newContact.firstName}
                            onChange={handleFormChange}
                        />

                        <TextField
                            margin="dense"
                            id="lastName"
                            label={formatMessage({ defaultMessage: "Last Name" })}
                            type="text"
                            fullWidth
                            value={newContact.lastName}
                            onChange={handleFormChange}
                        />
                        <TextField
                            margin="dense"
                            id="phone"
                            label={formatMessage({ defaultMessage: "Phone" })}
                            type="text"
                            fullWidth
                            value={newContact.phone}
                            onChange={handleFormChange}
                        />
                        <TextField
                            margin="dense"
                            id="position"
                            label={formatMessage({ defaultMessage: "Position" })}
                            type="text"
                            fullWidth
                            value={newContact.position}
                            onChange={handleFormChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()} color="secondary" variant="text">
                            {formatMessage({ defaultMessage: "Cancel" })}
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            {contact
                                ? formatMessage({ defaultMessage: "Save" })
                                : formatMessage({ defaultMessage: "Add" })}
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

export default UpsertContactModal;
