import { Stack, Typography, DialogContent, DialogActions, Button, Dialog } from "@mui/material";
import { FormattedMessage } from "react-intl";

import timeImage from "../SocialRiskEvaluation/time.svg";

interface Props {
    handleClose: () => void;
    open: boolean;
}

export const SpendColumnConfirmation: React.FC<Props> = ({ handleClose, open }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Stack alignItems="center" spacing={2}>
                    <img src={timeImage} width="100px" alt="Time icon" crossOrigin="anonymous" />
                    <Typography variant="displayXs">
                        <FormattedMessage
                            defaultMessage="Processing the spend column"
                            description="Spend column confirmation title"
                        />
                    </Typography>
                    <Typography variant="textSm" justifyContent="center">
                        <FormattedMessage
                            defaultMessage="The spend column has been added, but it may take some time to finish the calculations. In the meantime, this column will be empty. You can close this window and continue working in Ignite. Come back later and check if it is completed."
                            description="Spend column confirmation description"
                        />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleClose();
                    }}
                    size="medium"
                    color="primary"
                >
                    <FormattedMessage defaultMessage="Close" description="Close button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SpendColumnConfirmation;
