import { X } from "@ignite-analytics/icons";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

type Props = {
    close: () => void;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    newCampaign: boolean;
    setNewCampaign: React.Dispatch<React.SetStateAction<boolean>>;
    noExistingCampaigns: boolean;
};

export const InviteToNewOrExisting: React.FC<Props> = ({
    close,
    setStep,
    newCampaign,
    setNewCampaign,
    noExistingCampaigns,
}) => {
    return (
        <>
            <Stack spacing={2}>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <FormattedMessage defaultMessage="Invite to campaign" description="Invite to campaign title" />
                        <IconButton onClick={close}>
                            <X />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <RadioGroup
                        value={newCampaign === true ? "new" : "existing"}
                        onChange={(event) => {
                            setNewCampaign(event.target.value === "new");
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label={
                                    <Stack>
                                        <Typography variant="textSm" fontWeight={500}>
                                            <FormattedMessage
                                                defaultMessage="Create new campaign"
                                                description="Invite modal Create new option"
                                            />
                                        </Typography>
                                        <Typography variant="textSm" color="text.text-helper">
                                            <FormattedMessage
                                                defaultMessage="Start by creating a new campaign"
                                                description="Campaign invitation modal choice 1 helper"
                                            />
                                        </Typography>
                                    </Stack>
                                }
                                sx={{ alignItems: "flex-start" }}
                            />
                            <FormControlLabel
                                value="existing"
                                control={<Radio disabled={noExistingCampaigns} size="small" />}
                                label={
                                    <Stack>
                                        <Typography variant="textSm" fontWeight={500}>
                                            <FormattedMessage
                                                defaultMessage="Add to an existing campaign"
                                                description="Invite modal Add to existing option"
                                            />
                                        </Typography>
                                        <Typography variant="textSm" color="text.text-helper">
                                            <FormattedMessage
                                                defaultMessage="Start by adding supplier choices to an existing campaign"
                                                description="Campaign invitation modal choice 2 helper"
                                            />
                                        </Typography>
                                    </Stack>
                                }
                                sx={{ alignItems: "flex-start" }}
                            />
                        </Stack>
                    </RadioGroup>
                </DialogContent>
            </Stack>

            <DialogActions>
                <Button
                    variant="text"
                    onClick={() => {
                        close();
                    }}
                    size="medium"
                    color="secondary"
                >
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setStep(1);
                    }}
                    size="medium"
                    color="primary"
                >
                    <FormattedMessage defaultMessage="Next" description="Next button" />
                </Button>
            </DialogActions>
        </>
    );
};
