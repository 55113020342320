import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { RenameViewModal_SupplierTableConfigFragment } from "@/gql/graphql";

graphql(`
    fragment RenameViewModal_SupplierTableConfig on SupplierTableConfig {
        id
        displayName
        state
    }
`);

interface RenameViewModalProps {
    onClose: (newName?: string) => void;
    view: RenameViewModal_SupplierTableConfigFragment;
}

export const RenameViewModal: React.FC<RenameViewModalProps> = ({ onClose, view }) => {
    const [newName, setNewName] = useState(view.displayName);
    const [error, setError] = useState(false);
    const { formatMessage } = useIntl();
    const renameView = () => {
        if (!newName) {
            setError(true);
            return;
        }
        setError(false);
        onClose(newName);
    };
    return (
        <Dialog open={true} onClose={() => onClose()} maxWidth="xs" fullWidth>
            <DialogTitle>
                <FormattedMessage defaultMessage="Rename view" description="Rename view modal title" />
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Please enter a new name for the view"
                            description="Rename view desc "
                        />
                    </Typography>
                    <TextField
                        value={newName}
                        onChange={(e) => {
                            setNewName(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key !== "Escape") e.stopPropagation();
                        }}
                        error={error}
                        helperText={error && formatMessage({ defaultMessage: "Name cannot be empty" })}
                        variant="outlined"
                        fullWidth
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} variant="text" color="secondary">
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button onClick={renameView} variant="contained">
                    <FormattedMessage defaultMessage="Rename" description="Rename button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
