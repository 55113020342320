import { GridApi, GridCallbackDetails, GridInitialState, GridPinnedColumns } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import { ColumnType, TableConfigsMenuButton_SupplierTableConfigFragment } from "@/gql/graphql";

import { ADD_COLUMN_ID, CONTACT_COLUMN_ID } from "../constants";
import { FilterInput } from "../InlineFilter/types";

import {
    COUNTRY_COLUMN_ID,
    ENRICHMENT_ID,
    NAME_COLUMN_ID,
    NORMALIZED_INTO_ID,
    REG_NR_COLUMN_ID,
    SELECT_SUPPLIER_COLUMN_ID,
} from "./constants";

export type StoredTableState = Omit<GridInitialStatePro, "filter"> & {
    readonly filter: FilterInput[];
};

export const defaultPinnedColumns: GridPinnedColumns = {
    left: [SELECT_SUPPLIER_COLUMN_ID, NAME_COLUMN_ID],
    right: [ADD_COLUMN_ID],
};

export const defaultInitialState: GridInitialState = {
    pinnedColumns: defaultPinnedColumns,
};

const STORED_SIDEBAR_OPEN_STATE_POSTFIX = "-supplierTableSidebarOpenState";
const STORED_LAST_USED_TABLE_VIEW_ID_POSTFIX = "-lastUsedTableViewId";
const STORED_SOCIAL_RISK_VIEW = "-socialRiskView";

export function setSidebarOpenState(isOpen: boolean) {
    const tenant = localStorage.getItem("tenant");
    if (!tenant) return;
    localStorage.setItem(`${tenant}${STORED_SIDEBAR_OPEN_STATE_POSTFIX}`, JSON.stringify(isOpen));
}

export function getSidebarOpenState() {
    const tenant = localStorage.getItem("tenant");
    if (!tenant) return;
    const state = localStorage.getItem(`${tenant}${STORED_SIDEBAR_OPEN_STATE_POSTFIX}`);
    if (!state) {
        return false;
    }
    return JSON.parse(state);
}

export function setLastUsedTableViewId(id: string) {
    const tenant = localStorage.getItem("tenant");
    if (!tenant) return;
    if (!id?.length) return;
    localStorage.setItem(`${tenant}${STORED_LAST_USED_TABLE_VIEW_ID_POSTFIX}`, id);
}

export function getLastUsedTableViewId() {
    const tenant = localStorage.getItem("tenant");
    if (!tenant) return null;
    const id = localStorage.getItem(`${tenant}${STORED_LAST_USED_TABLE_VIEW_ID_POSTFIX}`);
    return id ?? null;
}

export function getStoredSocialRiskState(): StoredTableState | undefined {
    const tenant = localStorage.getItem("tenant");
    if (!tenant) return;
    const state = localStorage.getItem(`${tenant}${STORED_SOCIAL_RISK_VIEW}`);
    if (state) {
        try {
            const parsedState: StoredTableState = JSON.parse(state);
            if (parsedState) {
                // We dont want the menu to be open when the table is loaded
                // so we just set it to be initial closed
                parsedState.preferencePanel = {
                    open: false,
                };
                return parsedState;
            }
        } catch (error) {
            return undefined;
        }
    }
    return undefined;
}

export function isGridProCallbackDetails(
    details: GridCallbackDetails
): details is GridCallbackDetails & { api: GridApi } {
    return "api" in details;
}

export const setFixedColumnsPinned = (pinnedColumns: GridPinnedColumns): GridPinnedColumns => {
    return {
        left: [
            SELECT_SUPPLIER_COLUMN_ID,
            ...(pinnedColumns?.left?.filter((id) => id !== SELECT_SUPPLIER_COLUMN_ID) ?? []),
        ],
        right: [...(pinnedColumns?.right?.filter((id) => id !== ADD_COLUMN_ID) ?? []), ADD_COLUMN_ID],
    };
};

export function isColumnDeletable(columnId: string) {
    return (
        columnId !== NAME_COLUMN_ID &&
        columnId !== REG_NR_COLUMN_ID &&
        columnId !== COUNTRY_COLUMN_ID &&
        columnId !== NORMALIZED_INTO_ID &&
        columnId !== ENRICHMENT_ID &&
        columnId !== CONTACT_COLUMN_ID
    );
}

export function isRiskColumnType(columnType: ColumnType) {
    return ["RISK", "NACE"].includes(columnType);
}

export function getDefaultView(displayName: string): TableConfigsMenuButton_SupplierTableConfigFragment {
    const defaultState: StoredTableState = {
        ...({
            preferencePanel: { open: false },
            sorting: { sortModel: [] },
            pinnedColumns: { left: ["__check__", "name"], right: ["ADD_COLUMN"] },
            columns: {
                columnVisibilityModel: {},
            },
        } as GridInitialStatePro),
        filter: [],
    };

    return {
        __typename: "SupplierTableConfig",
        id: "default",
        displayName: displayName,
        state: JSON.stringify(defaultState),
        createdAt: "",
    };
}

export function setSocialRiskTableView(
    filters: FilterInput[],
    visibleColumnIds: string[],
    allColumnIds: string[],
    sorts?: { columnId: string; order: "asc" | "desc" }[]
) {
    const allColumns = [...allColumnIds, CONTACT_COLUMN_ID];
    const visibleColumns = [...visibleColumnIds, SELECT_SUPPLIER_COLUMN_ID, NAME_COLUMN_ID, ADD_COLUMN_ID];

    const socialRiskState: StoredTableState = {
        preferencePanel: { open: false },
        pinnedColumns: { left: ["__check__", "name"], right: ["ADD_COLUMN"] },
        filter: filters,
        sorting: {
            sortModel: sorts?.map((s) => ({
                field: s.columnId,
                sort: s.order,
            })),
        },
        columns: {
            columnVisibilityModel: allColumns
                .filter((col) => !visibleColumns.includes(col))
                .reduce(
                    (acc, curr) => {
                        acc[curr] = false;
                        return acc;
                    },
                    {} as Record<string, boolean>
                ),
            dimensions: visibleColumns.reduce(
                (acc, curr) => {
                    switch (curr) {
                        case SELECT_SUPPLIER_COLUMN_ID:
                            acc[SELECT_SUPPLIER_COLUMN_ID] = { width: 50, maxWidth: -1, minWidth: 50 };
                            return acc;
                        case NAME_COLUMN_ID:
                            acc[NAME_COLUMN_ID] = { width: 300, maxWidth: -1, minWidth: 50 };
                            return acc;
                        case ADD_COLUMN_ID:
                            acc[ADD_COLUMN_ID] = { width: 50, maxWidth: -1, minWidth: 50 };
                            return acc;
                        default:
                            acc[curr] = { width: 150, maxWidth: -1, minWidth: 50 };
                            return acc;
                    }
                },
                {} as Record<string, { width: number; maxWidth: number; minWidth: number }>
            ),
        },
    };
    const tenant = localStorage.getItem("tenant");
    if (!tenant) return;
    localStorage.setItem(`${tenant}${STORED_SOCIAL_RISK_VIEW}`, JSON.stringify(socialRiskState));
    setLastUsedTableViewId("default");
}

export function isFromSocialRiskPage() {
    // write a function that check if url holds url parameter formSocialRiskPage=true
    const url = new URL(window.location.href);
    return url.searchParams.get("fromSocialRiskPage") === "true";
}
