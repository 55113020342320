import { Alert as MuiAlert, AlertTitle, Snackbar } from "@mui/material";
import React from "react";

import { useAlert } from "@/providers/Alerts";

const Alert: React.FC = () => {
    const { alert, onClose } = useAlert();

    return (
        <Snackbar
            key={alert?.timestamp}
            open={Boolean(alert?.message)}
            autoHideDuration={5000}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            {...alert?.SnackbarProps}
        >
            <MuiAlert severity={alert?.message.severity} title={alert?.message.title} {...alert?.AlertProps}>
                {alert?.message.title && <AlertTitle>{alert.message.title}</AlertTitle>}
                {alert?.message.value}
            </MuiAlert>
        </Snackbar>
    );
};

interface Props {
    children?: React.ReactNode;
}

export const ContentLayout: React.FC<Props> = ({ children }) => {
    return (
        <div style={{ boxSizing: "border-box", height: "100%", position: "relative", background: "white" }}>
            {children}
            <Alert />
        </div>
    );
};
