import { Skeleton, Stack } from "@mui/material";

interface ListContentContainerProps {
    children?: React.ReactNode;
    loading?: boolean;
}

export const ListContentContainer: React.FC<ListContentContainerProps> = ({ children, loading }) => {
    return (
        <Stack>
            {loading ? (
                <Stack justifyContent="center">
                    <Skeleton height="55px" width="100%" />
                    <Skeleton height="55px" width="100%" />
                    <Skeleton height="55px" width="100%" />
                </Stack>
            ) : (
                children
            )}
        </Stack>
    );
};
