import { useMemo } from "react";

import { FilePreviewData } from "../components/FilePreview";
import { BaseSupplierColumn, BaseSupplierColumnMapping } from "../interfaces";

const getDuplicateMappingValues = (mapping: Partial<BaseSupplierColumnMapping>) => {
    const seen = new Set();
    const duplicateValues = new Set();
    for (const fileColumn of Object.values(mapping)) {
        if (fileColumn !== undefined && seen.has(fileColumn)) {
            duplicateValues.add(fileColumn);
        }
        seen.add(fileColumn);
    }
    return duplicateValues;
};

const unmapDuplicates = (mapping: Partial<BaseSupplierColumnMapping>) => {
    const duplicateValues = getDuplicateMappingValues(mapping);

    // Mapping with duplicates mapped to undefined
    const duplicateMapping = Object.entries(mapping).reduce((acc, [baseColumn, fileColumn]) => {
        if (duplicateValues.has(fileColumn)) return { ...acc, [baseColumn]: undefined };
        return acc;
    }, {} as Partial<BaseSupplierColumnMapping>);

    // Set elements with duplicate values to undefined make the user map them
    return { ...mapping, ...duplicateMapping };
};

const BASE_COLUMN_REGEXPS: Record<BaseSupplierColumn, RegExp[]> = {
    name: [/supplier\s*name/i, /leverandør\s*navn/i],
    country: [/supplier\s*country/i, /leverandør\s*land/i, /country/i, /land/i],
    regNumber: [
        /vat\s*id/i,
        /registration\s*id/i,
        /organi[sz]ation\s*id/i,
        /vat\s*number/i,
        /registration\s*number/i,
        /organi[sz]ation\s*number/i,
        /reg\.?\s*number/i,
        /org\.?\s*number/i,
        /vat\.?\s*nr/i,
        /reg\.?\s*nr/i,
        /org\.?\s*nr/i,
        /vat\.?\s*no/i,
        /reg\.?\s*no/i,
        /org\.?\s*no/i,
        /organisasjons?\s*nummer/i,
        /vat\.?\s*nummer/i,
        /org\.?\s*nummer/i,
    ],
};

const automapColumns = (filePreview: FilePreviewData) => {
    const mapping = Object.entries(BASE_COLUMN_REGEXPS).reduce((acc, [baseColumn, regexps]) => {
        const match = filePreview.find((column) => regexps.some((regexp) => regexp.test(column.fieldKey)));
        return { ...acc, [baseColumn]: match?.fieldKey };
    }, {} as Partial<BaseSupplierColumnMapping>);

    return unmapDuplicates(mapping);
};

export const useAutomappedColumns = (filePreview?: FilePreviewData) => {
    return useMemo(() => {
        if (!filePreview) return {};
        return automapColumns(filePreview);
    }, [filePreview]);
};
