import { getMessages as getOpportunitiesMessages } from "@ignite-analytics/opportunities";
import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { deDE, enUS, nbNO } from "@mui/material/locale";
import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { CompatRouter } from "react-router-dom-v5-compat";

import { ApolloProvider } from "@/lib/apollo";
import { loadMessages } from "@/lib/i18n";
import { AppProps } from "@/types";

import { AlertProvider } from "./Alerts";
import { CompanyCurrencyProvider } from "./CompanyCurrencyContext";
import { UserContextProvider } from "./UserContext";

type Props = { children?: React.ReactNode } & AppProps;

function getMuiLocale(locale: string) {
    switch (locale) {
        case "nb-NO":
            return nbNO;
        case "de-DE":
            return deDE;
        default:
            return enUS;
    }
}

function customErrorFunction(err: Error) {
    console.warn(err);
    return;
}

export const AppProvider: React.FC<Props> = ({ children, theme = "ignite-ui", locale }) => {
    const designTokens = useMemo(() => getDesignTokens(theme, "light"), [theme]);
    const localization = useMemo(() => getMuiLocale(locale), [locale]);
    const messages = useMemo(() => ({ ...loadMessages(locale), ...getOpportunitiesMessages(locale) }), [locale]);

    return (
        <CompatRouter>
            <ThemeProvider theme={responsiveFontSizes(createTheme(designTokens, localization, { name: theme }))}>
                <CssBaseline />
                <IntlProvider messages={messages} locale={locale} key={locale} onError={customErrorFunction}>
                    <ApolloProvider>
                        <AlertProvider>
                            <UserContextProvider>
                                <CompanyCurrencyProvider>{children}</CompanyCurrencyProvider>
                            </UserContextProvider>
                        </AlertProvider>
                    </ApolloProvider>
                </IntlProvider>
            </ThemeProvider>
        </CompatRouter>
    );
};
