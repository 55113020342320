import { useMutation } from "@apollo/client";
import { SUPPLIER_TABLE_GT } from "@ignite-analytics/global-types";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { FileFieldInput } from "@/gql/graphql";
import { useUser } from "@/providers/UserContext";

import {
    BASE_IMPORT_CONFIG,
    BASE_SUPPLIER_COLUMN_DATA_TYPES,
    BASE_SUPPLIER_COLUMN_GLOBAL_TYPES,
    SUPPLIER_COLUMN_MESSAGES,
    SUPPLIER_COLUMN_STANDARD_NAMES,
} from "../constants";
import { evictDataGridColumns } from "../helpers";
import { BaseSupplierColumn, BaseSupplierColumnMapping, DataSource } from "../interfaces";

const CompleteSetupDocument = graphql(`
    mutation ValidateFileStep_CompleteSetup($input: CompleteSetupInput!) {
        completeSetup(input: $input) {
            dataTableId
            notificationId
        }
    }
`);

const UpdateSuppliersDocument = graphql(`
    mutation ValidateFileStep_UpdateSuppliers($input: UpdateSuppliersInput!) {
        updateSuppliers(input: $input) {
            valid
            error
            notificationId
        }
    }
`);

export const useTryToUploadSuppliers = () => {
    const [completeSetup, completeSetupMutation] = useMutation(CompleteSetupDocument);
    const [updateSuppliers, updateSuppliersMutation] = useMutation(UpdateSuppliersDocument);
    const { formatMessage } = useIntl();

    const user = useUser();

    const invalidUploadState = updateSuppliersMutation.data && !updateSuppliersMutation.data.updateSuppliers.valid;
    const loading = updateSuppliersMutation.loading || completeSetupMutation.loading;
    const error = updateSuppliersMutation.error ?? completeSetupMutation.error;

    const tryToUploadSuppliers = useCallback(
        async (
            file: File,
            fileId: string,
            mapping: Partial<BaseSupplierColumnMapping>,
            dataColumnIdToFieldNameMapping: Record<string, string>,
            includedUnmappedColumns: Set<string>,
            unmappedFileColumns: string[],
            supplierTableInfo: {
                sources: DataSource[];
                dataTableId?: string | null;
                dataPipelineIds: string[];
            }
        ) => {
            const fileSettings = {
                fileId,
                fileName: file.name,
                contentType: BASE_IMPORT_CONFIG.contentType,
                csvConfiguration: BASE_IMPORT_CONFIG.csvConfiguration,
                xlsxConfiguration: BASE_IMPORT_CONFIG.xlsxConfiguration,
            };

            const baseFieldsWithMapping = Object.entries(mapping)
                .filter(([, fileColumn]) => fileColumn !== undefined)
                .map(
                    ([targetColumn, fileColumn]) =>
                        ({
                            fieldName: fileColumn,
                            dataType: BASE_SUPPLIER_COLUMN_DATA_TYPES[targetColumn as BaseSupplierColumn],
                            globalType: BASE_SUPPLIER_COLUMN_GLOBAL_TYPES[targetColumn as BaseSupplierColumn],
                            repositoryFieldName: SUPPLIER_COLUMN_STANDARD_NAMES[targetColumn as BaseSupplierColumn],
                            dataColumnName: formatMessage(SUPPLIER_COLUMN_MESSAGES[targetColumn as BaseSupplierColumn]),
                        }) as const
                );
            const mappedFields = Object.entries(dataColumnIdToFieldNameMapping).map(
                ([dataColumnId, fieldName]) =>
                    ({
                        fieldName,
                        dataType: "TEXT",
                        dataColumnId,
                        globalType: null,
                    }) as const
            );
            const unmappedFields = unmappedFileColumns
                .filter((fileColumn) => includedUnmappedColumns.has(fileColumn))
                .map(
                    (fileColumn) =>
                        ({
                            fieldName: fileColumn,
                            dataType: "TEXT",
                            globalType: null,
                        }) as const
                );

            if (
                supplierTableInfo.sources.length === 0 ||
                supplierTableInfo.dataTableId === null ||
                supplierTableInfo.dataTableId === undefined
            ) {
                const existingFieldsWithMapping = [...baseFieldsWithMapping, ...mappedFields];

                const fileFields: FileFieldInput[] = existingFieldsWithMapping.map(
                    ({ fieldName, dataType, globalType }) => ({ fieldName, dataType, tag: globalType })
                );
                const result = await completeSetup({
                    variables: {
                        input: {
                            fields: fileFields,
                            tableType: SUPPLIER_TABLE_GT,
                            fileSettings,
                        },
                    },
                    // Evict cached columns because we might create new ones
                    update: evictDataGridColumns,
                });
                return result.data?.completeSetup.notificationId;
            } else {
                const allFieldsWithMapping = [...baseFieldsWithMapping, ...mappedFields, ...unmappedFields];

                const result = await updateSuppliers({
                    variables: {
                        input: {
                            fileSettings,
                            fields: allFieldsWithMapping,
                            userId: user.id,
                        },
                    },
                    // Evict cached columns if we create new ones
                    update: includedUnmappedColumns.size > 0 ? evictDataGridColumns : undefined,
                });

                if (!result.data?.updateSuppliers.valid)
                    throw new Error(result.data?.updateSuppliers.error ?? "Could not update suppliers");
                return result.data.updateSuppliers.notificationId;
            }
        },
        [user, completeSetup, updateSuppliers, formatMessage]
    );

    return [tryToUploadSuppliers, { invalidUploadState, loading, error }] as const;
};
