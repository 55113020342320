import { useFetchGet } from "./useFetchGet";

export type PermissionsResponse = {
    carbon: {
        included: boolean;
        expires_at?: string;
    };
    contracts: {
        included: boolean;
        expires_at?: string;
    };
    "social-risk": {
        included: boolean;
        expires_at?: string;
    };
    spend: {
        included: boolean;
        expires_at?: string;
    };
};

const isPermission = (data: unknown): data is PermissionsResponse => {
    if (typeof data !== "object" || data === null) {
        return false;
    }
    if (typeof (data as PermissionsResponse).carbon?.included !== "boolean") {
        return false;
    }
    if (typeof (data as PermissionsResponse)["social-risk"]?.included !== "boolean") {
        return false;
    }
    if (typeof (data as PermissionsResponse).spend?.included !== "boolean") {
        return false;
    }
    if (typeof (data as PermissionsResponse).contracts?.included !== "boolean") {
        return false;
    }
    return true;
};

export const useGetModuleAccessPermissions = (url: string) => {
    const permissions = useFetchGet<PermissionsResponse>(url, isPermission, {
        carbon: {
            included: true,
        },
        contracts: {
            included: true,
        },
        "social-risk": {
            included: true,
        },
        spend: {
            included: true,
        },
    });
    return {
        carbon: permissions.data?.carbon.included || false,
        contracts: permissions.data?.contracts.included || false,
        risk: permissions.data?.["social-risk"].included || false,
        spend: permissions.data?.spend.included || false,
    };
};
