import { DotsVertical, ChevronDown, ChevronUp, CheckCircle, ExclamationCircle } from "@ignite-analytics/icons";
import { Collapse, Stack, Tooltip, Typography, Menu, MenuItem, Switch, IconButton, Divider } from "@mui/material";
import { GridDensity, GridPreferencePanelsValue } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import React, { MutableRefObject, useState } from "react";
import { FormattedMessage } from "react-intl";

import { KeyboardShortcuts } from "./KeyboardShortcuts";

interface TablePreferencesProps {
    apiRef: MutableRefObject<GridApiPro> | undefined;
    density: GridDensity;
    onDensityChange: (density: GridDensity) => void;
    suppliersDataPollActive: boolean;
    onToggleSuppliersDataPoll: VoidFunction;
}

export const TablePreferences = React.memo(function TablePreferences({
    apiRef,
    density,
    suppliersDataPollActive,
    onDensityChange,
    onToggleSuppliersDataPoll,
}: TablePreferencesProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openTableDensity, setOpenTableDensity] = useState(false);
    const [tableShortcutsOpen, setTableShortcutsOpen] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenTableShortcuts = () => {
        setTableShortcutsOpen(true);
        handleClose();
    };

    const handleTogglePolling = () => {
        onToggleSuppliersDataPoll();
    };

    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                color="secondary"
                size="small"
            >
                <DotsVertical fontSize="small" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                sx={{ marginTop: 2 }}
            >
                <MenuItem
                    onClick={() => {
                        apiRef?.current?.showPreferences(GridPreferencePanelsValue.columns);
                        handleClose();
                    }}
                >
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Configure view" description="Configure view" />
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => setOpenTableDensity(!openTableDensity)}>
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Set table density" description="Set table density" />
                        </Typography>
                        {openTableDensity ? <ChevronUp fontSize="small" /> : <ChevronDown fontSize="small" />}
                    </Stack>
                </MenuItem>
                <Collapse in={openTableDensity}>
                    <MenuItem
                        onClick={() => onDensityChange("compact")}
                        sx={{
                            backgroundColor: (theme) =>
                                density === "compact" ? theme.palette.grey[200] : "transparent",
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            {density === "compact" ? <CheckCircle fontSize="inherit" /> : null}
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Compact" description="Compact" />
                            </Typography>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                        onClick={() => onDensityChange("standard")}
                        sx={{
                            backgroundColor: (theme) =>
                                density === "standard" ? theme.palette.grey[200] : "transparent",
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            {density === "standard" ? <CheckCircle fontSize="inherit" /> : null}
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Standard" description="Standard" />
                            </Typography>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                        onClick={() => onDensityChange("comfortable")}
                        sx={{
                            backgroundColor: (theme) =>
                                density === "comfortable" ? theme.palette.grey[200] : "transparent",
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            {density === "comfortable" ? <CheckCircle fontSize="inherit" /> : null}
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Comfortable" description="Comfortable" />
                            </Typography>
                        </Stack>
                    </MenuItem>
                </Collapse>
                <MenuItem onClick={handleOpenTableShortcuts}>
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Keyboard shortcuts" description="Keyboard shortcuts" />
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Real time data sync"
                                description="Real time data sync toggle label"
                            />
                        </Typography>
                        <Tooltip
                            title={
                                suppliersDataPollActive ? (
                                    <FormattedMessage
                                        defaultMessage="Data is being updated in real time. On slower connections, this may impact performance."
                                        description="Table data polling off description"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Data is not being updated in real time."
                                        description="Table data polling on description"
                                    />
                                )
                            }
                        >
                            <ExclamationCircle fontSize="inherit" />
                        </Tooltip>
                        <Switch checked={suppliersDataPollActive} onChange={handleTogglePolling} />
                    </Stack>
                </MenuItem>
            </Menu>

            <KeyboardShortcuts open={tableShortcutsOpen} onClose={() => setTableShortcutsOpen(false)} />
        </>
    );
});
