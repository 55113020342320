import { Lock } from "@ignite-analytics/icons";
import { Chip, Stack, Tooltip } from "@mui/material";
import { styled, Theme, useTheme } from "@mui/material/styles";

import { Chip as OldChip } from "../../Chip";

export const DisabledField = styled("div")(() => ({
    display: "flex",
    alignContent: "center",
    // backgroundColor: "#F6F6F7",
    width: "100%",
    height: "100%",
    padding: "0 8px",
    alignItems: "center",
    boxSizing: "border-box",
}));

type RiskValues = "low" | "medium" | "high";

const getChipColor = (value: RiskValues) => {
    switch (value) {
        case "low":
            return "primary";
        case "medium":
            return "warning";
        case "high":
            return "error";
    }
};

// riddle transition bandaid
const getOldChipColor = (value: RiskValues) => {
    switch (value) {
        case "low":
            return "#DDFDF9";
        case "medium":
            return "#D5E3FE";
        case "high":
            return "#FCE8E8";
    }
};

interface RiskFieldProps {
    value: string;
}

export const TableRiskField: React.FC<RiskFieldProps> = ({ value }) => {
    const { name } = useTheme() as Theme & { name?: string };

    if (!value) return null;
    return (
        <Tooltip placement="top" title={<Lock fontSize="small" />}>
            <Stack width="100%">
                <DisabledField>
                    {name === "ignite-riddle" ? (
                        <Chip
                            size="small"
                            color={getChipColor(value as RiskValues)}
                            label={value}
                            sx={{ textTransform: "capitalize" }}
                        />
                    ) : (
                        <OldChip color={getOldChipColor(value as RiskValues)}>{value}</OldChip>
                    )}
                </DisabledField>
            </Stack>
        </Tooltip>
    );
};
