import { MutationHookOptions, useMutation } from "@apollo/client";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

import { graphql } from "@/gql";
import { AddSupplier_SupplierColumnFragment, CreateSupplierInput, InputFieldType } from "@/gql/graphql";

import { GlobalType } from "../constants";

const addSupplierDocument = graphql(`
    mutation UseAddSupplier_AddSupplier($input: CreateSupplierInput!) {
        createSupplier(input: $input) {
            supplier {
                id
                name
                country
                orgNumber
                customFields {
                    id
                    name
                    fieldType
                    fieldId
                    dataJson
                }
            }
        }
    }
`);

graphql(`
    fragment AddSupplier_SupplierColumn on SupplierTableColumn {
        id
        globalType
        name
        type
    }

    fragment AddSupplier_Supplier on Supplier {
        id
    }
`);

const formatValue = (value: string, columnType: string): string => {
    if (columnType === "TEXT" || columnType === "SELECT") {
        return `"${value}"`;
    }
    return value;
};

export const useAddSupplier = (
    fields: AddSupplier_SupplierColumnFragment[],
    startIndex: number,
    pageSize: number,
    filterJson: string | undefined,
    options?: MutationHookOptions<ResultOf<typeof addSupplierDocument>, VariablesOf<typeof addSupplierDocument>>
) => {
    const [addSupplierMutation] = useMutation(addSupplierDocument, {
        ...options,
    });

    const addSupplier = (inputFields: object) => {
        let supplierObject: CreateSupplierInput = {
            name: "",
            country: "",
            orgNumber: "",
            customFields: [],
        };

        Object.entries(inputFields)
            .filter(([key]) => key !== "selected")
            .forEach(([key, value]) => {
                const column = fields.filter((field) => field.id === key)[0];
                if (value !== "") {
                    if (key === "name") {
                        supplierObject = { ...supplierObject, name: value };
                    } else {
                        switch (column.globalType) {
                            case GlobalType.RegNumber: {
                                supplierObject = { ...supplierObject, orgNumber: value };
                                break;
                            }
                            case GlobalType.SupplierCountry: {
                                supplierObject = { ...supplierObject, country: value };
                                break;
                            }
                            default: {
                                supplierObject = {
                                    ...supplierObject,
                                    customFields: [
                                        ...supplierObject.customFields,
                                        {
                                            fieldId: column.id,
                                            dataJson: formatValue(value, column.type),
                                            fieldType: column.type as unknown as InputFieldType,
                                            name: column.name,
                                        },
                                    ],
                                };
                            }
                        }
                    }
                }
            });

        addSupplierMutation({
            variables: {
                input: supplierObject,
            },
        });
    };
    return { addSupplier };
};
