import { Pen, Search, Trash, UserPlus } from "@ignite-analytics/icons";
import {
    Button,
    Divider,
    FormControlLabel,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {
    GridColDef,
    GridColumnMenu,
    GridColumnMenuItemProps,
    GridColumnMenuProps,
    GridColumnsPanelProps,
    gridColumnVisibilityModelSelector,
    GridPanelContent,
    GridPanelFooter,
    GridPanelHeader,
    GridPanelWrapper,
    useGridApiContext,
    useGridRootProps,
    useGridSelector,
} from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CONTACT_COLUMN_ID } from "../..";

function CustomUserItem(props: GridColumnMenuItemProps) {
    const { onClickHandler, actionTitle, icon } = props;
    return (
        <MenuItem onClick={onClickHandler}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{actionTitle}</ListItemText>
        </MenuItem>
    );
}

export function CustomColumnMenu(
    props: GridColumnMenuProps & {
        slotProps: {
            onDeleteColumn: (columnId: string) => void;
            onEditColumn: (columnId: string) => void;
            onGenerateContacts: VoidFunction;
        };
    }
) {
    let slots = {};

    const { formatMessage } = useIntl();

    if (props?.slotProps) {
        if (props.colDef.field.startsWith("custom_")) {
            slots = {
                ...slots,
                deleteColumnAction: CustomUserItem,
            };
        }
        if (props.colDef.field !== CONTACT_COLUMN_ID) {
            slots = {
                ...slots,
                editColumnAction: CustomUserItem,
            };
        }

        if (props.colDef.field === CONTACT_COLUMN_ID) {
            slots = {
                ...slots,
                generateContactsAction: CustomUserItem,
            };
        }
    }

    return (
        <GridColumnMenu
            {...props}
            slots={{ ...slots }}
            slotProps={{
                deleteColumnAction: {
                    displayOrder: 100,
                    actionTitle: formatMessage({
                        defaultMessage: "Delete column",
                        description: "Delete column",
                    }),
                    icon: <Trash fontSize="small" />,
                    onClickHandler: () => props.slotProps.onDeleteColumn(props.colDef.field),
                },
                editColumnAction: {
                    displayOrder: 90,
                    actionTitle: formatMessage({
                        defaultMessage: "Edit column",
                        description: "Edit column",
                    }),

                    icon: <Pen fontSize="small" />,
                    onClickHandler: () => props.slotProps.onEditColumn(props.colDef.field),
                },
                generateContactsAction: {
                    displayOrder: 10,
                    actionTitle: formatMessage({
                        defaultMessage: "Generate contacts",
                        description: "Generate contacts action label",
                    }),
                    icon: <UserPlus />,
                    onClickHandler: () => props.slotProps.onGenerateContacts(),
                },
            }}
        />
    );
}

export function CustomColumnPanel(props: GridColumnsPanelProps) {
    const { formatMessage } = useIntl();
    const otherColumns: { [key: string]: string } = {
        __check__: formatMessage({ defaultMessage: "Check Box" }),
        ADD_COLUMN: formatMessage({ defaultMessage: "Add Column" }),
        CONTACT_COLUMN: formatMessage({ defaultMessage: "Contact" }),
        ONBOARDING_COLUMN: formatMessage({ defaultMessage: "Onboarding" }),
        ONBOARDING_APPROVER_COLUMN: formatMessage({ defaultMessage: "Onboarding Approver" }),
    };
    const [searchValue, setSearchValue] = useState<string>("");
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector);

    const handleSearchValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }, []);

    const searchPredicate = (column: GridColDef, searchValue: string) =>
        (column.headerName || column.field).toLowerCase().indexOf(searchValue) > -1;

    const toggleColumn = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name: field } = event.target as HTMLInputElement;
        apiRef.current.setColumnVisibility(field, columnVisibilityModel[field] === false);
    };

    const currentColumns = useMemo(() => {
        const togglableColumns = rootProps.apiRef?.current.getAllColumns() ?? [];

        if (!searchValue) {
            return togglableColumns;
        }

        return togglableColumns.filter((column) => searchPredicate(column, searchValue.toLowerCase()));
    }, [rootProps.apiRef, searchValue]);

    const toggleAllColumns = useCallback(
        (isVisible: boolean) => {
            const currentModel = gridColumnVisibilityModelSelector(apiRef);
            const newModel = { ...currentModel };
            const togglableColumns =
                rootProps.apiRef?.current
                    .getAllColumns()
                    .filter((col) => col.hideable !== false)
                    .map((col) => col.field) ?? [];

            currentColumns.forEach((col) => {
                if (col.hideable && (togglableColumns == null || togglableColumns.includes(col.field))) {
                    if (isVisible) {
                        // delete the key from the model instead of setting it to `true`
                        delete newModel[col.field];
                    } else {
                        newModel[col.field] = false;
                    }
                }
            });
            return apiRef.current.setColumnVisibilityModel(newModel);
        },
        [apiRef, currentColumns, rootProps.apiRef]
    );

    const hideableColumns = useMemo(() => currentColumns.filter((col) => col.hideable), [currentColumns]);

    const allHideableColumnsHidden = useMemo(
        () => hideableColumns.every((column) => columnVisibilityModel[column.field] === false),
        [columnVisibilityModel, hideableColumns]
    );

    const allHideableColumnsVisible = useMemo(
        () =>
            hideableColumns.every(
                (column) => columnVisibilityModel[column.field] == null || columnVisibilityModel[column.field] !== false
            ),
        [columnVisibilityModel, hideableColumns]
    );

    const handleClose = () => {
        apiRef.current.hidePreferences();
    };

    return (
        <GridPanelWrapper {...props}>
            <GridPanelHeader>
                <Stack spacing={2} paddingY={0.5} paddingX={1}>
                    <Typography variant="textMd">
                        <FormattedMessage defaultMessage="Columns" description="Columns" />
                    </Typography>
                    <TextField
                        size="small"
                        variant="outlined"
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        placeholder={formatMessage({
                            defaultMessage: "Search",
                            description: "Search",
                        })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </GridPanelHeader>
            <Divider />
            <GridPanelContent sx={{ height: "1000px" }}>
                <Stack paddingX={2} paddingY={1} spacing={1}>
                    <Typography variant="textMd">
                        <FormattedMessage
                            defaultMessage="Column configuration"
                            description="Column configurations header"
                        />
                    </Typography>
                    {currentColumns
                        ?.filter((column) => !Object.keys(otherColumns).includes(column.field))
                        .map((column) => (
                            <FormControlLabel
                                key={column.field}
                                sx={{ paddingY: 1 }}
                                control={
                                    <Switch
                                        disabled={column.hideable === false}
                                        checked={columnVisibilityModel[column.field] !== false}
                                        onClick={toggleColumn}
                                        name={column.field}
                                        sx={{ p: 0.5 }}
                                        inputRef={undefined}
                                    />
                                }
                                label={column.headerName || column.field}
                            />
                        ))}
                    <Typography variant="textMd">
                        <FormattedMessage defaultMessage="Other settings" description="Other settings header" />
                    </Typography>
                    {currentColumns
                        ?.filter((column) => Object.keys(otherColumns).includes(column.field))
                        .map((column) => (
                            <FormControlLabel
                                key={column.field}
                                sx={{ paddingY: 1 }}
                                control={
                                    <Switch
                                        disabled={column.hideable === false}
                                        checked={columnVisibilityModel[column.field] !== false}
                                        onClick={toggleColumn}
                                        name={column.field}
                                        sx={{ p: 0.5 }}
                                        inputRef={undefined}
                                    />
                                }
                                label={otherColumns[column.field] || column.field}
                            />
                        ))}
                    <FormControlLabel
                        key="show_all"
                        sx={{ paddingY: 1 }}
                        control={
                            <Switch
                                disabled={hideableColumns.length === 0}
                                checked={allHideableColumnsVisible}
                                onClick={() => toggleAllColumns(true)}
                                sx={{ p: 0.5 }}
                            />
                        }
                        label={formatMessage({ defaultMessage: "Show all", description: "Show all columns toggle" })}
                    />
                    <FormControlLabel
                        key="hide_all"
                        sx={{ paddingY: 1 }}
                        control={
                            <Switch
                                disabled={hideableColumns.length === 0}
                                checked={allHideableColumnsHidden}
                                onClick={() => toggleAllColumns(false)}
                                sx={{ p: 0.5 }}
                            />
                        }
                        label={formatMessage({ defaultMessage: "Hide all", description: "Hide all columns toggle" })}
                    />
                </Stack>
            </GridPanelContent>
            <Divider />
            <GridPanelFooter sx={{ position: "sticky", bottom: 0 }}>
                <Stack width="100%" paddingY={0.75} paddingX={1}>
                    <Button fullWidth size="xsmall" onClick={handleClose} variant="contained" color="primary">
                        <FormattedMessage defaultMessage="Done" description="Done-button column visibility menu" />
                    </Button>
                </Stack>
            </GridPanelFooter>
        </GridPanelWrapper>
    );
}
