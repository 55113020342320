import { ExclamationTriangle, InformationCircleSolid, XCircleSolid } from "@ignite-analytics/icons";
import { styled, alpha, Box } from "@mui/material";

/**
 * TODO: This should likely be a shared component in the future. Not sure if we should solve like this in CSS or just use SVG ref theme-package alerts.
 */
export const AlertIconWrapper = styled("div")<{ color: "error" | "warning" | "info" | "success" }>(
    ({ theme, color }) => ({
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(theme.palette[color].main, 0.08),
        borderRadius: "50%",
        position: "relative",
        padding: theme.spacing(1),
        "&::before, &::after": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
        },
        "&::before": {
            border: `2px solid ${alpha(theme.palette[color].main, 0.32)}`,
            transform: "scale(1.2)",
        },
        "&::after": {
            border: `2px solid ${alpha(theme.palette[color].main, 0.16)}`,
            transform: "scale(1.4)",
        },
    })
);

export const AlertIcon: React.FC<{ variant: "error" | "warning" | "info" }> = ({ variant }) => {
    const Icon = getIcon(variant);
    return (
        <Box>
            <AlertIconWrapper color={variant}>{Icon}</AlertIconWrapper>
        </Box>
    );
};

function getIcon(variant: "error" | "warning" | "info") {
    switch (variant) {
        case "error":
            return <XCircleSolid />;
        case "warning":
            return <ExclamationTriangle />;
        case "info":
            return <InformationCircleSolid />;
        default:
            return <ExclamationTriangle />;
    }
}
