import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";

import { AppRoutes } from "@/routes";

import { useUser } from "./providers/UserContext";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_TOKEN ?? "");

const App: React.FC = () => {
    const user = useUser();

    useEffect(() => {
        track("Suppliers: App loaded");
    }, []);
    return (
        <FeatureToggleContextProvider userEmail={user.email} tenant={user.tenant}>
            <AppRoutes />
        </FeatureToggleContextProvider>
    );
};
export default App;
