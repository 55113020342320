import { useReducer, useEffect } from "react";

type State<T> = {
    data: T | null;
    loading: boolean;
    error: Error | null;
};

type Action<T> = { type: "LOADING" } | { type: "SUCCESS"; payload: T } | { type: "ERROR"; payload: Error };

const dataFetchReducer =
    <T>() =>
    (state: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case "LOADING":
                return { ...state, loading: true };
            case "SUCCESS":
                return { ...state, data: action.payload, loading: false };
            case "ERROR":
                return { ...state, error: action.payload, loading: false };
        }
    };

/*
 * This is a simple custom hook to fetch data and get loading and error state
 * if we want more complex functionality we should migrate this to  use react-query or SWR
 * @param url - the url to fetch data from
 * @param typeGuard - a type guard to ensure the data is of the right type
 */
export const useFetchGet = <T>(
    url: string,
    typeGuard: (data: unknown) => data is T,
    defaultValue: T | null = null
): {
    data: T | null;
    loading: boolean;
    error: Error | null;
} => {
    const [state, dispatch] = useReducer(dataFetchReducer<T>(), {
        data: defaultValue,
        loading: false,
        error: null,
    });
    useEffect(() => {
        let didCancel = false;

        async function fetchData() {
            dispatch({ type: "LOADING" });
            try {
                const response = await fetch(url, {
                    credentials: "include",
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                if (!didCancel) {
                    const data = await response.json();
                    if (!typeGuard(data)) {
                        throw new Error("Data is not of right type");
                    }
                    dispatch({ type: "SUCCESS", payload: data });
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({
                        type: "ERROR",
                        payload: error instanceof Error ? error : Error("Something went wrong"),
                    });
                }
            }
        }

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [typeGuard, url]);

    return { ...state };
};
