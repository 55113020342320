import { useMutation } from "@apollo/client";
import { Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DataGridColumnsDocument } from "@/gql/graphql";
import { useAlert } from "@/providers/Alerts";

const deleteSupplierTableColumnDocument = graphql(`
    mutation DeleteColumn_DeleteSupplierTableColumn($input: DeleteSupplierTableColumnInput!) {
        deleteSupplierTableColumn(input: $input) {
            id
        }
    }
`);

interface DeleteColumnProps {
    columnId: string;
    open: boolean;
    onClose: (columnId?: string) => void;
    onDeleteColumn: (columnId: string) => void;
}

export const DeleteColumn: React.FC<DeleteColumnProps> = ({ columnId, open, onClose, onDeleteColumn }) => {
    const [deleteColumnMutation] = useMutation(deleteSupplierTableColumnDocument);

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const handleDeleteColumn = () => {
        deleteColumnMutation({
            variables: {
                input: {
                    id: columnId,
                },
            },
            onCompleted: () => {
                track("Supplier Table: Deleted Column");
                onDeleteColumn(columnId);
                onClose(columnId);
            },
            onError: () => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Error deleting column",
                        description: "Alert message for error when deleting column",
                    }),
                    severity: "error",
                });
            },
            update: (cache) => {
                const q = cache.readQuery({
                    query: DataGridColumnsDocument,
                });
                if (!q) {
                    return;
                }
                cache.writeQuery({
                    query: DataGridColumnsDocument,
                    data: {
                        getSupplierTableMeta: {
                            columns: [...q.getSupplierTableMeta.columns.filter((c) => c.id !== columnId)],
                            __typename: "GetSupplierTableMetaResponse",
                        },
                        getAllSupplierTableConfigs: {
                            ...q.getAllSupplierTableConfigs,
                            __typename: "GetSupplierTableConfigsResponse",
                        },
                        __typename: "Query",
                    },
                });
            },
        });
    };

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>
                <FormattedMessage defaultMessage="Delete column" description="Delete column" />
            </DialogTitle>
            <DialogContent>
                <Typography variant="textSm">
                    <FormattedMessage
                        defaultMessage="Are you sure you want to delete this column?"
                        description="Are you sure you want to delete this column"
                    />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row">
                    <Button onClick={() => onClose()} variant="text" color="secondary">
                        <FormattedMessage defaultMessage="Cancel" description="Cancel" />
                    </Button>
                    <Button onClick={handleDeleteColumn} startIcon={<Trash />}>
                        <FormattedMessage defaultMessage="Delete" description="Delete" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
