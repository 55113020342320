import { useEffect } from "react";
import { useIntl } from "react-intl";

type Location = "overview" | "upload" | "socialRisk" | "onboardingList";

export function useSetBreadcrumbs(location: Location) {
    const { formatMessage } = useIntl();
    useEffect(() => {
        const basePayload = [
            {
                text: formatMessage({ defaultMessage: "Suppliers", description: "Breadcrumb for suppliers module" }),
                href: "/suppliers/overview",
            },
        ];

        let payload;
        switch (location) {
            case "upload":
                payload = [
                    ...basePayload,

                    {
                        text: formatMessage({
                            defaultMessage: "Upload",
                            description: "Breadcrumb for suppliers upload",
                        }),
                    },
                ];
                break;
            case "overview":
                payload = [
                    ...basePayload,

                    {
                        text: formatMessage({
                            defaultMessage: "Supplier list",
                            description: "Breadcrumb for suppliers overview page",
                        }),
                    },
                ];
                break;
            case "onboardingList":
                payload = [
                    ...basePayload,

                    {
                        text: formatMessage({
                            defaultMessage: "Onboarding list",
                            description: "Breadcrumb for suppliers onboarding list",
                        }),
                    },
                ];
                break;
            case "socialRisk":
                payload = [
                    {
                        text: formatMessage({
                            defaultMessage: "Social risk",
                            description: "Breadcrumb for suppliers social risk page",
                        }),
                        href: "/suppliers/risk/",
                    },
                ];
                break;
        }
        const data = {
            source: "ignite-breadcrumbs",
            payload,
        };
        window.postMessage(data, window.location.origin);
    }, [formatMessage, location]);
}
