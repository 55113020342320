import { File } from "@ignite-analytics/icons";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface RfxProps {
    disabled: boolean;
    onClick: () => void;
}

export const StartRfxButton: React.FC<RfxProps> = ({ disabled, onClick }: RfxProps) => {
    return (
        <Button disabled={disabled} color="inherit" variant="text" onClick={onClick} startIcon={<File />}>
            <FormattedMessage defaultMessage="Start RFX" description="Start RFX button" />
        </Button>
    );
};
