import { useMutation } from "@apollo/client";
import { ChevronDown, ExclamationCircle } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Divider, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { TableConfigsMenuButton_SupplierTableConfigFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { getDefaultView, getLastUsedTableViewId, setLastUsedTableViewId, StoredTableState } from "../../../tableUtils";

import { CreateViewModal } from "./CreateViewModal";
import { SelectViews } from "./SelectViews";

export type TableConfigsMenuButtonProps = {
    readonly onViewSelect: (state: StoredTableState) => void;
    readonly isEditor: boolean;
    readonly currentTableState: StoredTableState;
    readonly views: TableConfigsMenuButton_SupplierTableConfigFragment[];
};

graphql(`
    fragment TableConfigsMenuButton_SupplierTableConfig on SupplierTableConfig {
        id
        state
        displayName
        ...CreateViewModal_SupplierTableConfig
        ...SelectViews_SupplierTableConfig
    }
`);

const updateSupplierTableConfigMutation = graphql(`
    mutation TableConfigsMenuButton_updateSupplierTableConfigMutation($input: UpdateSupplierTableConfigInput!) {
        updateSupplierTableConfig(input: $input) {
            supplierTableConfig {
                ...TableConfigsMenuButton_SupplierTableConfig
            }
        }
    }
`);

export const TableConfigsMenuButton = React.memo(function TableConfigsMenuButton({
    onViewSelect,
    isEditor,
    currentTableState,
    views,
}: TableConfigsMenuButtonProps) {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const defaultView = getDefaultView(
        formatMessage({
            defaultMessage: "Default view",
            description: "Default view display name",
        })
    );
    const [isOpen, setOpen] = useState(false);
    const [isCreateViewModalOpen, setCreateViewModalOpen] = useState(false);
    const anchorEl = useRef(null);
    const [updateView] = useMutation(updateSupplierTableConfigMutation);

    const { selectedView, viewHasChanges } = useMemo(() => {
        const view = views?.find((view) => view.id === getLastUsedTableViewId()) ?? defaultView;
        const selectedViewState = JSON.parse(view.state);
        return { selectedView: view, viewHasChanges: !isEqual(currentTableState, selectedViewState) };
    }, [views, defaultView, currentTableState]);

    const handleOnViewChange = useCallback(
        (view: TableConfigsMenuButton_SupplierTableConfigFragment) => {
            setLastUsedTableViewId(view.id);
            onViewSelect(JSON.parse(view.state));
        },
        [onViewSelect]
    );

    const handleOnViewCreate = useCallback(
        (id: string, view: TableConfigsMenuButton_SupplierTableConfigFragment) => {
            // have to filter out the existing view and then add the updated one if the view already exists
            setLastUsedTableViewId(view.id);
            return onViewSelect(JSON.parse(view.state));
        },
        [onViewSelect]
    );

    const handleOnViewDelete = useCallback(
        (id: string) => {
            id === selectedView.id && handleOnViewChange(defaultView);
            setOpen(false);
        },
        [handleOnViewChange, defaultView, selectedView.id]
    );

    const toggleOpen = useCallback(() => {
        if (!isOpen) {
            track("Supplier Table: Opened views menu", { viewHasChanges });
        }
        setOpen(!isOpen);
    }, [isOpen, viewHasChanges]);

    const handleOnViewUpdate = useCallback(
        (id: string, displayName: string, state: string) => {
            updateView({
                variables: {
                    input: {
                        id: id,
                        displayName: displayName,
                        state: state,
                    },
                },
                onCompleted: (data) => {
                    setOpen(false);
                    const { state } = data?.updateSupplierTableConfig?.supplierTableConfig ?? {};
                    if (!state) {
                        return;
                    }
                    try {
                        handleOnViewCreate(id, data?.updateSupplierTableConfig?.supplierTableConfig);
                        const deserializedState = JSON.parse(state);
                        onViewSelect(deserializedState);
                    } catch (e) {
                        Sentry.captureException(e, { tags: { app: "suppliers-app" } });
                        alertUser({
                            value: formatMessage({
                                defaultMessage: "An error occurred while parsing the saved configuration.",
                                description: "Alert message when loading a saved table config fails.",
                            }),
                            severity: "error",
                        });
                    }
                },
                onError: (e) => {
                    setOpen(false);
                    Sentry.captureException(e, { tags: { app: "suppliers-app" } });
                    alertUser({
                        value: formatMessage({
                            defaultMessage: "An error occurred while parsing the saved configuration.",
                            description: "Alert message when loading a saved table config fails.",
                        }),
                        severity: "error",
                    });
                },
            });
        },
        [updateView, onViewSelect, alertUser, formatMessage, handleOnViewCreate]
    );
    return (
        <Stack direction="row" alignItems="center">
            <Button
                id="saved-views-button"
                aria-haspopup="true"
                color="secondary"
                aria-controls={isOpen ? "saved-views-menu" : undefined}
                aria-expanded={isOpen ? "true" : undefined}
                onClick={toggleOpen}
                ref={anchorEl}
                startIcon={
                    viewHasChanges &&
                    selectedView.id !== "default" && (
                        <Tooltip
                            title={formatMessage({
                                defaultMessage: "Unsaved changes",
                                description: "Unsaved changes",
                            })}
                            placement="top"
                        >
                            <ExclamationCircle fontSize="inherit" color="warning" />
                        </Tooltip>
                    )
                }
                endIcon={<ChevronDown fontSize="small" />}
                size="small"
            >
                <Typography
                    variant="textSm"
                    maxWidth={160}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {selectedView?.displayName ??
                        formatMessage({ defaultMessage: "Manage views", description: "Manage views" })}
                </Typography>
            </Button>
            <Menu
                id="saved-views-menu"
                anchorEl={anchorEl.current}
                open={isOpen}
                onClose={() => setOpen(false)}
                MenuListProps={{
                    "aria-labelledby": "saved-views-button",
                }}
                sx={{ marginTop: 1 }}
            >
                {isEditor && (
                    <Tooltip
                        title={
                            selectedView?.id != "default" && viewHasChanges
                                ? ""
                                : selectedView?.id == "default"
                                  ? formatMessage({
                                        defaultMessage: "Can't change default view",
                                        description: "Cant change view message",
                                    })
                                  : formatMessage({
                                        defaultMessage: "No changes to save",
                                        description: "No changes to save message",
                                    })
                        }
                        placement="left"
                    >
                        <Stack>
                            <MenuItem
                                key="save-changes"
                                onClick={() => {
                                    handleOnViewUpdate(
                                        selectedView.id,
                                        selectedView.displayName,
                                        JSON.stringify({ ...currentTableState, preferencePanel: { open: false } })
                                    );
                                    track("Supplier Table: Saved view", { selectedView, currentTableState });
                                }}
                                disabled={!viewHasChanges || selectedView?.id == "default"}
                            >
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="Save changes" description="Save changes" />
                                </Typography>
                            </MenuItem>
                        </Stack>
                    </Tooltip>
                )}
                <Tooltip
                    title={
                        !viewHasChanges
                            ? formatMessage({
                                  defaultMessage: "No changes to discard",
                                  description: "No changes to discard message",
                              })
                            : ""
                    }
                    placement="left"
                >
                    <Stack>
                        <MenuItem
                            key="discard-changes"
                            onClick={() => {
                                if (!selectedView) return;
                                handleOnViewChange(selectedView);
                                track("Supplier Table: Discarded changes", { selectedView, currentTableState });
                            }}
                            disabled={!viewHasChanges}
                        >
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Discard changes" description="Discard changes" />
                            </Typography>
                        </MenuItem>
                    </Stack>
                </Tooltip>
                {isEditor && (
                    <MenuItem key="save-as-new" onClick={() => setCreateViewModalOpen(true)}>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Save as new view" description="Save as new view" />
                        </Typography>
                    </MenuItem>
                )}

                <Divider />
                <SelectViews
                    selectedViewId={selectedView.id}
                    onViewSelect={handleOnViewChange}
                    views={[...views, defaultView]}
                    isEditor={isEditor}
                    toggleOpen={toggleOpen}
                    onViewDelete={handleOnViewDelete}
                    onViewRename={handleOnViewUpdate}
                />
            </Menu>
            {isCreateViewModalOpen && (
                <CreateViewModal
                    isOpen={isCreateViewModalOpen}
                    close={() => {
                        setCreateViewModalOpen(false);
                        setOpen(false);
                    }}
                    currentState={currentTableState}
                    onSuccess={handleOnViewCreate}
                />
            )}
        </Stack>
    );
});
