import { Chip } from "@mui/material";
import { useIntl } from "react-intl";

interface MappingStatusProps {
    value: string | null;
    newField?: boolean;
    ignored?: boolean;
}

export const MappingStatus: React.FC<MappingStatusProps> = ({ value, newField, ignored }) => {
    const { formatMessage } = useIntl();
    if (newField)
        return (
            <Chip
                color="primary"
                label={
                    ignored
                        ? formatMessage({ defaultMessage: "Ignored", description: "Ignored badge label" })
                        : formatMessage({ defaultMessage: "New", description: "New badge label" })
                }
            />
        );
    if (value)
        return (
            <Chip
                color="success"
                label={formatMessage({ defaultMessage: "Mapped", description: "Mapped badge label" })}
            />
        );

    return (
        <Chip
            color="warning"
            label={formatMessage({ defaultMessage: "Missing", description: "Missing badge label" })}
        />
    );
};
