import { Box } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { TableViews_RiskColumnConfigFragment, TableViews_SupplierFragment } from "@/gql/graphql";

import { FilterInput } from "../../InlineFilter/types";

import { BoxItem } from "./Components/BoxItem";
import { CountryDistributionList } from "./Components/CountryDistributionList";
import { HighRiskSuppliersList } from "./Components/HighRiskSuppliersList";
import { IndustryDistributionList } from "./Components/IndustryDistributionList";
import { TableViewHeader } from "./Components/TableViewHeader";

interface TableViews {
    topSuppliers?: TableViews_SupplierFragment[];
    topSuppliersLoading: boolean;
    socialRiskConfig?: TableViews_RiskColumnConfigFragment;
    spendColumnIds: string[];
    lastSpendYearColumnId: string;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}

graphql(`
    fragment TableViews_RiskColumnConfig on GetRiskColumnConfigResponse {
        id
        nameColumn
        countryColumn
        orgNumberColumn
        socialRiskColumn
        industryRiskColumn
        geographyRiskColumn
        naceColumn
    }
    fragment TableViews_Supplier on Supplier {
        id
        name
        country
        nace
        customFields {
            id
            dataJson
            fieldType
        }
        spend {
            total
            lastAvailableYear {
                value
            }
        }
        risk {
            social
            geography
            industry
        }
    }
`);

export const TableViews: React.FC<TableViews> = ({
    topSuppliers,
    socialRiskConfig,
    onNavigate,
    topSuppliersLoading,
    spendColumnIds,
    lastSpendYearColumnId,
}) => {
    const { formatMessage } = useIntl();
    const tableViews = [
        formatMessage({ defaultMessage: "High risk suppliers", description: "high risk suppliers list header" }),
        formatMessage({ defaultMessage: "Country distribution", description: "country distribution list header" }),
        formatMessage({ defaultMessage: "Industry distribution", description: "industry distribution list header" }),
    ];
    const [currentTable, setCurrentTable] = useState(0);

    const renderTable = () => {
        switch (currentTable) {
            case 0:
                return <HighRiskSuppliersList topSuppliers={topSuppliers} topSuppliersLoading={topSuppliersLoading} />;
            case 1:
                return (
                    <CountryDistributionList
                        geoRiskColumnId={socialRiskConfig?.geographyRiskColumn}
                        onNavigate={onNavigate}
                        topSuppliers={topSuppliers}
                        topSuppliersLoading={topSuppliersLoading}
                    />
                );
            case 2:
                return (
                    <IndustryDistributionList
                        industryRiskColumnId={socialRiskConfig?.industryRiskColumn}
                        naceColumnId={socialRiskConfig?.naceColumn}
                        onNavigate={onNavigate}
                        topSuppliers={topSuppliers}
                        topSuppliersLoading={topSuppliersLoading}
                    />
                );
            default:
                return null;
        }
    };

    const handleViewAllClick = () => {
        let listName = "";
        let sortConfig: {
            columnId: string;
            order: "desc" | "asc";
        }[] = [];
        switch (currentTable) {
            case 0:
                listName = "HighRiskSuppliersList";
                sortConfig = [
                    {
                        columnId: lastSpendYearColumnId ?? "",
                        order: "desc",
                    },
                ];
                break;
            case 1:
                listName = "CountryDistributionList";
                sortConfig = [{ columnId: socialRiskConfig?.countryColumn ?? "", order: "asc" }];
                break;
            case 2:
                listName = "IndustryDistributionList";
                sortConfig = [{ columnId: socialRiskConfig?.naceColumn ?? "", order: "asc" }];
                break;
        }
        const visibleColumns = Object.values(socialRiskConfig ?? {});
        onNavigate(
            [
                {
                    type: "include",
                    column_id: socialRiskConfig?.socialRiskColumn ?? "",
                    include_exclude_values: ["high"],
                    min_range: null,
                    max_range: null,
                    min_date: null,
                    max_date: null,
                    include_blanks: false,
                },
            ],
            spendColumnIds.length > 0 ? [...visibleColumns, ...spendColumnIds] : visibleColumns,
            listName,
            sortConfig
        );
    };

    return (
        <Box px={1.5}>
            <BoxItem>
                <TableViewHeader
                    tableViews={tableViews}
                    currentTable={currentTable}
                    setCurrentTable={setCurrentTable}
                    onViewAllClick={handleViewAllClick}
                />
                {renderTable()}
            </BoxItem>
        </Box>
    );
};
