import { Filter as FilterIcon, Plus, X } from "@ignite-analytics/icons";
import { Badge, Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { InlineFilter_SupplierTableColumnFragment } from "@/gql/graphql";
import { track } from "@/lib/track";

import { FilterLine } from "./FilterLine";
import { Filter, FilterInput } from "./types";

graphql(`
    fragment InlineFilter_SupplierTableColumn on SupplierTableColumn {
        id
        type
        ...FilterLine_SupplierTableColumn
    }
`);

interface FilterProps {
    columns: InlineFilter_SupplierTableColumnFragment[];
    setFilterInput: (filterInput: FilterInput[]) => void;
    filterInput: FilterInput[];
}

const parseDate = (date: string | null) => {
    // Check if date is a valid time string

    if (date && !isNaN(Date.parse(date))) {
        return new Date(date).toISOString().split("T")[0];
    }

    return null;
};

const generateRandomID = () => {
    return Math.random().toString(36).slice(2, 9);
};

const newFilter = (): Filter => {
    return {
        id: generateRandomID(),
        type: null,
        column: null,
        includeExcludeValues: [],
        minDate: null,
        maxDate: null,
        hasChanged: false,
    };
};

const loadFilter = (
    filter: FilterInput[] | undefined,
    columns: InlineFilter_SupplierTableColumnFragment[]
): Filter[] => {
    if (!filter || filter.length == 0) {
        return [newFilter()];
    }
    return filter.map((f) => ({
        id: generateRandomID(),
        type: f.type,
        column: columns.find((c) => c.id === f.column_id) ?? null,
        includeExcludeValues: [
            ...f.include_exclude_values,
            ...((f.type == "include" && f.include_blanks) || (f.type == "exclude" && !f.include_blanks)
                ? ["Empty fields"]
                : []),
        ],
        minRange: String(f.min_range),
        maxRange: String(f.max_range),
        minDate: parseDate(f.min_date),
        maxDate: parseDate(f.max_date),
        hasChanged: false,
    }));
};
const isValidFilter = (filter: Filter) => {
    if (!filter?.column?.id) {
        return false;
    }
    if (filter.type === "include" || filter.type === "exclude") {
        return !!filter.includeExcludeValues.length;
    } else if (filter.type === "range") {
        return !!filter.minRange || !!filter.maxRange;
    } else if (filter.type === "date") {
        return !!filter.minDate || !!filter.maxDate;
    }

    return false;
};

function isFilterableColumns(column: InlineFilter_SupplierTableColumnFragment) {
    const nonFilterableIds = ["ONBOARDING_COLUMN", "ONBOARDING_APPROVER_COLUMN"];
    const filterableTypes = [
        "DATE",
        "AGGREGATION",
        "SPEND",
        "NUMBER",
        "SELECT",
        "TEXT",
        "CLASSIFICATION",
        "RISK",
        "NACE",
        "ASSESSMENT_SCORE",
        "ASSESSMENT_STATUS",
    ];

    if (nonFilterableIds.includes(column.id)) {
        return false;
    }

    return filterableTypes.includes(column.type);
}

const onboardingStatusColumn: InlineFilter_SupplierTableColumnFragment = {
    globalType: "",
    id: "onboarding.status",
    name: "Onboarding status",
    type: "TEXT",
    __typename: "SupplierTableColumn",
} as InlineFilter_SupplierTableColumnFragment;

export const InlineFilter: React.FC<FilterProps> = ({ columns, filterInput, setFilterInput }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { formatMessage } = useIntl();

    const [filters, setFilters] = useState<Filter[]>([]);
    useEffect(() => {
        setFilters(loadFilter(filterInput, [...columns, onboardingStatusColumn]));
    }, [filterInput, columns]);

    const filterableColumns = useMemo(() => {
        return [...columns.filter((column) => isFilterableColumns(column)), onboardingStatusColumn];
    }, [columns]);

    const createFilterJSONString = useCallback(
        (filters: Filter[]): FilterInput[] => {
            let updatedFilters: Filter[] = [];
            const f = filters
                .filter((filter) => {
                    const valid = isValidFilter(filter);
                    if (!valid) {
                        updatedFilters = [...updatedFilters, filter];
                    }
                    return valid;
                })
                .map((filter) => {
                    updatedFilters = [...updatedFilters, { ...filter, hasChanged: false }];
                    // include blanks is true if the empty field value is in the includeExcludeValues array
                    const userSelectedEmptyFields = filter.includeExcludeValues.includes(
                        formatMessage({ defaultMessage: "Empty fields" })
                    );
                    return {
                        type: filter.type,
                        column_id: filter.column?.id ?? "",
                        include_exclude_values: filter.includeExcludeValues.filter(
                            (v) => v !== formatMessage({ defaultMessage: "Empty fields" })
                        ),
                        min_range: filter.minRange ? Number(filter.minRange) : null,
                        max_range: filter.maxRange ? Number(filter.maxRange) : null,
                        min_date: parseDate(filter.minDate),
                        max_date: parseDate(filter.maxDate),
                        include_blanks:
                            filter.includeBlanks !== undefined
                                ? filter.includeBlanks
                                : filter.type == "exclude"
                                  ? !userSelectedEmptyFields
                                  : userSelectedEmptyFields,
                    };
                });
            setFilters([...updatedFilters]);
            return f;
        },
        [formatMessage]
    );

    // Ensures that when a column is deleted, the filter is also deleted
    useEffect(() => {
        const columnIds = columns.map((column) => column.id).concat(onboardingStatusColumn.id);
        setFilters((filters) =>
            filters.filter(
                (filter) => filter.column === null || (filter.column?.id && columnIds.includes(filter.column.id))
            )
        );
    }, [filterInput.length, columns]);

    const handleApplyFilters = useCallback(() => {
        setFilterInput(createFilterJSONString(filters));
        setOpen(false);
    }, [filters, setFilterInput, createFilterJSONString]);

    const handleAddFilter = () => {
        const newFilters = [...filters, newFilter()];
        setFilters([...newFilters]);
    };

    const handleUpdateFilter = (filter: Filter) => {
        const updatedFilters = filters.map((f) => {
            if (f.id === filter.id) {
                return { ...filter, hasChanged: true };
            }
            return f;
        });
        setFilters([...updatedFilters]);
        track("Supplier Table: Applied Filters");
    };

    const handleDeleteFilter = (id: string) => {
        const updatedFilters = filters.filter((f) => f.id !== id);
        setFilters([...updatedFilters]);
        setFilterInput(createFilterJSONString(updatedFilters));
    };

    const handleClearFilters = () => {
        setFilters([newFilter()]);
        setFilterInput([]);
    };

    const applyFilterButtonDisabled = useMemo(() => {
        return !filters.some((filter) => isValidFilter(filter) && filter.hasChanged);
    }, [filters]);

    function handleFiltersClicked() {
        setOpen(true);
        track("Supplier Table: Clicked Filters");
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
                <Stack direction="row">
                    <div ref={(el) => setAnchorEl(el)} />
                    <Button
                        color="secondary"
                        onClick={handleFiltersClicked}
                        size="small"
                        sx={{ paddingRight: filterInput.length ? 1 : "20px" }}
                    >
                        <Stack direction="row" alignItems="center">
                            <FilterIcon fontSize="small" />
                            <Typography variant="textSm" marginLeft={1}>
                                <FormattedMessage defaultMessage="Filter" description="Filter button" />
                            </Typography>
                            {!!filterInput.length && (
                                <>
                                    <Badge
                                        sx={{
                                            bgcolor: "#DFF1FD",
                                            color: (theme) => theme.palette.info.dark,
                                            width: "16px",
                                            height: "16px",
                                            borderRadius: "15%",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            marginLeft: 1,
                                        }}
                                    >
                                        <Typography variant="text2xs">{filterInput.length}</Typography>
                                    </Badge>

                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleClearFilters();
                                        }}
                                        size="xsmall"
                                        sx={{ margin: 0 }}
                                    >
                                        <X />
                                    </IconButton>
                                </>
                            )}
                        </Stack>
                    </Button>
                </Stack>
                <Popover open={open} onClose={() => setOpen(false)} anchorEl={anchorEl}>
                    <Stack direction="column" spacing={2} padding={3} minWidth={720}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Filters" description="Filters title" />
                            </Typography>
                            <Button
                                disabled={filterInput.length === 0}
                                onClick={handleClearFilters}
                                sx={{ ":disabled": { opacity: 0.38 } }}
                                size="2xsmall"
                                color="secondary"
                            >
                                <FormattedMessage defaultMessage="Clear Filters" description="Clear Filters button" />
                            </Button>
                        </Stack>
                        {filters.map((filter) => (
                            <FilterLine
                                filter={filter}
                                key={"filter-line-" + filter.id}
                                columns={filterableColumns}
                                handleUpdateFilter={handleUpdateFilter}
                                handleDeleteFilter={handleDeleteFilter}
                                showDelete={filters.length > 1}
                            />
                        ))}
                        <Stack justifyContent="space-between" paddingTop={4} direction="row">
                            <Button
                                color="secondary"
                                size="2xsmall"
                                startIcon={<Plus fontSize="small" />}
                                onClick={handleAddFilter}
                            >
                                <FormattedMessage defaultMessage="Add Filter" description="Add Filter button" />
                            </Button>
                            <Button
                                onClick={handleApplyFilters}
                                disabled={applyFilterButtonDisabled}
                                sx={{
                                    ":disabled": {
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        color: (theme) => theme.palette.common.white,
                                        opacity: 0.38,
                                    },
                                }}
                                size="2xsmall"
                            >
                                <FormattedMessage defaultMessage="Apply Filters" description="Apply Filter button" />
                            </Button>
                        </Stack>
                    </Stack>
                </Popover>
            </Stack>
        </LocalizationProvider>
    );
};
