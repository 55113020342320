import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";

const CreateUploadUrls = graphql(`
    mutation UseFileManager_CreateUploadUrls($input: CreateUploadUrlsInput!) {
        createUploadUrls(input: $input) {
            urls {
                fileId
                fileName
                url
            }
        }
    }
`);

const UpdateFilesUploadStatus = graphql(`
    mutation UseFileManager_UpdateFilesUploadStatus($input: UpdateFilesUploadStatusInput!) {
        updateFilesUploadStatus(input: $input) {
            status
        }
    }
`);

const DeleteFilesMutation = graphql(`
    mutation UseFileManager_DeleteFiles($input: DeleteFilesInput!) {
        deleteFiles(input: $input) {
            status
        }
    }
`);

const MAX_FILE_SIZE_MB = 200;
const MIBI = 1024 * 1024;

export class FileSizeError extends Error {
    constructor(message: string) {
        super();
        this.name = FileSizeError.name;
        this.message = message;
    }
}

export interface FileInformation {
    name: string;
    url: string;
    id: string;
}

const useFileManager = (serviceId: string) => {
    const [createUploadUrls] = useMutation(CreateUploadUrls);
    const [updateFilesUploadStatusMutation] = useMutation(UpdateFilesUploadStatus);
    const [deleteFilesMutation] = useMutation(DeleteFilesMutation);

    const { formatMessage } = useIntl();

    const createUploadUrl = useCallback(
        async (file: File): Promise<FileInformation> => {
            let uploadUrlsObjects;
            try {
                uploadUrlsObjects = await createUploadUrls({
                    variables: {
                        input: {
                            serviceId,
                            files: [{ fileName: file.name, fileSize: file.size.toString(), fileType: file.type }],
                        },
                    },
                });
            } catch (error) {
                Sentry.captureException(error, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
                throw error;
            }

            const uploadUrl = uploadUrlsObjects.data?.createUploadUrls.urls[0];
            if (!uploadUrl) {
                Sentry.captureException(`Failed getting upload url: ${uploadUrlsObjects.toString()}`, {
                    tags: { app: "suppliers-app", feature: "supplier-upload" },
                });
                throw new Error("Unable to upload file. Please try again.");
            }
            return {
                name: uploadUrl.fileName,
                url: uploadUrl.url,
                id: uploadUrl.fileId,
            };
        },
        [serviceId, createUploadUrls]
    );

    const uploadFile = useCallback(
        async (
            file: File,
            onProgress: (progress: number) => void,
            fileInformation: FileInformation,
            controller?: AbortController // Controller to abort the upload
        ) => {
            if (file.size > (MAX_FILE_SIZE_MB + 1) * MIBI) {
                throw new FileSizeError(
                    formatMessage(
                        {
                            defaultMessage: "File is too large. Must be at most {maxSize}MB",
                            description: "File is too large. Must be at most {maxSize}MB",
                        },
                        { maxSize: MAX_FILE_SIZE_MB }
                    )
                );
            }
            try {
                await axios({
                    method: "PUT",
                    url: fileInformation.url,
                    headers: { "Content-Type": file.type },
                    data: file,
                    onUploadProgress: (progressEvent) => {
                        if (!progressEvent.total) return;
                        onProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    },
                    signal: controller?.signal,
                });

                await updateFilesUploadStatusMutation({
                    variables: {
                        input: {
                            files: [{ fileId: fileInformation.id, uploadStatus: "SUCCESS" }],
                        },
                    },
                });
            } catch (error) {
                Sentry.captureException(error, { tags: { app: "suppliers-app", feature: "supplier-upload" } });
                throw new Error(
                    formatMessage({
                        defaultMessage:
                            "Failed uploading file, please try again. If you are on a windows computer, make sure the file is not open in Excel.",
                        description:
                            "Failed uploading file, please try again. If you are on a windows computer, make sure the file is not open in Excel.",
                    })
                );
            }
        },
        [formatMessage, updateFilesUploadStatusMutation]
    );

    const deleteFile = useCallback(
        async (fileId: string) => {
            await deleteFilesMutation({
                variables: {
                    input: {
                        fileIds: [fileId],
                    },
                },
            });
        },
        [deleteFilesMutation]
    );

    return useMemo(
        () => ({
            createUploadUrl,
            uploadFile,
            deleteFile,
        }),
        [createUploadUrl, uploadFile, deleteFile]
    );
};

export default useFileManager;
