import { DocumentNode, useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { useAlert } from "@/providers";

import { ContactOptionsColumn } from "../ColumnActions/ColumnActions";

type GenerateContactsProps = {
    readonly open: boolean;
    readonly onClose: VoidFunction;
    readonly columns: ContactOptionsColumn[];
    readonly refetchQueries?: Array<DocumentNode | string>;
    readonly refetch?: VoidFunction;
};

type GenerateContactsForm = {
    readonly emailColumnId: string;
    readonly firstNameColumnId: string;
    readonly lastNameColumnId: string;
    readonly phoneNumberColumnId: string;
};

const createContactsDocument = graphql(`
    mutation createContactsFromColumns($input: CreateContactsFromColumnsInput!) {
        createContactsFromColumns(input: $input) {
            ok
        }
    }
`);

const GenerateContacts = ({ open, onClose, columns, refetchQueries, refetch }: GenerateContactsProps) => {
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useIntl();
    const [error, setError] = useState(false);
    const [form, setForm] = useState<GenerateContactsForm>({
        emailColumnId: "",
        firstNameColumnId: "",
        lastNameColumnId: "",
        phoneNumberColumnId: "",
    });
    const { alertUser } = useAlert();
    const [createContactsFromColumnsMutation] = useMutation(createContactsDocument, {
        refetchQueries,
        onError: () => {
            setLoading(false);
            alertUser(
                {
                    value: formatMessage({
                        defaultMessage: "Failed to generate contacts, please try again later.",
                        description: "Error message when trying to generate contacts",
                    }),
                    severity: "error",
                },
                {
                    SnackbarProps: {
                        autoHideDuration: null,
                    },
                }
            );
        },
        onCompleted() {
            setLoading(false);
            refetch?.();
            onClose();
            alertUser(
                {
                    value: formatMessage({
                        defaultMessage: "Contacts have been generated.",
                        description: "Alert message when contacts have been generated successfully",
                    }),
                },
                {
                    SnackbarProps: {
                        autoHideDuration: null,
                    },
                }
            );
        },
    });

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const error = !form.emailColumnId;
        setError(error);
        if (error) {
            return;
        }
        setLoading(true);
        track("Supplier Table: Generated Contacts");
        createContactsFromColumnsMutation({
            variables: {
                input: {
                    ...form,
                },
            },
        });
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage defaultMessage="Generate contacts" description="Generate contacts modal title" />
            </DialogTitle>
            {loading ? (
                <Stack direction="row" alignItems="center" justifyContent="center" height="200px" width="100%">
                    <CircularProgress />
                </Stack>
            ) : (
                <form onSubmit={submitForm}>
                    <DialogContent>
                        <DialogContentText marginBottom={3}>
                            <FormattedMessage
                                defaultMessage="Please select the column that represents email (optional: first name, last name and phone number)"
                                description="Generate Contacts dialog description"
                            />
                        </DialogContentText>
                        <Grid container rowSpacing={4}>
                            <Grid item xs={7}>
                                <Stack direction="row">
                                    <Stack>
                                        <Typography variant="textMd">
                                            <FormattedMessage
                                                defaultMessage="Email *"
                                                description="Email description"
                                            />
                                        </Typography>
                                        <Typography variant="textSm">
                                            <FormattedMessage defaultMessage="Select the column that represents email" />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <FormattedMessage defaultMessage="Email" description="Email select label" />
                                    </InputLabel>
                                    <Select
                                        value={form.emailColumnId}
                                        label={formatMessage({
                                            defaultMessage: "Email",
                                            description: "Email select label",
                                        })}
                                        onChange={(event) => {
                                            setError(false);
                                            setForm((formState) => ({
                                                ...formState,
                                                emailColumnId: event.target.value,
                                            }));
                                        }}
                                        error={error}
                                    >
                                        <MenuItem value="" key="none">
                                            <FormattedMessage defaultMessage="None" description="None message" />
                                        </MenuItem>
                                        {columns.map((column) => (
                                            <MenuItem value={column.id} key={column.id}>
                                                {column.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <Stack direction="row">
                                    <Stack>
                                        <Typography variant="textMd">
                                            <FormattedMessage
                                                defaultMessage="First name"
                                                description="First name description"
                                            />
                                        </Typography>
                                        <Typography variant="textSm">
                                            <FormattedMessage defaultMessage="Select the column that represents first name" />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <FormattedMessage
                                            defaultMessage="First name"
                                            description="First name select label"
                                        />
                                    </InputLabel>
                                    <Select
                                        value={form.firstNameColumnId}
                                        label={formatMessage({
                                            defaultMessage: "First Name",
                                            description: "First name select label",
                                        })}
                                        onChange={(event) => {
                                            setError(false);
                                            setForm((formState) => ({
                                                ...formState,
                                                firstNameColumnId: event.target.value,
                                            }));
                                        }}
                                    >
                                        <MenuItem value="" key="none">
                                            <FormattedMessage defaultMessage="None" description="None message" />
                                        </MenuItem>
                                        {columns.map((column) => (
                                            <MenuItem value={column.id} key={column.id}>
                                                {column.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <Stack direction="row">
                                    <Stack>
                                        <Typography variant="textMd">
                                            <FormattedMessage
                                                defaultMessage="Last name"
                                                description="Last name description"
                                            />
                                        </Typography>
                                        <Typography variant="textSm">
                                            <FormattedMessage defaultMessage="Select the column that represents last name" />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <FormattedMessage
                                            defaultMessage="Last name"
                                            description="Last name select label"
                                        />
                                    </InputLabel>
                                    <Select
                                        value={form.lastNameColumnId}
                                        label={formatMessage({
                                            defaultMessage: "Last Name",
                                            description: "Last name select label",
                                        })}
                                        onChange={(event) => {
                                            setError(false);
                                            setForm((formState) => ({
                                                ...formState,
                                                lastNameColumnId: event.target.value,
                                            }));
                                        }}
                                    >
                                        <MenuItem value="" key="none">
                                            <FormattedMessage defaultMessage="None" description="None message" />
                                        </MenuItem>
                                        {columns.map((column) => (
                                            <MenuItem value={column.id} key={column.id}>
                                                {column.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <Stack direction="row">
                                    <Stack>
                                        <Typography variant="textMd">
                                            <FormattedMessage
                                                defaultMessage="Phone number"
                                                description="Phone number description"
                                            />
                                        </Typography>
                                        <Typography variant="textSm">
                                            <FormattedMessage defaultMessage="Select the column that represents phone number" />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <FormattedMessage
                                            defaultMessage="Phone number"
                                            description="Phone number select label"
                                        />
                                    </InputLabel>
                                    <Select
                                        value={form.phoneNumberColumnId}
                                        label={formatMessage({
                                            defaultMessage: "Phone number",
                                            description: "Phone number select label",
                                        })}
                                        onChange={(event) => {
                                            setError(false);
                                            setForm((formState) => ({
                                                ...formState,
                                                phoneNumberColumnId: event.target.value,
                                            }));
                                        }}
                                    >
                                        <MenuItem value="" key="none">
                                            <FormattedMessage defaultMessage="None" description="None message" />
                                        </MenuItem>
                                        {columns.map((column) => (
                                            <MenuItem value={column.id} key={column.id}>
                                                {column.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary" variant="text">
                            <FormattedMessage
                                defaultMessage="Cancel"
                                description="Generate contacts cancel button title"
                            />
                        </Button>
                        <Button type="submit" color="primary">
                            <FormattedMessage
                                defaultMessage="Generate"
                                description="Generate contacts primary button title"
                            />
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

export default GenerateContacts;
