import { styled } from "@mui/material/styles";

export const colors = ["#ffd4e0", "#ffe0cc", "#ffeab6", "#c1f5f0", "#d1e2ff"];

export const Chip = styled("div")((props) => ({
    border: "none",
    backgroundColor: props.color ?? "rgba(0, 0, 0, 0.38)",
    font: "inherit",
    borderRadius: "4px",
    fontSize: "inherit",
    fontWeight: "inherit",
    padding: "0 12px",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
}));
